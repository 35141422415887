import {IRoute} from "../interfaces/IRoute";
import React, {Fragment} from "react";
import Helmet from "react-helmet";
import Restricted from "../../context/Restricted";

export const useRouteGenerator = () => {
    const routeGenerator = (route: IRoute) => {
        return (
            <Fragment key={route.id}>
                <Restricted to={route?.permission} replace={true}>
                    <Helmet>
                        <title>Lagrange | {route.title}</title>
                    </Helmet>
                    <route.component/>
                </Restricted>
            </Fragment>
        )
    }

    return {routeGenerator};
}