import React, {useEffect} from "react";
import SecondSidebar from "../components/SecondSidebar";
import {Route, Routes, useParams} from "react-router-dom";
import {modelLayoutRoutes} from "../routes";
import {dashboards, getModel, workspace} from "../services";
import {useDashboards} from "../context/DashboardsContext";
import {useModel} from "../context/ModelContext";
import {useError} from "../context/ErrorContext";
import {isNullOrUndefinedOrEmpty} from "../shared/utils/helper";
import {DashboardProvider} from "../context/DashboardContext";
import NotFound from "../pages/NotFound";
import {useWorkSpace} from "../context/WorkSpaceContext";
import {useRouteGenerator} from "../shared/hooks/useRouteGenerator";

type ModelChartLayoutProps = {
    children?: React.ReactNode
}
const ModelLayout = ({children}: ModelChartLayoutProps) => {

    const {setDashboards} = useDashboards();
    const {model, setModel} = useModel();
    const {modelUUId, workspaceUUId} = useParams();
    const {setError} = useError();
    const {workSpace, setWorkSpace} = useWorkSpace();
    const {routeGenerator} = useRouteGenerator();

    const loadDashboards = async () => {
        if (modelUUId) {
            try {
                const data = await dashboards(modelUUId);
                setDashboards(data)
            } catch (error: any) {
                setError({message: error.response.data.detail, type: 'error'});
            }
        }
    }

    const loadModel = async (uuid: string) => {
        try {
            const data = await getModel(uuid);
            setModel(data);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    useEffect(() => {
        if (modelUUId) {
            loadDashboards()
        }
        if (isNullOrUndefinedOrEmpty(model) && modelUUId) {
            loadModel(modelUUId)
        }

        return () => {
            setDashboards([])
        }
    }, [])

    useEffect(() => {
        if (modelUUId) {
            loadModel(modelUUId);
        }
    }, [model?.uuid]);

    useEffect(() => {
        if (workspaceUUId && workspaceUUId !== "" && isNullOrUndefinedOrEmpty(workSpace)) {
            loadWorkspace(workspaceUUId)
        }
    },[workspaceUUId])

    const loadWorkspace = async (uuid: string) => {
        try {
            const data = await workspace(uuid);
            setWorkSpace(data);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    return (
        <DashboardProvider>
            <div className="flex gap-x-1 mt-[2px]">
                <div className="flex gap-x-1">
                    <div className="flex h-[calc(100vh-64px)]">
                        <SecondSidebar/>
                    </div>
                </div>
                <div className="flex flex-col flex-1 gap-y-1">
                    <Routes>
                        {
                            modelLayoutRoutes.map(route => {
                                return <Route key={route.id} path={route.path} element={routeGenerator(route)}/>
                            })
                        }
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                    {children}
                </div>
            </div>
        </DashboardProvider>
    )
}

export default ModelLayout