import axios from 'axios';
import toast from "react-hot-toast";

const client = axios.create({
    baseURL: `${process.env.REACT_APP_API_ENDPOINT}/api/v1`,
    headers: {
        "Content-Type": "application/json"
    }
})

client.interceptors.response.use((response) => {
    return response;
}, async (error) => {

    const originalConfig = error.config;

    if (originalConfig.url !== "/login/access-token" && error.response) {
        if (error.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;

            try {
                const values = {
                    grant_type: 'refresh_token',
                    refresh_token: localStorage.getItem("refresh_token")
                }

                const {data} = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/v1/login/access-token`, values, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                })

                if (data?.access_token) {
                    localStorage.setItem('token', data.access_token);
                    client.defaults.headers.common['Authorization'] = `bearer ${data.access_token}`;
                    originalConfig.headers.Authorization = `bearer ${data.access_token}`;
                    const accessToken = data.access_token;
                    setToken(accessToken, 'bearer');
                    return client(originalConfig);
                }
            } catch (error: any) {
                if(error.response.status === 401) {
                    removeToken();
                    window.location.replace('/sign-in');
                    return;
                }
                return;
            }
        }
    }
    if (originalConfig.url === "/login/access-token" && error.response) {
        if (error.response.status !== 401) {
            removeToken();
            window.location.replace('/sign-in');
            return;
        }
    }
    if (error.response) {
        if (error.response.status !== 401) {
            return Promise.reject(error);
        }
    } else {
        toast.error("Oops! Something went wrong, Please try again later.", {duration: 5000});
        return Promise.reject(error);
    }
})

const token = localStorage.getItem("token");
const token_type = localStorage.getItem("token_type");
client.defaults.headers.common['Authorization'] = `${token_type} ${token}`;

export const setToken = (access_token, token_type) => {
    localStorage.setItem('token', access_token);
    localStorage.setItem('token_type', token_type);
    client.defaults.headers.common['Authorization'] = `${token_type} ${access_token}`;
}

export const setRefreshToken = (refresh_token) => {
    localStorage.setItem("refresh_token", refresh_token);
}

export const removeToken = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('token_type');
    client.defaults.headers.common['Authorization'] = '';
}

export default client;


