import React, {useEffect, useState} from "react";
import Profile from "../Profile";
import {Link} from "react-router-dom";
import {useNotifications} from "../../context/NotificationContext";
import {getNotificationCount} from "../../services";
import {useAuth} from "../../context/AuthContext";
import {useError} from "../../context/ErrorContext";
import {useNotificationCount} from "../../context/NotificationCountContext";
import Restricted from "../../context/Restricted";

type headerProps = {
    onOpenNotification: () => void
}
const Header = ({onOpenNotification}: headerProps) => {

    const {notifications, setNotifications} = useNotifications();
    const {notificationCount, setNotificationCount} = useNotificationCount()
    const [webSocket, setWebSocket] = useState<WebSocket | null>()
    const {user} = useAuth();
    const {setError} = useError();

    useEffect(() => {
        loadNotification()

        return () => {
            webSocket?.close();
        }
    }, []);

    const loadNotification = () => {
        const ws = new WebSocket(`${process.env.REACT_APP_SOCKET_URL}/api/v1/notification/`) as WebSocket;
        setWebSocket(ws);
    }

    useEffect(() => {
        if (webSocket) {
            webSocket.onopen = (event) => {
                console.log(event)
            }


            webSocket.onclose = (event) => {
                console.log('Socket is closed. Reconnect will be attempted in 1 second.', event.reason);
                setTimeout(function () {
                    loadNotification()
                }, 1000);
            }

            webSocket.onerror = (event) => {
                console.log(event)
            }

            webSocket.onmessage = (event) => {
                const message = JSON.parse(event.data);
                setNotifications([message, ...notifications]);
                setNotificationCount(presState => presState + 1);
            }
        }
    }, [webSocket]);

    useEffect(() => {
        if (user?.uuid) {
            loadNotificationCount(user?.uuid)
        }
    }, [user]);

    const loadNotificationCount = async (uuid: string) => {
        try {
            const data = await getNotificationCount(uuid);
            setNotificationCount(data.count);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    return (
        <header className="py-3 px-6 bg-white shadow-sm">
            <nav className="flex justify-between">
                <Link to="/" className="flex items-center h-[38px]">
                    <img src="/images/main-logo.png" alt="logo" className="h-full"/>
                </Link>
                <div className="flex items-center">
                    <div className="flex mr-4">
                        <button
                            className="border border-lightGray relative rounded ml-3 p-[6px] flex justify-center items-center"
                            onClick={onOpenNotification}>
                            <i className="icon icon-22 icon-alarm"/>
                            {
                                notificationCount > 0 ? (<span
                                        className="absolute top-0 right-0 bg-red rounded-full inline-block w-4 h-4 text-white text-xs">{notificationCount <= 9 ? notificationCount : "+9"}</span>
                                ) : null
                            }
                        </button>
                        {/*<button*/}
                        {/*    className="border border-lightGray rounded ml-3 p-[6px] flex justify-center items-center">*/}
                        {/*    <i className="icon icon-22 icon-headphone"/></button>*/}


                        <Restricted to="organization admin">
                            <Link to="/setting/organization"
                                  className="border border-lightGray rounded ml-3 p-[6px] flex justify-center items-center"><i
                                className="icon icon-22 icon-config"/></Link>
                        </Restricted>
                    </div>
                    <Profile/>
                </div>
            </nav>
        </header>
    )
}

export default Header