import React from "react";
import DataTypeItem from "./DataTypeItem";
import {IDateTypeItem} from "../../shared/interfaces/IDateTypeItem";


type importDataTypeProps = {
    data: IDateTypeItem[],
    selectedItem?: number,
    onChange: (e,id) => void,
}
const ImportDataType = ({data, selectedItem, onChange}: importDataTypeProps) => {
    return (
        <div className="w-[265px] h-[calc(100vh-133px)] flex flex-col overflow-y-auto">
            <div className="py-5 px-4 bg-white shadow-sm flex-1">
                {
                    data.map((item: IDateTypeItem) => {
                        return <DataTypeItem key={item.id} {...item} isSelected={item?.id === selectedItem} onChange={onChange} />
                    })
                }
            </div>
        </div>
    )
}

export default ImportDataType