import Input from "../Input/input";
import Modal from "../Modal";
import React, {useEffect, useRef, useState} from "react";
import {useFormik} from "formik";
import {encryptData} from "../../shared/utils/helper";
import {updatePasswordSchema, updatePasswordValues} from "../../shared/formValidator/updatePasswordValidator";
import {useError} from "../../context/ErrorContext";
import {updatePassword} from "../../services";
import Loading from "../Loading";

type UpdatePasswordProps = {
    isOpen: boolean,
    onClose: () => void,
}

const UpdatePassword = ({isOpen, onClose}: UpdatePasswordProps) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const {setError} = useError();

    const handleUpdatePassword = async (values) => {
        setIsLoading(true)
        const {
            iv,
            encryptedData1: old_password,
            encryptedData2: new_password
        } = encryptData(values.old_password, values.new_password)

        const formValues = {
            iv,
            old_password,
            new_password
        }

        try {
            await updatePassword(formValues);
            setIsLoading(false)
            onClose();
        } catch (error: any) {
            setIsLoading(false)
            onClose()
            setError({message: error?.response?.data?.detail, type: 'error'});
        } finally {
            formik.resetForm()
        }
    }

    const formik = useFormik({
        initialValues: updatePasswordValues,
        onSubmit: handleUpdatePassword,
        validationSchema: updatePasswordSchema,
        validateOnMount: true,
        validateOnBlur: true,
        validateOnChange: true
    })

    useEffect(() => {
        if (inputRef.current === null) return;
        if (isOpen) setTimeout(() => {
            inputRef.current?.focus()
        }, 200);
        if (!isOpen) {
            formik.resetForm()
        }
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} isSubmitBtn={false} isCancelBtn={false} closeButton={false}
               classNameSize="max-w-lg">
            <div>
                <form onSubmit={formik.handleSubmit}>
                    <Input type="password" name="old_password" label="Currernt Password: " formik={formik} ref={inputRef}/>
                    <Input type="password" name="new_password" label="New Password: " formik={formik}/>
                    <Input type="password" name="confirm_password" label="Confirm New Password: " formik={formik}/>
                    <div className="mt-8 flex gap-x-4 justify-end">
                        <button className="btn btn-primary btn-outline px-6" type="button" onClick={onClose}>Cancel
                        </button>
                        <button className={`btn btn-primary px-12 w-[154px] ${formik.isValid ? "" : "btn-disabled"}`} type="submit">
                            {isLoading ? <Loading /> : "Update"}
                        </button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default UpdatePassword;