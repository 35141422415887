import React, {useEffect, useState} from "react";
import ActionBar from "../../../components/ActionBar";
import DescriptionPage from "../../../components/SettingSidebar/DescriptionPage";
import Form from "./Form";
import {useNavigate, useParams} from "react-router-dom";
import {useError} from "../../../context/ErrorContext";
import {team, updateTeam} from "../../../services";
import {TeamValuesType} from "../../../shared/formValidator/teamFormValidator";
import {useAuth} from "../../../context/AuthContext";

const EditTeam = () => {

    const [teamData, setTeamData] = useState<TeamValuesType | null>(null);
    const {uuid} = useParams();
    const {setError} = useError();
    const {user} = useAuth();
    const navigate = useNavigate();

    const onSubmit = async (values) => {
        const formValues = {
            title: values.title.trim(),
            description: values.description.trim(),
            member_uuids: values.member_uuids.map(item => item.value),
            workspace_uuids: values.workspaces.map(item => item.value),
            organization_uuid: user?.organization_uuid,
            is_active: values.is_active
        };

        try {
            await updateTeam(uuid, formValues);
            navigate('/setting/teams')
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    const loadTeamData = async (uuid: string) => {
        try {
            const data = await team(uuid);
            setTeamData(data)
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    useEffect(() => {
        if (uuid) {
            loadTeamData(uuid)
        }
    }, []);

    return (
        <div className="flex flex-col gap-x-1 gap-y-1 w-full">
            <div className="flex flex-col flex-1 gap-y-1">
                <ActionBar goBack={true} pageTitle="Team" topic="team" isShow={false}/>
            </div>
            <div className="flex h-[calc(100vh-133px)] gap-x-1">
                <DescriptionPage>
                    <p>add / edit teams and team member</p>
                </DescriptionPage>
                <section className="h-full flex flex-col p-6 bg-white shadow-sm flex-1 overflow-y-auto">
                    <div className="border border-light-100 rounded-lg p-6 grid grid-cols-3">
                        <Form onSubmit={onSubmit} data={teamData}/>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default EditTeam;