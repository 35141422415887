import React, {createContext, ReactNode, useContext, useState} from "react";

type NotificationCountProviderProps = {
    children?: ReactNode
}

const NotificationCountContext = createContext<{notificationCount: number, setNotificationCount: (notificationCount: (prevState: number) => number) => void}>({notificationCount: 0, setNotificationCount: () => 0});

export const NotificationCountProvider = ({children}: NotificationCountProviderProps) => {
    const [notificationCount, setNotificationCount] = useState<number>(0);

    return (
        <NotificationCountContext.Provider value={{notificationCount, setNotificationCount}}>
            {children}
        </NotificationCountContext.Provider>
    )
}

export const useNotificationCount = () => useContext(NotificationCountContext);