import {useEffect, useState} from "react";
import {useError} from "../../context/ErrorContext";
import {useWorkSpace} from "../../context/WorkSpaceContext";
import {getExecutionItem, getExecutionStep} from "../../services";
import {IExecutionProgressStep} from "../interfaces/IExecutionProgressStep";
import {useParams} from "react-router-dom";
import {IExecutionProgress} from "../interfaces/IExecutionProgress";
import {FAILED, SUCCESS} from "../constant";

let timerExecutionProgress: NodeJS.Timer;
let intervalCheckExecution: NodeJS.Timer;
let timeoutCheckExecution: NodeJS.Timer;

export const useExecutionProgress = () => {

    const [executionProgressList, setExecutionProgressList] = useState<[]>([])
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
    const {setError} = useError();
    const {workSpace} = useWorkSpace();

    useEffect(() => {
        loadExecutionProgressList();
        timerExecutionProgress = setInterval(() => {
            loadExecutionProgressList()
        },5000)

        return () => clearInterval(timerExecutionProgress);
    }, [workSpace]);

    const loadExecutionProgressList = async () => {
        if (workSpace) {
            try {
                const data = await getExecutionItem(workSpace?.uuid);
                setExecutionProgressList(data);
            } catch (error: any) {
                setError({message: error.response.data.detail, type: 'error'})
            }
        }
    }

    useEffect(() => {
        if (executionProgressList.length > 0) {
            const hasRunningItem = executionProgressList.some((item: IExecutionProgress) => item?.status === "running")
            if (!hasRunningItem) {
                clearInterval(timerExecutionProgress)
            }
        } else {
            clearInterval(timerExecutionProgress)
        }
    }, [executionProgressList]);

    const handleSelectedItems = (e, uuid) => {
        const {checked} = e.target;
        if (checked) {
            setSelectedItem(uuid);
        } else {
            setSelectedItem(null)
        }
    }

    return {executionProgressList, selectedItem, handleSelectedItems, loadExecutionProgressList}
}

export const useExecutionProgressStep = () => {

    const [executionProgress, setExecutionProgress] = useState<IExecutionProgressStep | null>(null);
    const {setError} = useError();
    const {uuid} = useParams();


    useEffect(() => {
        if (uuid) {
            loadExecutionProgress(uuid)
            intervalCheckExecution = setInterval(() => {
                loadExecutionProgress(uuid)
            }, 5000)
        }

        return () => {
            clearInterval(intervalCheckExecution);
            clearTimeout(timeoutCheckExecution);
        }
    }, [uuid]);

    const loadExecutionProgress = async (uuid: string) => {
        try {
            const data = await getExecutionStep(uuid);
            setExecutionProgress(data);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    useEffect(() => {
        if (executionProgress && executionProgress?.execution_steps.length > 3) {
            const hasRunningItem = executionProgress?.execution_steps.some((item) => item?.status !== "done")
            if (!hasRunningItem) {
                clearInterval(intervalCheckExecution)
            }
        }
        if (executionProgress && executionProgress.execution_steps) {
            const hasFailedItem = executionProgress?.execution_steps.some((item) => item?.status === FAILED)
            if (hasFailedItem) {
                clearInterval(intervalCheckExecution)
            }
        }
        if (executionProgress && executionProgress.execution_steps.length === 4 && executionProgress.execution_steps.every(item => item.status === "done") && executionProgress.execution_item.status !== "done") {
            timeoutCheckExecution = setTimeout(() => {
                if (uuid) {
                    loadExecutionProgress(uuid)
                }
            }, 2000)
        }
    }, [executionProgress]);

    return {executionProgress}
}