import React from "react";
import {ResponsiveBar} from '@nivo/bar'

const Bar = ({plotData}) => {

    const convertData = () => {
        let finalResult: { [key: string]: string | number }[] = [];

        for (let indexValues in plotData.values) {
            let obj: { [key: string]: string | number } = {[plotData.title]: plotData.values[indexValues].id}
            for (let indexData in plotData.values[indexValues].data) {
                if (plotData.values[indexValues].id === plotData.values[indexValues].data[indexData].x) {
                    obj = {...obj, [" "]: plotData.values[indexValues].data[indexData].y}
                } else {
                    obj = {
                        ...obj,
                        [plotData.values[indexValues].data[indexData].x]: plotData.values[indexValues].data[indexData].y
                    }
                }
            }
            finalResult.push(obj);
        }
        return finalResult
    }

    const convertKeys = () => {
        if (plotData.values.length > 0) {
            return plotData.values[0].data.map(item => {
                if (plotData.values[0].id === item.x) {
                    return ' '
                }
                return item?.x
            });
        }
    }

    return (
        <>
            <span className="text-sm text-center block mt-4">{plotData.title}</span>
            <ResponsiveBar
                data={convertData()}
                keys={convertKeys()}
                indexBy={plotData.title}
                margin={{top: 24, right: 150, bottom: 75, left: 60}}
                padding={0.3}
                valueScale={{type: 'linear'}}
                indexScale={{type: 'band', round: true}}
                colors={{scheme: 'blues'}}
                defs={[
                    {
                        id: 'dots',
                        type: 'patternDots',
                        background: 'inherit',
                        color: '#38bcb2',
                        size: 4,
                        padding: 1,
                        stagger: true
                    },
                    {
                        id: 'lines',
                        type: 'patternLines',
                        background: 'inherit',
                        color: '#eed312',
                        rotation: -45,
                        lineWidth: 6,
                        spacing: 10
                    }
                ]}
                fill={[]}
                borderColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: plotData?.xAxisTitle,
                    legendPosition: 'middle',
                    legendOffset: 32
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: plotData?.yAxisTitle,
                    legendPosition: 'middle',
                    legendOffset: -55
                }}
                labelSkipWidth={12}
                labelSkipHeight={12}
                labelTextColor={{
                    from: 'color',
                    modifiers: [
                        [
                            'darker',
                            1.6
                        ]
                    ]
                }}
                legends={[
                    {
                        dataFrom: 'keys',
                        anchor: 'bottom-right',
                        direction: 'column',
                        justify: false,
                        translateX: 140,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 130,
                        itemHeight: 20,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
                role="application"
                ariaLabel="Nivo bar chart demo"
                barAriaLabel={e => e.id + ": " + e.formattedValue + " in country: " + e.indexValue}
            />
        </>
    )
}

export default Bar