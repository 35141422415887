import React from "react";
import {useMessage} from "../../context/MessageContext";
import {useSupport} from "../../context/SupportContext";
import {useLearn} from "../../context/LearnContext";


type ActionBarButtonProps = {
    topic?: string
}

const ActionBarButton = ({topic}: ActionBarButtonProps) => {


    const {setMessage} = useMessage();
    const {setLearn} = useLearn();
    const {setSupport} = useSupport();
          
    const handleLearn = () => {
        setLearn({isOpen: true, topic: topic || ''})
    }
    
    const handleSupport = () => {
        setSupport({isOpen: true, topic: topic ||''})
    }
    
    const handleClickMessage = () => {
        setMessage({isOpen: true, topic: topic || ''});
    }

    return (
        <div className="flex">
            <button className="ml-6 h-6" onClick={handleClickMessage}><i className="icon icon-24 icon-comment"/></button>
            <button className="ml-6 h-6" onClick={handleSupport}><i className="icon icon-24 icon-headphone"/></button>
            {/*<button className="ml-6 h-6" onClick={handleLearn}><i className="icon icon-24 icon-academic"/></button>*/}
        </div>
    )
}

export default ActionBarButton;