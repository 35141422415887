import React, {useEffect, useState} from "react";
import Modal from "../Modal";

const ModalCheckStatus = ({isOpen, onClose, checkStatusData}) => {

    const [columns, setColumns] = useState<{
        key: string,
        title: string
    }[] | []>([]);
    const [rows, setRows] = useState<any>(null);

    useEffect(() => {
        if (checkStatusData) {
            setColumns(generateColumns(checkStatusData));
            const co = generateColumns(checkStatusData);
            const ro = Object.keys(checkStatusData[co[1].key]);
            setRows(ro)
        }
    }, [checkStatusData]);

    const generateColumns = (values) => {
        return Object.keys(values)?.map(item => {
            return (
                {key: item, title: item}
            )
        })
    }

    const renderStatus = obj => {
        if (obj['required'] === false) {
            return <span className="inline-block w-6 h-6 bg-lightGray-100"/>
        } else if (obj['Imported'] === false) {
            return <i className="icon icon-24 icon-checkbox-unchecked"/>
        } else if (obj['complete'] === true) {
            return <i className="icon icon-24 icon-checkbox-checked"/>
        } else if ("complete" in obj && obj['complete'] === false) {
            return <i className="icon icon-24 icon-checkbox-uncomplete"/>
        } else {
            return <i className="icon icon-24 icon-checkbox-unchecked"/>;
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCancelBtn={false}>
            <div>
                <h2 className="text-left text-base">Check Status</h2>
            </div>
            <table className={`table dataTable border-collapse border border-lightGray table-striped w-full`}>
                <thead>
                <tr>
                    <th></th>
                    {
                        columns.map((column) => {
                            return (
                                <th key={column.key}
                                    className="rounded-tr-none rounded-tl-none text-sm">{column.title}</th>
                            )
                        })
                    }
                </tr>
                </thead>

                <tbody>
                {
                    rows && rows.map((ro, index) => {
                        return (<tr key={index}>
                            <td>{ro}</td>
                            {
                                columns.map((co, index) => {
                                    return (
                                        <td key={index}>{renderStatus(checkStatusData[co.key][ro])}</td>
                                    )
                                })
                            }
                        </tr>)
                    })
                }
                </tbody>

            </table>
            <div className="flex justify-between mt-4">
                <span className="flex gap-x-1 items-center">
                    <i className="icon icon-24 icon-checkbox-checked"/>
                    <span>Complete</span>
                </span>
                <span className="flex gap-x-1 items-center">
                    <i className="icon icon-24 icon-checkbox-unchecked"/>
                    <span>Not Imported</span>
                </span>
                <span className="flex gap-x-1 items-center">
                    <i className="icon icon-24 icon-checkbox-uncomplete"/>
                    <span>Partially Complete</span>
                </span>
                <span className="flex gap-x-1 items-center">
                    <span className="inline-block w-6 h-6 bg-lightGray-100"/>
                    <span>Not Required</span>
                </span>
            </div>
        </Modal>
    )
}

export default ModalCheckStatus;