import React from "react";

const ConversationItem = ({conversation, onClick, selectedConversation, selectedConversationUUId}) => {

    const handleSelectConversation = () => {
        if (selectedConversationUUId !== conversation.uuid) {
            onClick && onClick(conversation.uuid)
        }
    }

    return (
        <div
            className={`p-3 flex text-sm rounded cursor-pointer items-center gap-x-3 ${selectedConversationUUId === conversation?.uuid ? "bg-blue-100 text-primary" : ""}`}
            onClick={handleSelectConversation}>
            <i className="icon icon-24 icon-comment"/>
            <div className="truncate max-w-[calc(100%-36px)] block">{conversation.title}</div>
        </div>
    )
}

export default ConversationItem;