import React, {createContext, useContext, useState} from "react";

type learnProps = {
    isOpen: boolean,
    topic: string
}

type LearnProviderProps = {
    children: React.ReactNode
}

const LearnContext = createContext<{learn: learnProps, setLearn: (value: learnProps) => void}>({
    learn: {isOpen: false, topic: ''},
    setLearn: () => {}
});


export const LearnProvider = ({children}: LearnProviderProps) => {

    const [learn, setLearn] = useState<{isOpen: boolean, topic: string}>({isOpen: false, topic: ''});
    return (
        <LearnContext.Provider value={{learn, setLearn}}>
            {children}
        </LearnContext.Provider>
    )
}

export const useLearn = () => useContext(LearnContext);