import {Navigate, Route, Routes} from "react-router-dom";
import {settingsLayoutRoutes} from "../routes";
import React from "react";
import {ExpandedSidebarProvider} from "../context/ExpandedSidebarContext";
import {IRoute} from "../shared/interfaces/IRoute";
import NotFound from "../pages/NotFound";
import {useRouteGenerator} from "../shared/hooks/useRouteGenerator";

type SecondaryLayoutProps = {
    children?: React.ReactNode
}

const SettingsLayout = ({children}: SecondaryLayoutProps) => {

    const {routeGenerator} = useRouteGenerator();

    return (
        <div className="flex gap-x-1 mt-[2px] h-[calc(100%-64px)]">
            <ExpandedSidebarProvider>
                <Routes>
                    {
                        settingsLayoutRoutes.map((route: IRoute) => {
                            return <Route key={route.id} path={route.path} element={
                                route?.to ? <Navigate to={route.to} /> : routeGenerator(route)  } />
                        })
                    }
                    <Route path="*" element={<div className="h-full flex w-full"><NotFound/></div>}/>
                </Routes>
                {children}
            </ExpandedSidebarProvider>
        </div>
    )
}

export default SettingsLayout;