import React from "react";
import {IDateTypeItem} from "../../shared/interfaces/IDateTypeItem";

interface dataTypeItemProps extends IDateTypeItem {
    isSelected: boolean,
    onChange: (e, id) => void
}
const DataTypeItem = ({id, label, description, isSelected, onChange}: dataTypeItemProps) => {

    const handleChange = (e) => {
        onChange && onChange(e, id)
    }

    return (
        <div className="flex gap-x-3 mt-3 pb-5">
            <div className="">
                <label className="label cursor-pointer p-0">
                    <input type="checkbox" checked={isSelected} onChange={handleChange} className="checkbox checkbox-primary rounded-sm"/>
                </label>
            </div>
            <div>
                <h4 className="text-primary">{label}</h4>
                <p className="mt-4 text-dark-100 text-justify">
                    {description}
                </p>
            </div>
        </div>
    )
}

export default DataTypeItem;