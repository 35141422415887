import React, {useRef, useState, useEffect} from "react";
import {useAuth} from "../../context/AuthContext";
import {login, sendTwoFAEmail, verificationCode, verifyCaptcha} from "../../services";
import {setRefreshToken, setToken} from "../../services/config";
import {Link, useNavigate} from "react-router-dom";
import {useError} from "../../context/ErrorContext";
import OTPInput from "react-otp-input";
import ReCAPTCHA from "react-google-recaptcha";
import {encryptData} from "../../shared/utils/helper";
import Loading from "../Loading";

let timer: NodeJS.Timer;

type UserTwoFAInfoProps = {
    email_address: string,
    uuid: string,
    two_fa_send_date: string,
    two_fa_disactive_date: string,
    two_fa_resend_tries: number,
    two_fa_unseccesful_tries: number,
    is_two_fa_disactive: boolean
}

const SignInForm = () => {

    const [formValues, setFormValues] = useState({username: "", password: ""});
    const [isTwoFA, setIsTwoFA] = useState<boolean>(false);
    const [otp, setOtp] = useState('');
    const [countDownSeconds, setCountDownSeconds] = useState<number>(0);
    const [countDownMinutes, setCountDownMinutes] = useState<number>(0);
    const [isShowResendLink, setIsShowResendLink] = useState<boolean>(true);
    const [twoFAToken, setTwoFAToken] = useState<string | null>(null);
    const [userTwoFAInfo, setUserTwoFAInfo] = useState<UserTwoFAInfoProps | null>(null)
    const [isCaptchaToken, setIsCaptchaToken] = useState<boolean>(false);
    const recaptcha = useRef<ReCAPTCHA>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {setUser} = useAuth();
    const navigate = useNavigate();
    const {setError} = useError();

    const handleChangeInput = e => {
        setFormValues({...formValues, [e.target.name]: e.target.value});
    }

    const handleSubmit = async e => {
        setIsLoading(true);
        e.preventDefault();
        const captchaValue = recaptcha.current?.getValue();
        if (captchaValue) {
            try {
                const data = await verifyCaptcha(captchaValue);
                if (data.status) {
                    await handleLogin()
                }
            } catch (error: any) {
                setIsLoading(false)
                setError({message: error.response.data.detail, type: 'error'})
            }
        } else {
            setIsLoading(false)
            setError({message: "Please verify the reCAPTCHA!", type: 'error'})
        }
    }

    useEffect(() => {
        if (!isShowResendLink) {
            setCountDownSeconds(59);
            setCountDownMinutes(4);
            timer = setInterval(() => {
                setCountDownSeconds(prevState => prevState - 1);
            }, 1000)
        }
        return () => {
            clearInterval(timer)
        }
    }, [isShowResendLink])


    const handleLogin = async () => {
        setIsLoading(true)
        if (formValues.username !== "" && formValues.password !== "" && isCaptchaToken) {
            const {
                iv,
                encryptedData1: username,
                encryptedData2: password
            } = encryptData(formValues.username.trim(), formValues.password)

            const values = {
                grant_type: 'password',
                iv,
                username,
                password
            }
            try {
                const data = await login(values);
                if (data?.access_token && data?.token_type) {
                    const {access_token, token_type, user, refresh_token} = data;
                    setToken(access_token, token_type);
                    setRefreshToken(refresh_token);
                    setUser(user);
                    navigate('/workspace');
                } else if (data?.two_fa_token) {
                    const {two_fa_token, user_2fa} = data;
                    setTwoFAToken(two_fa_token);
                    setUserTwoFAInfo(user_2fa);
                    setIsTwoFA(true);
                    setIsShowResendLink(false);
                }
                setIsLoading(false);
            } catch (error: any) {
                setIsLoading(false)
                recaptcha.current?.reset();
                setIsCaptchaToken(false);
                setError({message: error?.response?.data?.detail, type: 'error'});
            }
        }
    }


    useEffect(() => {
        if (countDownSeconds === -1 && countDownMinutes > 1) {
            setCountDownMinutes(prevState => prevState - 1);
            setCountDownSeconds(59);
        }
        if (countDownSeconds === -1 && countDownMinutes === 1) {
            setCountDownMinutes(0);
            setCountDownSeconds(59);
        }
        if (countDownSeconds === -1 && countDownMinutes === 0) {
            clearInterval(timer);
            setIsShowResendLink(true);
        }
    }, [countDownSeconds])


    const handleChange = (otp) => setOtp(otp);

    const handleResendCode = async (e) => {
        setOtp("");
        e.preventDefault();
        if (userTwoFAInfo) {
            try {
                const data = await sendTwoFAEmail(userTwoFAInfo.uuid);
                if (data?.two_fa_token) {
                    const {two_fa_token, user_2fa} = data;
                    setTwoFAToken(two_fa_token);
                    setUserTwoFAInfo(user_2fa);
                    setIsShowResendLink(false);
                }
            } catch (error: any) {
                setError({message: error.response.data.detail, type: 'error'})
            }
        }
    }

    const handleVerifyOTP = async () => {
        setIsLoading(true);
        if (twoFAToken && !isShowResendLink) {
            try {
                const data = await verificationCode(twoFAToken, otp);

                if (data?.access_token && data?.token_type) {
                    const {access_token, token_type, user, refresh_token} = data;
                    setToken(access_token, token_type);
                    setRefreshToken(refresh_token);
                    setUser(user);
                    navigate('/workspace');
                }
                setIsLoading(false)
            } catch (error: any) {
                setIsLoading(false);
                setError({message: error.response.data.detail, type: 'error'});
            }
        }
    }

    useEffect(() => {
        if (otp.length === 6) {
            handleVerifyOTP()
        }
    }, [otp]);

    const handleChangeCaptcha = (token) => {
        if (token && token !== "") setIsCaptchaToken(true)
        else setIsCaptchaToken(false)
    }

    return (
        <div className="border border-rounded border-lightGray-100 px-4 py-6 rounded min-w-[400px]">
            <h3 className="text-center text-dark text-xl">Sign In</h3>
            {
                !isTwoFA ? (
                    <form onSubmit={handleSubmit}
                          className="flex flex-col">
                        <div className="form-control w-full">
                            <label className="label">User Name:</label>
                            <input type="text" name="username" value={formValues.username} onChange={handleChangeInput}
                                   className="input input-bordered w-full bg-white"/>
                        </div>
                        <div className="form-control w-full">
                            <label className="label">Password:</label>
                            <input type="password" name="password" value={formValues.password}
                                   onChange={handleChangeInput}
                                   className="input input-bordered w-full bg-white"/>
                        </div>
                        <div className="mt-2">
                            <Link to="/forget-password" type="button">
                                Forget Your Password?
                            </Link>
                        </div>
                        <div className="flex justify-center mt-2">
                            <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY || ""}
                                       onChange={handleChangeCaptcha}/>
                        </div>
                        <button type="submit"
                                className={`btn btn-primary mt-3 ml-auto w-[124px] ${(formValues.username !== "" && formValues.password !== "" && isCaptchaToken) ? "" : "btn-disabled"}`}>
                            {
                                isLoading ? <Loading/> : "Sign In"
                            }
                        </button>
                    </form>
                ) : (
                    <>
                        <form className="flex flex-col" onSubmit={e => e.preventDefault()}>
                            <div>
                                <p className="text-center whitespace-pre-line mt-2">
                                    Enter authentication code below we sent
                                    to {" \n "} {userTwoFAInfo?.email_address ? userTwoFAInfo.email_address : ""}
                                </p>
                                <OTPInput
                                    value={otp}
                                    onChange={handleChange}
                                    numInputs={6}
                                    shouldAutoFocus={true}
                                    inputStyle="!w-12 !h-12 border border-lightGray-100 focus:border-lightGray-100 outline-0 rounded-md bg-white"
                                    containerStyle="flex justify-between mt-4"
                                    renderSeparator={<span>-</span>}
                                    renderInput={(props) => <input {...props} />}
                                />
                                {
                                    !isShowResendLink ? (
                                        <span>0{countDownMinutes} : {countDownSeconds < 10 ? "0" : ""}{countDownSeconds}</span>) : (
                                        <div className="">
                                            <button onClick={handleResendCode} className="" type="button">
                                                Resend Code
                                            </button>
                                        </div>
                                    )
                                }
                            </div>
                        </form>
                        <button type="submit" onClick={handleVerifyOTP}
                                className={`btn btn-primary mt-3 ml-auto w-[124px] flex ${otp.length === 6 && !isShowResendLink ? "" : "btn-disabled"}`}>
                            {
                                isLoading ? <Loading /> : "Verify Code"
                            }
                        </button>
                    </>
                )
            }
        </div>
    )
}

export default SignInForm;