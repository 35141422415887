import React, {useEffect} from "react";
import Select from "../Select";
import {useModelComposer} from "../../shared/hooks/ModelComposer/useModelComposer";

type AnalyticsModelProps = {
    isOpenModalCopilot: boolean,
    setSelectedAnalyticsDataModel: (value: {
        data_type_name: string,
        data_type_uuid: number,
        is_required: boolean
    }) => void,
    selectedAnalyticModel: { label: string, value: string } | null,
    setSelectedAnalyticModel: (value: { label: string, value: string }) => void,
    dataModelStatusList: { analyticsDataModelName: string, status: boolean, data_import_uuid: string | null }[] | [],
    dataModelList: {
        data_type_name: string,
        data_type_uuid: string,
        is_required: boolean
    }[] | [],
    selectedDataModel?: {
        data_type_name: string,
        data_type_uuid: string,
        is_required: boolean
    } | null,
    onSelectDataModel: (value: number) => void
}

const AnalyticsModel = ({
                            isOpenModalCopilot,
                            setSelectedAnalyticModel,
                            selectedAnalyticModel,
                            dataModelList,
                            dataModelStatusList,
                            selectedDataModel,
                            onSelectDataModel
                        }: AnalyticsModelProps) => {

    const {analyticModelOption, loadAnalyticDataModel} = useModelComposer();

    const checkIsConfirmStatus = (dataModelName) => {
        const itemModel = dataModelStatusList.find(el => el.analyticsDataModelName === dataModelName);
        if (itemModel) {
            return itemModel.status
        }
        return false;
    }

    // useEffect(() => {
    //     if (!isOpenModalCopilot) {
    //         setSelectedAnalyticModel(analyticModelOption[0]);
    //     }
    // }, [isOpenModalCopilot]);

    useEffect(() => {
        loadAnalyticDataModel()
    }, []);

    useEffect(() => {
        setSelectedAnalyticModel(analyticModelOption[0]);
    }, [analyticModelOption]);

    return (
        <>
            <div className="h-1/2 flex flex-col border-b border-b-lightGray-100">
                <div>
                    <h2 className="text-base pb-1">Analytics Model:</h2>
                    <Select options={analyticModelOption} value={selectedAnalyticModel}
                            onChange={setSelectedAnalyticModel}
                            placeholder="Select" classNameWrapper="!min-w-full"/>
                </div>
                <div className="border-t border-lightGray-100 pt-2 h-[calc(100%-92px)]">
                    <h2 className="text-base pb-1">Data Model:</h2>
                    <div className="overflow-y-auto h-[calc(100%-44px)]">
                        {
                            dataModelList.length > 0 && dataModelList.map(item => {
                                return (
                                    <div key={item?.data_type_id} onClick={() => onSelectDataModel(item?.data_type_uuid)}
                                         className={`flex justify-between items-center px-3 rounded text-sm py-2 cursor-pointer ${selectedDataModel?.data_type_uuid === item?.data_type_uuid ? "bg-blue-100 text-primary" : ""}`}>
                                        <span className="capitalize">{item.data_type_name}</span>
                                        {
                                            dataModelStatusList.length > 0 && checkIsConfirmStatus(item.data_type_name) ? (
                                                <i className="icon icon-20 icon-success"/>) : null
                                        }
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default AnalyticsModel;