import React, {useEffect, useState} from 'react';
import ActionBar from "../../components/ActionBar";
import Tab from "../../components/Tab";
import ModalCopilot from "../../components/ModelComposer/ModalCopilot";
import Import from "../../components/ModelComposer/Import";
import ModalCheckStatus from "../../components/ModelComposer/ModalCheckStatus";
import ModalSpecialCopilot from "../../components/ModelComposer/ModalSpecialCopilot";
import Preprocessing from "../../components/ModelComposer/Preprocessing";
import Integration from "../../components/ModelComposer/Integration";
import Summary from "../../components/ModelComposer/Summary";
import ModalReportData from "../../components/ModelComposer/ModalReportData";
import DataFiles from "../../components/ModelComposer/DataFiles";
import AnalyticsModel from "../../components/ModelComposer/AnalyticsModel";
import {useModelComposer} from "../../shared/hooks/ModelComposer/useModelComposer";
import {useIsShowModalCopilot} from "../../context/IsShowModalCopilotContext";


const ModelComposer = () => {

    const {isShowModalCopilot} = useIsShowModalCopilot()
    const [selectedTab, setSelectedTab] = useState<number>(1);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(isShowModalCopilot);
    const [isOpenModalCheckStatus, setIsOpenModalCheckStatus] = useState<boolean>(false);
    const [isOpenModalSpecialCopilot, setIsOpenModalSpecialCopilot] = useState<boolean>(false);

    const {
        workspaceFiles,
        checkStatusDataModel,
        loadCheckStatusModel,
        selectedAnalyticModel,
        setSelectedAnalyticModel,
        dataModelList,
        dataModelStatusList,
        setDataModelStatusList,
        selectedDataModel,
        setSelectedDataModel,
        handleSelectDataModel,
        handleModelCompose,
        isOpenReportModal,
        handleCloseReportModal,
        handleClickLoad,
        setDataFileUploaded,
        dataFileUploaded,
        isDisabled,
        setStatusAirFlowIntegration,
        setStatusAirFlowPreProcessing,
        setStatusAirFlowFileImport,
        statusAirFlowIntegration,
        statusAirFlowPreProcessing,
        statusAirFlowFileImport,
        dataTable,
        dataTableSummary,
        columns,
        columnsSummary,
        displayPhaseName,
        handleDisableCompose,
        dataImports,
        selectedDataImportStatus,
        setSelectedDataImportStatus,
        loadDataImportStatus
    } = useModelComposer()

    const handleOpenModal = () => setIsOpenModal(true);

    const handleOpenModalCheckStatus = async () => {
        await loadCheckStatusModel();
        setIsOpenModalCheckStatus(true);
    }

    const handleOpenModalSpecialCopilot = () => setIsOpenModalSpecialCopilot(true);

    const handleCloseModal = () => setIsOpenModal(false);

    const handleCloseModalCheckStatus = () => setIsOpenModalCheckStatus(false);

    const handleCloseModalSpecialCopilot = () => setIsOpenModalSpecialCopilot(false);

    const resetPage = () => {
        setDataFileUploaded(null);
        setSelectedTab(1);
        if (dataModelList.length > 0) {
            setSelectedDataModel(dataModelList[0])
        }
    }

    const buttons = [
        {
            icon: "ai-chat",
            title: "Copilot",
            action: handleOpenModal
        },
        {
            icon: "model-compose",
            title: "Compose",
            action: handleModelCompose,
            className: handleDisableCompose()
        },
        {
            icon: "check-circle",
            title: "Check Status",
            action: handleOpenModalCheckStatus,
        },
        {
            icon: "refresh-circle",
            title: "Reset",
            action: resetPage
        }
    ]

    const handleChangeTab = (key) => setSelectedTab(key);

    useEffect(() => {
        setDataFileUploaded(null);
        setSelectedTab(1);
    }, [selectedDataModel]);

    useEffect(() => {
        if (dataModelStatusList && selectedDataModel) {
            const temp = dataModelStatusList.find(item => item.analyticsDataModelName === selectedDataModel?.data_type_name)
            if (temp?.data_import_uuid) {
                setDataFileUploaded({data_import: {uuid: temp.data_import_uuid}});
            }
        }
    }, [dataModelStatusList, selectedDataModel]);

    useEffect(() => {
        setSelectedDataImportStatus(null);
        if (dataImports) {
            const notConfirmedDataModel = dataImports.find(item => item.analytic_data_type_uuid === selectedDataModel?.data_type_uuid);
            if (notConfirmedDataModel) {
                loadDataImportStatus(notConfirmedDataModel.data_import_uuid);
                setDataFileUploaded({data_import: {uuid: notConfirmedDataModel.data_import_uuid}})
            }
        }
    }, [selectedDataModel]);

    return (
        <>
            <div className="flex flex-col flex-1 gap-y-1 h-[calc(100vh-64px)]">
                <ActionBar pageTitle="Model Composer" buttons={buttons} topic="data_import"/>
                <div className="flex-1 flex gap-x-1">
                    <div
                        className="shadow-sm h-[calc(100vh-132px)] gap-y-4 bg-white w-[265px] flex flex-col px-3 py-4">
                        <AnalyticsModel
                            isOpenModalCopilot={isOpenModal}
                            selectedAnalyticModel={selectedAnalyticModel}
                            setSelectedAnalyticsDataModel={handleSelectDataModel}
                            setSelectedAnalyticModel={setSelectedAnalyticModel}
                            dataModelList={dataModelList}
                            dataModelStatusList={dataModelStatusList}
                            selectedDataModel={selectedDataModel}
                            onSelectDataModel={handleSelectDataModel}
                        />
                        <DataFiles workspaceFiles={workspaceFiles}/>
                    </div>
                    <div
                        className="flex-1 h-[calc(100vh-132px)] flex flex-col gap-y-1 py-3 px-6 shadow bg-white">
                        <Tab activeKey={selectedTab} onClick={handleChangeTab}
                             tabContentClassName="h-[calc(100%-75px)]">
                            <Tab.Pane key={1} label="Import" disable={false}>
                                <Import onNext={handleChangeTab}
                                        dataFileUploaded={dataFileUploaded}
                                        setStatusAirFlow={setStatusAirFlowFileImport}
                                        statusAirFlow={statusAirFlowFileImport}
                                        setDataFileUploaded={setDataFileUploaded}
                                        selectedDataModel={selectedDataModel}
                                        selectedDataImportStatus={selectedDataImportStatus}
                                        setSelectedDataImportStatus={setSelectedDataImportStatus}
                                />
                            </Tab.Pane>
                            <Tab.Pane key={2} label="Preprocessing" disable={isDisabled("Preprocessing", "preprocessing", "convert_to_sql")}>
                                <Preprocessing onNext={handleChangeTab} selectedTab={selectedTab}
                                               setStatusAirFlow={setStatusAirFlowPreProcessing}
                                               statusAirFlow={statusAirFlowPreProcessing}
                                               dataImportId={dataFileUploaded?.data_import.uuid}
                                               onLoadReports={handleClickLoad} selectedDataModel={selectedDataModel}
                                               selectedDataImportStatus={selectedDataImportStatus}
                                />
                            </Tab.Pane>
                            <Tab.Pane key={3} label="Integration" disable={isDisabled("Integration", "data_integration", "preprocessing")}>
                                <Integration onNext={handleChangeTab} selectedTab={selectedTab}
                                             setStatusAirFlow={setStatusAirFlowIntegration}
                                             statusAirFlow={statusAirFlowIntegration}
                                             dataImportId={dataFileUploaded?.data_import.uuid}
                                             onLoadReports={handleClickLoad} selectedDataModel={selectedDataModel}
                                             selectedDataImportStatus={selectedDataImportStatus}
                                />
                            </Tab.Pane>
                            <Tab.Pane key={4} label="Summary" disable={isDisabled("Summary", "", "data_integration")}>
                                <Summary selectedTab={selectedTab} dataImportId={dataFileUploaded?.data_import.uuid}
                                         setDataModelStatusList={setDataModelStatusList} onLoadReports={handleClickLoad}
                                         dataModelStatusList={dataModelStatusList}
                                         disableConfirm={isDisabled("Summary", "", "data_integration")} selectedDataModel={selectedDataModel}
                                         dataModelList={dataModelList} setSelectedDataModel={setSelectedDataModel}
                                />
                            </Tab.Pane>
                        </Tab>
                    </div>
                </div>
            </div>
            <ModalCopilot isOpen={isOpenModal} handleClose={handleCloseModal}/>
            <ModalCheckStatus isOpen={isOpenModalCheckStatus} onClose={handleCloseModalCheckStatus}
                              checkStatusData={checkStatusDataModel}/>
            <ModalSpecialCopilot isOpen={isOpenModalSpecialCopilot} onClose={handleCloseModalSpecialCopilot}/>
            <ModalReportData onClose={handleCloseReportModal} columnsSummary={columnsSummary} columns={columns}
                             dataTable={dataTable} dataTableSummary={dataTableSummary} isOpen={isOpenReportModal}
                             tableName={selectedDataModel?.data_type_name} phaseName={displayPhaseName}/>
        </>
    )
}

export default ModelComposer;