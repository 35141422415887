import React, {createContext, ReactNode, useContext, useState} from "react";
import {IDashboard} from "../shared/interfaces/IDashboard";

type DashboardProviderProps = {
    children?: ReactNode
}

export const DashboardsContext = createContext<{ dashboards: IDashboard[] | [], setDashboards: (dashboards: IDashboard[]) => void }>({dashboards: [], setDashboards: () => {}})
export const DashboardsProvider = ({children}: DashboardProviderProps) => {

    const [dashboards, setDashboards] = useState<IDashboard[] | []>([]);

    return (
        <DashboardsContext.Provider value={{dashboards, setDashboards}}>
            {children}
        </DashboardsContext.Provider>
    )
}

export const useDashboards = () => useContext(DashboardsContext);
