import Item from "./Item";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {FormikErrors} from "formik";

type MultiSelectProps = {
    options: { value: number | string, label: string }[] | [],
    values: { value: number | string, label: string }[] | [],
    onChange: (item) => void,
    placeholder: string,
    disabled?: boolean,
    label?: string,
    classNameWrapper?: string,
    searchable?: boolean,
    required?: boolean,
    error?: string | string[] | FormikErrors<{value: number, label: string}>[],
}

const MultiSelect = ({
                         options,
                         values,
                         onChange,
                         placeholder,
                         disabled = false,
                         classNameWrapper = "",
                         label = "",
                         error,
                         searchable = false,
                         required = false,
                     }: MultiSelectProps) => {

    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [optionList, setOptionList] = useState(options || []);
    const selectRef = useRef<HTMLDivElement>(null);
    const [filterText, setFilterText] = useState("")

    useEffect(() => {
        document.addEventListener('mousedown', handleSelectIsOpen);

        return () => {
            document.removeEventListener('mousedown', handleSelectIsOpen);
        };
    }, [])

    const handleSelectIsOpen = (e) => {
        if (selectRef?.current && !selectRef.current.contains(e.target)) {
            setIsOpen(false);
            setFilterText("")
        }
    }

    useEffect(() => {
        setOptionList(options);
    }, [options])

    const handleItems = item => {
        const indexItem = values?.findIndex(value => value.value === item.value);

        if (indexItem === -1) {
            const tempValues = [...values];
            tempValues.push(item);
            onChange(tempValues)
        } else {
            const tempValues = [...values];
            tempValues.splice(indexItem, 1);
            onChange(tempValues);
        }
    }

    const handleRemoveItem = (e, id) => {
        e.stopPropagation();
        const indexItem = values?.findIndex(value => value.value === id);
        if (indexItem !== -1) {
            const tempValues = [...values];
            tempValues.splice(indexItem, 1);
            onChange(tempValues);
        }
    }

    const handleSelectItem = useCallback((item) => {
        const indexItem = values?.findIndex(value => value.value === item.value);
        return (indexItem !== -1);
    }, [values])

    useEffect(() => {
        if (filterText !== "") {
            const resultSearch = options.filter(option => option.label.toLowerCase().search(filterText.toLowerCase()) > -1);
            setOptionList(resultSearch)
        } else {
            setOptionList(options)
        }
    }, [filterText])


    const calculateDistanceToBottom = () => {
        const elementRect = selectRef.current?.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (elementRect) {
            return windowHeight - elementRect.bottom;
        }
        return 150;
    }

    return (
        <div className="form-group">
            <label className={`form-label ${required ? "required" : ""}`}>{label}</label>
            <div className={`select-wrapper ${classNameWrapper}`} ref={selectRef}>
                <div className={`select-input relative ${isOpen ? "list-open" : ""}${disabled ? "disabled" : ""}`}
                     onClick={() => setIsOpen(prevState => !prevState)}>
                <span className={`selected-items ${values?.length === 0 ? "" : "is-null"}`}>{values?.length > 0 ? (
                    values?.map((item) => {
                        return (<span className="px-1 py-2 mr-2 bg-light rounded whitespace-nowrap"
                                      key={item.value}>{item.label} <i
                            className="icon icon-24 icon-close-circle cursor-pointer"
                            onClick={(e) => handleRemoveItem(e, item.value)}/></span>)
                    })
                ) : placeholder}</span>
                    <span className={`transition-all ${isOpen ? "rotate-180" : ""}`}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.40001 9.59844L12 14.3984L17.6 9.59844" stroke="#1C274C" strokeWidth="1.5"
                          strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                </span>
                </div>
                <ul className={`select-list ${isOpen ? "is-open" : ""} ${calculateDistanceToBottom() < 150 ? "open-top" : ""}`}>
                    {
                        searchable ? <input type="text" className="form-input h-10 mb-1" value={filterText}
                                            onChange={e => setFilterText(e.target.value)}
                                            placeholder="search..."/> : null
                    }
                    {
                        optionList.length > 0 && optionList.map(item => {
                            return (
                                <Item item={item} key={item.value} isActive={handleSelectItem(item)} onClick={handleItems}/>
                            )
                        })
                    }
                </ul>
            </div>
            <span className="absolute text-xs text-red top-full left-0 whitespace-nowrap">
            {
                error !== "" ? <>{error}</> : null
            }
            </span>
        </div>
    )
}

export default MultiSelect;