import {Link, NavLink} from "react-router-dom";
import React from "react";
import {ISidebarMenuItem} from "../../shared/interfaces/ISidebarMenuItem";


interface CollapseMenuItemProps extends ISidebarMenuItem {
    isExpanded: boolean
}
const CollapseLinkMenuItem = ({id, title,icon, path, isExpanded}: Required<CollapseMenuItemProps>) => {

    return (
        <NavLink to={path} className={({isActive}) => `collapse-menu-item p-3 text-sm rounded mt-2 first:mt-0 ${isActive ? "text-blue font-medium active" : ""} ${isExpanded && isActive ? "bg-blue-100" : ""}`} title={title}><li className="whitespace-nowrap"><i className={`icon icon-24 icon-${icon} ${isExpanded ? "" : "bg-white"}`}/><span className="ml-3">{title}</span></li></NavLink>
    )
}

export default CollapseLinkMenuItem