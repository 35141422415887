import React, {useEffect, useState} from "react";
import ActionBar from "../../../components/ActionBar";
import SettingSidebar from "../../../components/SettingSidebar";
import Table from "../../../components/Table";
import {deleteTeam, teams} from "../../../services";
import {useNavigate} from "react-router-dom";
import {useError} from "../../../context/ErrorContext";
import InitialProfileImage from "../../../components/Profile/InitialProfileImage";
import {IUser} from "../../../shared/interfaces/IUser";

const Teams = () => {

    const [selectedItem, setSelectedItem] = useState<string[]>([]);
    const [teamList, setTeamList] = useState([]);

    const navigate = useNavigate();
    const {setError} = useError();

    const handleEdit = () => {
        navigate(`/setting/teams/edit/${selectedItem[0]}`);
    }

    const handleCreate = () => {
        navigate('/setting/teams/add')
    }

    const handleDelete = async () => {
        try {
            await deleteTeam(selectedItem[0])
            unCheckItem(selectedItem[0]);
            loadTeamList();
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    const loadTeamList = async () => {
        setTeamList([])
        try {
            const data = await teams();
            setTeamList(data);
        } catch (error: any) {
            setError({message: error?.response?.data?.detail, type : 'error'});
        }
    }

    const buttons = [
        {
            icon: "plus-circle",
            title: "Create",
            action: handleCreate
        },
        {
            icon: "edit-circle",
            title: "Edit",
            action: handleEdit,
            className: selectedItem.length === 1 ? "" : "disabled",
        },
        {
            icon: "delete",
            title: "Delete",
            action: handleDelete,
            className: selectedItem.length === 1 ? "" : "disabled",
        },
        {
            icon: "refresh-circle",
            title: "Refresh",
            action: loadTeamList
        }
    ]

    const teamProfile = (title) => {
        const arrayTitle = title.split(' ');
        if (arrayTitle.length > 1) {
            return title ? arrayTitle[0].charAt(0).toUpperCase() + arrayTitle[1].charAt(0).toUpperCase() : null;
        }
        return title ? title.charAt(0).toUpperCase() + title.at(-1).toUpperCase() : null;
    }

    const memberAvatars = (members_avatar: IUser[]) => {

        if (members_avatar.length === 0) return null

        const userAvatar = (user: IUser) => {
            return user && user.user_name ? `${user.user_name.charAt(0).toUpperCase() + user.user_name.charAt(user.user_name.length - 1).toUpperCase()}` : null;
        }

        return (
            <div className="avatar-group -space-x-2">
                {
                    members_avatar.slice(0,3).map((item: IUser) => {
                        return (
                            item.avatar_url && item.avatar_url !== "" ? (
                                <div className="avatar border-0">
                                    <div className="w-6">
                                        <img src={item.avatar_url} alt="avatar bg-contain" className="w-6 h-6 object-cover"/>
                                    </div>
                                </div>
                            ): (
                                <span
                                    className="inline-block w-6 h-6 text-[10px] bg-blue-400 rounded-full leading-[24px] text-center select-none">{userAvatar(item)}</span>
                            )
                        )
                    })
                }
                {
                    members_avatar.length > 3 ? (
                        <div className="avatar placeholder border-0">
                            <div className="w-6 bg-blue-400 text-[10px]">
                                <span>+{members_avatar.length - 3}</span>
                            </div>
                        </div>
                    ) : null
                }
            </div>
        )
    }

    const columns = [
        {
            key: 'select',
            title: 'select',
            render: (_, {uuid}) => (
                <label className="label cursor-pointer p-0">
                    <input type="checkbox" onChange={(e) => handleSelectedItems(e, uuid)}
                           checked={selectedItem.includes(uuid)}
                           className="checkbox checkbox-primary border-dark-400 rounded-sm"/>
                </label>
            )
        },
        {
            key: 'avatar',
            title: 'avatar',
            render: (_, {avatar, title}) => (
                avatar ? <img src="/images/avatar.png" alt="avatar bg-contain" className="w-6 h-6"/> :
                    <InitialProfileImage userProfile={teamProfile(title)}/>
            )
        },
        {
            key: 'title',
            title: 'Team Name'
        },
        {
            key: 'member_avatar',
            title: 'Member Avatar',
            render: (_, {members}) => (memberAvatars(members))
        },
        {
            key: 'is_active',
            title: 'Active',
            render: (_, {is_active}) => (
                <span>{is_active ? "Active" : "Not Active"}</span>
            )
        },
    ]

    const handleSelectedItems = (e, uuid) => {
        const {checked} = e.target;
        if (checked) {
            const tempSelected = [...selectedItem];
            tempSelected.push(uuid);
            setSelectedItem(tempSelected);
        } else {
            unCheckItem(uuid);
        }
    }

    const unCheckItem = (uuid) => {
        const selectedIndex = selectedItem.indexOf(uuid);
        const tempSelected = [...selectedItem];
        tempSelected.splice(selectedIndex, 1);
        setSelectedItem(tempSelected)
    }

    useEffect(() => {
        loadTeamList();
    },[])

    return (
        <>
            <div className="flex gap-x-1">
                <div className="flex h-[calc(100vh-64px)]">
                    <SettingSidebar/>
                </div>
            </div>
            <div className="flex flex-col flex-1 gap-y-1">
                <section className="h-full flex flex-col">
                    <ActionBar goBack={false} pageTitle="Teams" buttons={buttons} topic="team" isShow={false}/>
                    <div className="flex-1 bg-white shadow-sm mt-1 p-6 h-[calc(100vh-128px)] overflow-y-auto">
                        <Table columns={columns} data={teamList}/>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Teams;