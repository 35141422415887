import React, {createContext, ReactNode, useContext, useState} from "react";

type errorProps = {
    message: string,
    type: string
}

type ErrorProviderProps = {
    children?: ReactNode
}

export const ErrorContext = createContext<{ error: errorProps, setError: (error: errorProps) => void }>({
    error: {message: '', type: 'error'},
    setError: () => {
    }
})

export const ErrorProvider = ({children}: ErrorProviderProps) => {

    const [error, setError] = useState<errorProps>({message: '', type: 'error'})

    return (
        <ErrorContext.Provider value={{error, setError}}>
            {children}
        </ErrorContext.Provider>
    )
}

export const useError = () => useContext(ErrorContext);