import {useEffect, useState} from "react";
import {
    airFlowStatus,
    azureStatus,
    connectorTypes,
    dataImportNotification,
    uploadDataImportFile
} from "../../../services";
import {connectorTypeToList} from "../../utils/toList";
import {useError} from "../../../context/ErrorContext";
import {FAILED, SUCCESS} from "../../constant";
import {isNullOrUndefinedOrEmpty} from "../../utils/helper";
import data from "../../../mockData/data.json";
import {useWorkSpace} from "../../../context/WorkSpaceContext";

type UseImportFileProps = {
    setDataFileUploaded: (values: any) => void,
    dataFileUploaded: any,
    setStatusAirFlow: (value: boolean) => void,
    selectedDataModel?: {
        data_type_name: string,
        data_type_uuid: string,
        is_required: boolean
    } | null,
    selectedDataImportStatus: {
        [key: string]: { status: string, dag_id: string, dag_run_id: string, pipeline_type?: string  }
    } | null,
    setSelectedDataImportStatus: (value: null) => void
}

let intervalCheckStatus: NodeJS.Timer;

export const useImportFile = ({setDataFileUploaded, dataFileUploaded, setStatusAirFlow, selectedDataModel, selectedDataImportStatus, setSelectedDataImportStatus}: UseImportFileProps) => {

    const [files, setFiles] = useState<File[] | null>(null);
    const [connectorTypeList, setConnectorTypeList] = useState<{ value: number, label: string }[] | []>([])
    const [selectedConnectorType, setSelectedConnectorType] = useState<{ value: number, label: string } | null>(null);
    const [progressUploaded, setProgressUploaded] = useState<number>(0);
    const [isDisabledUpload, setIsDisabledUpload] = useState<boolean>(false);
    const [dataConvertToSQL, setDataConvertToSQL] = useState<{dag_id: string, dag_run_id?: string} | null>(null)
    const {setError} = useError();
    const {workSpace} = useWorkSpace();
    const {requiredInformation} = data;

    const loadConnectorTypes = async () => {
        try {
            const data = await connectorTypes();
            setConnectorTypeList(connectorTypeToList(data));
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    const handleUploadFile = async (file) => {
        setProgressUploaded(1);
        setStatusAirFlow(false);
        setIsDisabledUpload(true);
        try {
            const formData = new FormData();

            formData.append("file", file);
            formData.append("connector_uuid", workSpace?.connector_uuid ? workSpace?.connector_uuid.toString() : "1");
            formData.append("analytic_data_type_uuid", selectedDataModel?.data_type_uuid ? selectedDataModel.data_type_uuid : "1");

            const data = await uploadDataImportFile(formData);
            // setDataFileUploaded(data);
            setDataFileUploaded({data_import: {uuid: data.data_import.uuid}})
            setDataConvertToSQL({dag_id: data.dag_id, dag_run_id: data.dag_run_id});
            setSelectedDataImportStatus(null);
            clearInterval(intervalCheckStatus);
            setProgressUploaded(50);
            setIsDisabledUpload(false);
            await createFileUploadNotification(true, file.name)
        } catch (error: any) {
            await createFileUploadNotification(false, file.name)
            setError({message: error.response.data.detail, type: 'error'});
            setIsDisabledUpload(false)
        }
        setIsDisabledUpload(false)
    }

    const createFileUploadNotification = async (uploadStatus: boolean, fileName: string) => {
        try {
            const values = {
                input_data: uploadStatus,
                workspace_uuid: workSpace?.uuid,
                analytics_uuid: selectedDataModel?.data_type_uuid,
                file_name: fileName
            }
            await dataImportNotification(values);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    useEffect(() => {
        if (files && files?.length > 0 && selectedDataModel) {
            handleUploadFile(files[0])
        }
    }, [files]);

    const handleCheckStatusAirFlow = async () => {
        try {
            const values = {
                dataImportId: dataFileUploaded?.data_import.uuid,
                dagaId: dataConvertToSQL?.dag_id,
                runId: dataConvertToSQL?.dag_run_id
            }

            const data = await airFlowStatus(values);
            if (data.status === SUCCESS) {
                setStatusAirFlow(true);
                clearInterval(intervalCheckStatus);
                setProgressUploaded(100);
            }
            if (data.status === FAILED) {
                setStatusAirFlow(false);
                clearInterval(intervalCheckStatus);
                setError({message: "There was an error while importing the data model. To resolve this, please ensure that it meets the specified format requirements.", type: 'error'})
            }
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    const handleCheckStatusAzure = async () => {
        try {
            const values = {
                dataImportId: dataFileUploaded?.data_import.uuid,
                runId: dataConvertToSQL?.dag_id,
            }

            const data = await azureStatus(values);
            if (data.status === SUCCESS) {
                setStatusAirFlow(true);
                clearInterval(intervalCheckStatus);
                setProgressUploaded(100);
            }
            if (data.status === FAILED) {
                setStatusAirFlow(false);
                clearInterval(intervalCheckStatus);
                setError({message: "There was an error while importing the data model. To resolve this, please ensure that it meets the specified format requirements.", type: 'error'})
            }
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    useEffect(() => {
        if (dataFileUploaded && dataConvertToSQL?.dag_id && dataConvertToSQL?.dag_run_id && !isNullOrUndefinedOrEmpty(dataFileUploaded?.data_import)) {
            intervalCheckStatus = setInterval(() => {
                handleCheckStatusAirFlow()
            }, 5000)
        } else if (dataFileUploaded && dataConvertToSQL?.dag_id && !isNullOrUndefinedOrEmpty(dataFileUploaded?.data_import)){
            intervalCheckStatus = setInterval(() => {
                handleCheckStatusAzure()
            }, 5000)
        }
    }, [dataConvertToSQL]);

    useEffect(() => {
        if (selectedDataImportStatus && selectedDataImportStatus["convert_to_sql"] && selectedDataImportStatus["convert_to_sql"].status === "running") {
            setDataConvertToSQL({dag_id: selectedDataImportStatus["convert_to_sql"].dag_id, dag_run_id: selectedDataImportStatus["convert_to_sql"].dag_run_id});
        }
        if (selectedDataImportStatus && selectedDataImportStatus["convert_to_sql"] && selectedDataImportStatus["convert_to_sql"].status === SUCCESS) {
            setStatusAirFlow(true);
        }
    }, [selectedDataImportStatus]);

    const renderRequiredInformation = () => {
        if (selectedDataModel && requiredInformation.length > 0) {
            return requiredInformation.find(item => item.data_type_uuid === selectedDataModel.data_type_uuid)?.text ?? ""
        }
        return ""
    }

    const renderFindData = () => {
        if (selectedDataModel && requiredInformation.length > 0) {
            return requiredInformation.find(item => item.data_type_uuid === selectedDataModel.data_type_uuid)?.findData ?? ""
        }
        return ""
    }


    useEffect(() => {
        setStatusAirFlow(false);
        setProgressUploaded(0);
        setFiles(null);
        clearInterval(intervalCheckStatus);
    }, [selectedDataModel]);

    useEffect(() => {
        loadConnectorTypes();

        return () => {
            clearInterval(intervalCheckStatus);
        }
    }, []);


    return {
        files,
        setFiles,
        connectorTypeList,
        setSelectedConnectorType,
        selectedConnectorType,
        progressUploaded,
        renderFindData,
        renderRequiredInformation,
        isDisabledUpload
    }
}