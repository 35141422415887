import Item from "./Item";
import React, { useEffect, useRef, useState } from "react";
import { WidgetProps } from "@rjsf/utils";

const CustomSelectField = (props: WidgetProps) => {

    const [isOpen, setIsOpen] = useState(false);
    const selectRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        document.addEventListener('mousedown', handleSelectIsOpen);

        return () => {
            document.removeEventListener('mousedown', handleSelectIsOpen);
        };
    }, [])

    const handleSelectIsOpen = (e) => {
        if (selectRef?.current && !selectRef.current.contains(e.target)) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        setIsOpen(false)
    }, [props.value])

    return (
        <div className={`select-wrapper`} ref={selectRef}>
            <div className={`select-input relative ${isOpen ? "list-open" : ""}${props.disabled ? "disabled" : ""}`}
                onClick={() => setIsOpen(prevState => !prevState)}>
                <span className={!props.value === null ? "" : "is-null"}>{props.value !== null ? props?.options?.enumOptions?.find(x => x.value === props?.value)?.label : props.placeholder}</span>
                <span className={`transition-all ${isOpen ? "rotate-180" : ""}`}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M6.40001 9.59844L12 14.3984L17.6 9.59844" stroke="#1C274C" strokeWidth="1.5"
                            strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </span>
            </div>
            <ul className={`select-list ${isOpen ? "is-open" : ""}`}>
                {
                    props?.options?.enumOptions && props?.options?.enumOptions.map(item => {
                        return (
                            <Item item={item} key={item.value} isActive={item.value === props.value} onClick={({ value }) => props.onChange(value)} />
                        )
                    })
                }
            </ul>
        </div>
    )
}

export default CustomSelectField;