import React from "react";
import ActionBar from "../../../components/ActionBar";
import DescriptionPage from "../../../components/SettingSidebar/DescriptionPage";
import Form from "./Form";
import {createTeam} from "../../../services";
import {useNavigate} from "react-router-dom";
import {useError} from "../../../context/ErrorContext";
import {useAuth} from "../../../context/AuthContext";

const AddTeam = () => {

    const navigate = useNavigate();
    const {setError} = useError();
    const {user} = useAuth();

    const onSubmit = async (values) => {
        const formValues = {
            title: values.title.trim(),
            description: values.description.trim(),
            member_uuids: values.member_uuids.map(item => item.value),
            workspace_uuids: values.workspaces.map(item => item.value),
            organization_uuid: user?.organization_uuid
        };
        try {
            await createTeam(formValues);
            navigate('/setting/teams')
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    return (
        <div className="flex flex-col gap-x-1 gap-y-1 w-full">
            <div className="flex flex-col flex-1 gap-y-1">
                <ActionBar goBack={true} pageTitle="Team" topic="team" isShow={false}/>
            </div>
            <div className="flex h-[calc(100vh-133px)] gap-x-1">
                <DescriptionPage>
                    <p>add / edit teams and team member</p>
                </DescriptionPage>
                <section className="h-full flex flex-col p-6 bg-white shadow-sm flex-1 overflow-y-auto">
                    <div className="border border-light-100 rounded-lg p-6 grid grid-cols-3">
                        <Form onSubmit={onSubmit} />
                    </div>
                </section>
            </div>
        </div>
    )
}

export default AddTeam;