import React, {createContext, useContext, useState} from "react";


const IsShowModalCopilotContext = createContext<{ isShowModalCopilot: boolean, setIsShowModalCopilot: (model: boolean) => void }>({isShowModalCopilot: true, setIsShowModalCopilot: () => {}});

type IsShowModalCopilotProviderProps = {
    children: React.ReactNode;
}

export const IsShowModalCopilotProvider = ({children}: IsShowModalCopilotProviderProps) => {
    const [isShowModalCopilot, setIsShowModalCopilot] = useState(true);

    return (
        <IsShowModalCopilotContext.Provider value={{isShowModalCopilot, setIsShowModalCopilot}}>
            {children}
        </IsShowModalCopilotContext.Provider>
    )
}


export const useIsShowModalCopilot = () => useContext(IsShowModalCopilotContext);