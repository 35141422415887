import React, {createContext, ReactNode, useContext, useState} from "react";
import {IUser} from "../shared/interfaces/IUser";

type UserProviderProps = {
    children?: ReactNode
}

export const AuthContext = createContext<{ user: IUser , setUser: (user: IUser) => void }>({user: {}, setUser: () => {}})

export const AuthProvider = ({children}: UserProviderProps) => {

    const [user, setUser] = useState<IUser | {}>({});

    return (
        <AuthContext.Provider value={{user, setUser}}>
            {children}
        </AuthContext.Provider>
    )
}

export const useAuth = () => useContext(AuthContext);
