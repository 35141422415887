import React from "react";
import {Link} from "react-router-dom";
import {ISidebarMenuItem} from "../../shared/interfaces/ISidebarMenuItem";

interface SidebarMenuItemProps extends Required<ISidebarMenuItem> {
    isExpanded?: boolean,
    isActive?: boolean,
}

const SidebarMenuItem = ({path, icon, title, isExpanded, isActive}: SidebarMenuItemProps) => {

    return (
        <li className={`text-sm rounded mt-2 first:mt-0 flex overflow-x-hidden ${isExpanded && isActive ? "bg-blue-100 text-primary" : ""} ${isActive ? "active" : ""}`}>
            <Link to={path} className="block py-3 px-2 grow-0 shrink-0 basis-auto whitespace-nowrap w-full" replace={true} title={title}>
                <i className={`icon icon-24 icon-${icon}`}/>
                <span className="ml-3">{title}</span>
            </Link>
        </li>
    )
}


export default SidebarMenuItem;