import Table from "../Table";
import Modal from "../Modal";
import React from "react";

type ModalReportDataProps = {
    isOpen: boolean,
    onClose: () => void,
    columns: { key: string, title: string }[] | [],
    dataTable: any,
    columnsSummary: { key: string, title: string }[] | [],
    dataTableSummary: any,
    tableName: string | undefined,
    phaseName: string
}

const ModalReportData = ({isOpen, onClose, columns, dataTable, columnsSummary, dataTableSummary, tableName, phaseName}: ModalReportDataProps) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} isCancelBtn={false}>
            <h3 className="text-base text-left">First {dataTable.length > 0 ? dataTable.length : ""} <span className="capitalize">{tableName || ""}</span> Records {phaseName !== "" ? `containing ${phaseName} data` : ""}:</h3>
            <Table columns={columns} data={dataTable}/>
            {/*<div className="mt-6">*/}
            {/*    <h3 className="text-base text-left">Records Summary (Number of {phaseName} data in each field):</h3>*/}
            {/*    <Table columns={columnsSummary} data={dataTableSummary}/>*/}
            {/*</div>*/}
        </Modal>
    )
}


export default ModalReportData;