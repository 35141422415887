import SignIn from "../pages/Auth/SignIn";
import MainLayout from "../layouts/MainLayout";
import Model from "../pages/Model";
import ModelLayout from "../layouts/ModelLayout";
import Workspace from "../pages/Workspace";
import RunAnalytics from "../pages/Model/RunAnalytics";
import AIQuery from "../pages/Model/AICopilot/AIQuery";
import WorkspaceLayout from "../layouts/WorkspaceLayout";
import ModelComposer from "../pages/ModelComposer";
import ExecutionProgress from "../pages/ExecutionProgress";
import Detail from "../pages/ExecutionProgress/Detail";
import Statistics from "../pages/Model/Statistics";

//  setting pages
import Organization from "../pages/Settings/Organization";
import EditOrganization from "../pages/Settings/Organization/Edit";
import Subscription from "../pages/Settings/Subscription";
import Teams from "../pages/Settings/Teams";
import EditTeam from "../pages/Settings/Teams/Edit";
import AddTeam from "../pages/Settings/Teams/Add";
import Users from "../pages/Settings/Users";
import EditUser from "../pages/Settings/Users/Edit";
import SettingsLayout from "../layouts/SettingsLayout";
import AddUser from "../pages/Settings/Users/Add";
import Message from "../pages/Message";
import Support from "../pages/Support";
import CreateTicket from "../pages/Support/CreateTicket";
import ViewTicket from "../pages/Support/View";
import MasterData from "../pages/Model/MasterData";
import Processes from "../pages/Model/Processes";
import ForgetPassword from "../pages/Auth/ForgetPassword";
import ResetPassword from "../pages/Auth/ResetPassword";


export const mainRoutes = [

    {
        id: 1,
        path: '/*',
        component: MainLayout,
        title: 'dashboard'
    },
    {
        id: 2,
        path: '/sign-in',
        component: SignIn,
        title: 'sign in'
    },
    {
        id: 3,
        path: '/forget-password',
        component: ForgetPassword,
        title: 'forget password'
    },
    {
        id: 4,
        path: '/reset-password/*',
        component: ResetPassword,
        title: 'reset password'
    },
]

export const mainLayoutRoutes = [
    {
        id: 1,
        title: "dashboard",
        path: "/",
        to: "/workspace",
    },
    {
        id: 2,
        title: "Workspaces",
        path: "/workspace",
        component: Workspace,
    },
    {
        id: 10,
        title: "Run Analytics",
        path: "/workspace/:workspaceUUId/model-explorer/:modelUUId/run-analytics",
        component: RunAnalytics
    },
    {
        id: 3,
        title: "Model Explorer",
        path: "/workspace/:workspaceUUId/model-explorer/:modelUUId/*",
        component: ModelLayout,
    },
    {
        id: 4,
        title: "Workspaces",
        path: "/workspace/:workspaceUUId/*",
        component: WorkspaceLayout,
    },
    {
        id: 8,
        title: "Setting",
        path: "/setting/*",
        component: SettingsLayout,
        permission: "organization admin"
    }
]

export const workspaceLayoutRoutes = [
    {
        id: 1,
        title: "Model Explorer",
        path: "model-explorer",
        component: Model,
    },
    {
        id: 2,
        title: "Model Composer",
        path: "/model-composer",
        component: ModelComposer
    },
    {
        id: 3,
        title: "Execution Progress",
        path: "/execution-progress",
        component: ExecutionProgress
    },
    {
        id: 4,
        title: "Detail Execution Progress",
        path: "/execution-progress/detail/:uuid",
        component: Detail
    },
    {
        id: 4,
        title: "Support",
        path: "support",
        component: Support
    },
    {
        id: 5,
        title: "Create Ticket",
        path: "/support/create",
        component: CreateTicket
    },
    {
        id: 6,
        title: "View Ticket",
        path: "/support/view/:uuid",
        component: ViewTicket
    },
    {
        id: 7,
        title: "Messages",
        path: "/message",
        component: Message
    }
]

export const modelLayoutRoutes = [
    {
        id: 1,
        title: "Ai Query",
        path: "ai-copilot/ai-query",
        component: AIQuery
    },
    {
        id: 2,
        title: "Statistics",
        path: "statistics",
        component: Statistics
    },
    {
        id: 3,
        title: "Statistics",
        path: "statistics/:title",
        component: Statistics
    },
    {
        id: 4,
        title: "Master Data",
        path: "master-data",
        component: MasterData
    },
    {
        id: 5,
        title: "Master Data",
        path: "master-data/:title",
        component: MasterData
    },
    {
        id: 6,
        title: "Processes",
        path: "processes",
        component: Processes
    },
    {
        id: 7,
        title: "Processes",
        path: "processes/:title",
        component: Processes
    },
]

export const settingsLayoutRoutes = [
    {
        id: 1,
        title: "Setting | Organization",
        path: "/organization",
        component: Organization
    },
    {
        id: 2,
        title: "Setting | Edit Organization",
        path: "/organization/edit",
        component: EditOrganization
    },
    {
        id: 3,
        title: "Setting | Subscription",
        path: "/subscription",
        component: Subscription
    },
    {
        id: 4,
        title: "Setting | Teams",
        path: "/teams",
        component: Teams
    },
    {
        id: 5,
        title: "Setting | Add Teams",
        path: "/teams/add",
        component: AddTeam
    },
    {
        id: 6,
        title: "Setting | Edit Teams",
        path: "/teams/edit/:uuid",
        component: EditTeam
    },
    {
        id: 7,
        title: "Setting | Users",
        path: "/users",
        component: Users
    },
    {
        id: 8,
        title: "Setting | Edit Users",
        path: "/users/edit/:uuid",
        component: EditUser
    },
    {
        id: 9,
        title: "Setting | Add Users",
        path: "/users/add",
        component: AddUser
    }
]