import React from "react";
import {FormikProps} from "formik";

type TextAreaProps = {
    className?: string,
    label?: string,
    value?: string,
    onChange?: (e) => void,
    name: string,
    rows?: number,
    formik?: FormikProps<any>,
    required?: boolean,
    placeholder?: string
}

const TextArea = ({label = "", className = "", rows = 4, onChange, name, placeholder = "",  value, formik, required = false,}: TextAreaProps) => {
    return (
        <div className="form-group">
            <label className={`form-label ${required ? "required" : ""}`}>{label}</label>
            <textarea
                className={`form-input resize-none ${className}`}
                rows={rows}
                onChange={onChange || formik?.handleChange}
                onBlur={formik?.handleBlur}
                name={name}
                value={value || formik?.values[name]}
                placeholder={placeholder}
            >
            </textarea>
            <span className="absolute top-full left-0 whitespace-nowrap text-xs text-red">
                {
                    formik?.touched[name] && formik?.errors[name] ? <>{formik?.errors[name]}</> : null
                }
            </span>
        </div>
    )
}

export default TextArea;