import React from "react";
import {IFile} from "../../shared/interfaces/IFile";

type DataFilesProps = {
    workspaceFiles: IFile[] | null
}
const DataFiles = ({workspaceFiles}: DataFilesProps) => {

    return (
        <div className="h-1/2 flex flex-col overflow-hidden">
            <h2 className="text-base pb-1">Data Files:</h2>
            <div className="flex-1 overflow-y-auto py-2 flex flex-col gap-y-2">
                {
                    workspaceFiles && workspaceFiles.map(fileItem => {
                        return (
                            <div className="py-1 flex items-center flex-nowrap text-sm"
                                 key={fileItem.id}>
                                <i className="icon icon-24 icon-layers"/>
                                <span
                                    className="ml-1 w-[calc(100%-24px)] truncate">{fileItem.title}</span>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default DataFiles;