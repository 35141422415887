import React from "react";
import {useModel} from "../../context/ModelContext";
import {useNavigate, useParams} from "react-router-dom";

const ModelTitle = () => {

    const {workspaceUUId} = useParams();

    const {model} = useModel();
    const navigate = useNavigate();
    const handleClick = () => {
        navigate(`/workspace/${workspaceUUId}/model-explorer`);
    }

    return (
        <div className="py-5 px-6 bg-white shadow-sm flex items-center">
            <button onClick={handleClick} className="flex"><i className="icon icon-24 icon-backward" /></button><div className="ml-3 truncate max-w-[calc(100%-36px)]" title={model?.title}>{model?.title}</div>
        </div>
    )
}

export default ModelTitle;