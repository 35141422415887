import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import SettingTitle from "./settingTitle";
import SidebarMenuItem from "../MainSidebar/SidebarMenuItem";
import data from "../../mockData/data.json";
import {useExpandedSidebar} from "../../context/ExpandedSidebarContext";
const SettingSidebar = () => {

    const {SettingSidebarMenuItems} = data

    const {pathname} = useLocation();
    const {expandedSidebar, setExpandedSidebar} = useExpandedSidebar();

    const handleClick = () => {
        setExpandedSidebar(prevState => !prevState)
    }

    return (
        <div className={`h-[calc(100vh-64px)] flex flex-col transition-all ${expandedSidebar ? "max-w-[265px] w-[265px]" : "max-w-[73px]"}`}>
            <SettingTitle/>
            <nav className="py-5 px-4 bg-white shadow-sm mt-1 flex-1 relative">
                <ul className={`flex list-none flex-col main-sidebar-list`}>
                    {
                        SettingSidebarMenuItems.map(item => {
                            return (
                                <SidebarMenuItem {...item} key={item.id} isExpanded={expandedSidebar} isActive={`${pathname}` === item.path}/>
                            )
                        })
                    }
                    {
                        !expandedSidebar && <span className="menu-item"/>
                    }
                </ul>
                <button onClick={handleClick} className="shadow-sm absolute inset-y-1/2 right-[-12px] bg-white h-fit rounded-full p-1"><i className={`icon icon-24 icon-expanded transition-all ${expandedSidebar ? "rotate-180" : ""}`} /></button>
            </nav>
        </div>
    )
}

export default SettingSidebar;