import React, {useEffect} from 'react';
import {Routes, Route} from "react-router-dom";
import {mainRoutes} from "./routes";
import {AuthProvider} from "./context/AuthContext";
import {useError} from "./context/ErrorContext";
import toast, {Toaster, useToasterStore} from "react-hot-toast";
import {WorkSpaceProvider} from "./context/WorkSpaceContext";
import {isNullOrUndefinedOrEmpty} from "./shared/utils/helper";
import ErrorBoundary from "./context/ErrorBoundry";
import NotFound from "./pages/NotFound";
import Alert from "./components/Alert";
import client, {setRefreshToken} from "./services/config";
import useFrameBusting from "./shared/hooks/useFrameBusting";

const App = () => {

    const {error} = useError();
    const {toasts} = useToasterStore();
    useFrameBusting();

    useEffect(() => {
        if (!isNullOrUndefinedOrEmpty(error) && error.message && error.message !== "") {
            toast[error.type]((t) => (
                <Alert message={error.message} t={t} />
            ))
        }
    }, [error]);

    useEffect(() => {
        toasts
            .filter((t) => t.visible)
            .filter((_, i) => i >= 1)
            .forEach((t) => toast.dismiss(t.id));
    }, [toasts]);

    const handleChangeStorage = (event) => {
        if (event.key === "token" && event.newValue !== null) {
            localStorage.setItem("token", event.newValue);
            client.defaults.headers.common['Authorization'] = `bearer ${event.newValue}`;
        }
        if (event.key === "token_type" && event.newValue !== null) {
            localStorage.setItem("token_type", event.newValue);
        }
        if (event.key === "refresh_token" && event.newValue !== null) {
            setRefreshToken(event.newValue);
        }
        if (event.key === "token" && event.newValue === null) {
            delete client.defaults.headers.common["Authorization"];
            localStorage.removeItem("token");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("token_type");
        }
    }

    useEffect(() => {
        window.addEventListener("storage", handleChangeStorage);

        return () => {
            window.removeEventListener("storage", handleChangeStorage)
        }
    },[])

    return (
        <ErrorBoundary>
            <AuthProvider>
                <WorkSpaceProvider>
                    <Routes>
                        {
                            mainRoutes.map(route => {
                                return <Route key={route.id} path={route.path} element={<route.component/>}/>
                            })
                        }
                        <Route path="*" element={<NotFound/>}/>
                    </Routes>
                    <Toaster toastOptions={{duration: 20000}}/>
                </WorkSpaceProvider>
            </AuthProvider>
        </ErrorBoundary>
    );
};

export default App;
