import React from "react";
import {useWorkSpace} from "../../context/WorkSpaceContext";
import {useNavigate} from "react-router-dom";

type WorkSpaceTitleProps = {
    isExpanded: boolean
}
const WorkSpaceTitle = ({isExpanded}: WorkSpaceTitleProps) => {

    const navigate = useNavigate();
    const {workSpace} = useWorkSpace();

    const handleClick = () => {
        navigate('/workspace');
    }

    return (
        <div className="py-5 px-6 bg-white shadow-sm flex items-center gap-x-3">
            <i className={`icon icon-24 icon-backward cursor-pointer`} onClick={handleClick}/><div className={`truncate max-w-[calc(100%-36px)] ${isExpanded ? "block" : "hidden"}`} title={workSpace?.title}>{workSpace?.title}</div>
        </div>
    )
}

export default WorkSpaceTitle;