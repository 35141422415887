import React, {useEffect, useState} from "react";
import WorkspaceItem from "../../components/Workspace/WorkspaceItem";
import {IWorkSpace} from "../../shared/interfaces/IWorkSpace";
import {deleteWorkspace, workspaces} from "../../services";
import {useError} from "../../context/ErrorContext";
import ActionBar from "../../components/ActionBar";
import CreateNewWorkspace from "../../components/Workspace/CreateNewWorkspace";
import ModalDeleteWorkspace from "../../components/Workspace/ModalDeleteWorkspace";

const Workspace = () => {

    const [workspaceList, setWorkspaceList] = useState<IWorkSpace[] | []>([]);
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [selectedWorkspace, setSelectedWorkspace] = useState<IWorkSpace | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const {setError} = useError();
    const handleOpenModal = () => setIsOpen(true);
    const handleCloseModal = () => setIsOpen(false);

    const buttons = [
        {
            icon: "workspace",
            title: "Create New Workspace",
            action: handleOpenModal
        }
    ]

    const loadWorkSpaceList = async () => {
        try {
            const data = await workspaces();
            setWorkspaceList(data);
        } catch (error: any) {
            setError(error.response.data.detail);
        }
    }

    const handleClose = () => setIsOpenDeleteModal(false)

    const handleOpen = (e) => {
        e.stopPropagation()
        setIsOpenDeleteModal(true);
    }

    useEffect(() => {
        loadWorkSpaceList();
    }, []);

    const handleDelete = async (uuid: string) => {
        setIsLoading(true);
        try {
            await deleteWorkspace(uuid);
            await loadWorkSpaceList();
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
        finally {
            handleClose();
            setSelectedWorkspace(null);
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="flex flex-col flex-1 gap-y-1 mt-[2px] h-[calc(100vh-64px)]">
                <div className="flex gap-x-1">
                    <div className="py-5 px-6 bg-white shadow-sm flex items-center gap-x-3 w-[265px]">Workspaces</div>
                    <div className="flex-1">
                        <ActionBar buttons={buttons} topic="workspace" isShow={false}/>
                    </div>
                </div>
                <section className="shadow-sm bg-white p-4 h-[calc(100vh-64px)] overflow-y-auto">
                    <div className="grid 2xl:grid-cols-5 xl:grid-cols-4 grid-cols-4 gap-y-4 gap-x-4">
                        {
                            workspaceList.length > 0 && workspaceList.map((item: IWorkSpace) => {
                                return (<WorkspaceItem key={item.uuid} onSelect={setSelectedWorkspace} workspace={item} onOpenDeleteModal={handleOpen} />)
                            })
                        }
                    </div>
                </section>
            </div>
            <CreateNewWorkspace isOpen={isOpen} onClose={handleCloseModal} loadWorkspaces={loadWorkSpaceList}/>
            <ModalDeleteWorkspace isOpen={isOpenDeleteModal} onClose={handleClose} onDelete={handleDelete} workspace={selectedWorkspace} isLoading={isLoading}/>

        </>
    )
}

export default Workspace;