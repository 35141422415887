import {IUser} from "../interfaces/IUser";
import {array, boolean, object, string} from "yup";

export interface UserValuesType extends IUser {
    workspaces?: { value: string, label: string }[],
    teams?: { value: string, label: string }[],
    user_role: { value: number, label: string }[] | [],
    role_uuids?: string[],
    workspace_uuids?: string[],
    team_uuids?: string[],
    user_name_field: string,
    password_field: string
}

export const userValues: UserValuesType = {
    first_name: "",
    last_name: "",
    user_name_field: "",
    password_field: "",
    phone: "",
    email: "",
    is_active: true,
    team_uuids: [],
    workspace_uuids: [],
    user_role: [],
};


export const userCreateSchema = object({
    first_name: string().max(15, 'First Name cannot be longer than 15 characters.'),
    last_name: string().max(15, 'Last Name cannot be longer than 15 characters.'),
    user_name_field: string().required("The User Name is required.").max(50, "Username cannot be longer than 50 characters."),
    password_field: string().required("The Password is required.")
        .matches(
            /^(?=.*[a-z])/,
            "Password must contain One Lowercase")
        .matches(/(?=.*[A-Z])/, "Password must contain One Uppercase")
        .matches(/(?=.*[0-9])/, "Password must contain One Number")
        .matches(/(?=.*[!@#\$%\^&\*])/, "Password must contain One Special Case Character")
        .min(8, "Min Length is 8 Character")
        .max(15, "Max Length is 15 Character"),
    phone: string().matches(/^\+?[0-9]{1,3}\s?[0-9]{3,14}$/, "Phone Number must be a valid phone number"),
    email: string().email("Email must be a valid email").required("The Email is required."),
    is_active: boolean(),
    user_role: array().of(
        object().shape({value: string(), label: string()})
    ).min(1, "The User Role is required."),
    teams: array().of(
        object().shape({
            value: string(),
            label: string()
        })
    ),
    workspaces: array().of(
        object().shape({
            value: string(),
            label: string()
        })
    )
});

export const userEditSchema = object({
    first_name: string().max(15, 'First Name cannot be longer than 15 characters.'),
    last_name: string().max(15, 'Last Name cannot be longer than 15 characters.'),
    user_name_field: string().required("The User Name is required.").max(50, "Username cannot be longer than 50 characters."),
    phone: string().matches(/^\+?[0-9]{1,3}\s?[0-9]{3,14}$/, "Phone Number must be a valid phone number"),
    email: string().email("Email must be a valid email").required("The Email is required."),
    is_active: boolean(),
    user_role: array().of(
        object().shape({value: string(), label: string()})
    ).min(1, "The User Role is required."),
    teams: array().of(
        object().shape({
            value: string(),
            label: string()
        })
    ),
    workspaces: array().of(
        object().shape({
            value: string(),
            label: string()
        })
    )
});