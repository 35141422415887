import ActionBar from "../../components/ActionBar";
import Table from "../../components/Table";
import React, {useEffect} from "react";
import {renderDate} from "../../shared/utils/helper";
import {Link, useNavigate} from "react-router-dom";
import {useTicket} from "../../shared/hooks/useTicket";
import {TICKET_STATUS} from "../../shared/constant";

const Support = () => {

    const {loadTickets, ticketList} = useTicket()
    const navigate = useNavigate();

    const handleClick = () => {
        console.log()
    }

    const handleCreate = () => {
        navigate(`create`)
    }

    useEffect(() => {
        loadTickets();
    },[])

    const buttons = [
        {
            icon: "plus-circle",
            title: "Create",
            action: handleCreate
        },
        {
            icon: "refresh-circle",
            title: "Refresh",
            action: loadTickets
        }
        // {
        //     icon: "filter",
        //     title: "Filter",
        //     action: handleClick
        // }
    ]

    const renderTicketStatus = (status:string) => {
        switch (status) {
            case TICKET_STATUS.READ_CUSTOMER:
                return 'Read By Customer';
            case TICKET_STATUS.TICKET_CLOSED:
                return 'Closed';
            case TICKET_STATUS.TICKET_DRAFT:
                return 'Draft';
            case TICKET_STATUS.UNREAD_SUPPORT_TEAM:
                return 'Un Read By Support Team';
            case TICKET_STATUS.ANSWERED_SUPPORT_TEAM:
                return 'Answered By Support Team'
            case TICKET_STATUS.READ_SUPPORT_TEAM:
                return 'Read By Support Team'
        }
    }

    const columns = [
        {
            key: 'title',
            title: 'Title',
            render: (_, {ticket}) => (
                <Link to={`view/${ticket.uuid}`}>{ticket.title}</Link>
            )
        },
        {
            key: 'ticket_subject',
            title: 'Subject',
            render: (_, {ticket_subject}) => (
                <>{ticket_subject.title}</>
            )
        },
        {
            key: 'status',
            title: "Status",
            render: (_, {ticket}) => (
                <>{renderTicketStatus(ticket.ticket_status_uuid)}</>
            )
        },
        {
            key: 'last_modified_at',
            title: 'Last Updated',
            render: (_, {ticket}) => (
                <>{renderDate(ticket.last_modified_at)}</>
            )
        }
    ]

    return (
        <div className="flex flex-col flex-1 gap-y-1">
            <section className="h-full flex flex-col">
                <ActionBar goBack={false} pageTitle="Support" topic="ticket" buttons={buttons}/>
                <div className="flex-1 bg-white shadow-sm mt-1 p-6">
                    <Table columns={columns} data={ticketList}/>
                </div>
            </section>
        </div>
    )
}

export default Support;