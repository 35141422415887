import {useEffect, useState} from "react";
import {useError} from "../../../context/ErrorContext";
import {dataComposerConfirm, summary} from "../../../services";

type useSummaryProps = {
    setDataModelStatusList: (value: {
        analyticsDataModelName: string,
        status: boolean,
        data_import_uuid: string | null
    }[]) => void
    dataImportId: string,
    dataModelStatusList: { analyticsDataModelName: string, status: boolean, data_import_uuid: string | null }[] | [],
    selectedDataModel?: {
        data_type_name: string,
        data_type_uuid: string,
        is_required: boolean
    } | null,
    setSelectedDataModel: (value: {
        data_type_name: string,
        data_type_uuid: string,
        is_required: boolean
    } | null) => void,
    dataModelList: {
        data_type_name: string,
        data_type_uuid: string,
        is_required: boolean
    }[] | []
}
export const useSummary = ({
                               setDataModelStatusList,
                               dataImportId,
                               dataModelStatusList,
                               selectedDataModel,
                               setSelectedDataModel,
                               dataModelList
                           }: useSummaryProps) => {

    const [summaryData, setSummaryData] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {setError} = useError();

    const loadSummary = async () => {
        try {
            const data = await summary(dataImportId);
            setSummaryData(data);
            setIsLoading(false);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
            setIsLoading(false);
        }
    }

    const handleConfirm = async () => {
        try {
            const data = await dataComposerConfirm(dataImportId);
            if (data?.is_confirm) {
                const cloneDataModelStatusList = [...dataModelStatusList];
                if (selectedDataModel) {
                    const indexNextDataModel = dataModelList.findIndex(item => item.data_type_name === selectedDataModel.data_type_name);
                    const indexSelectedDataModelStatus = dataModelStatusList.findIndex(item => item.analyticsDataModelName === selectedDataModel?.data_type_name)
                    cloneDataModelStatusList[indexSelectedDataModelStatus].status = true;
                    cloneDataModelStatusList[indexSelectedDataModelStatus].data_import_uuid = dataImportId;
                    setDataModelStatusList(cloneDataModelStatusList);
                    if (indexNextDataModel !== -1 && (indexNextDataModel + 1 <= dataModelList.length)) {
                        setSelectedDataModel(dataModelList[indexNextDataModel + 1]);
                    } else {
                        setSelectedDataModel(null);
                    }
                }
            }
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    useEffect(() => {
        setSummaryData(null);
        setIsLoading(true);
    }, [selectedDataModel, dataImportId]);

    return {isLoading, loadSummary, handleConfirm, summaryData}

}