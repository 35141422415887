import React from "react";
import {createPortal} from "react-dom";

type modalProps = {
    isOpen: boolean,
    onClose: () => void,
    children?: React.ReactNode,
    isSubmitBtn?: boolean,
    onSubmit?: () => void,
    isCancelBtn?: boolean,
    classNameSize?: string,
    closeButton?: boolean
}
const Modal = ({isOpen, onClose, children, isSubmitBtn, onSubmit, closeButton = true, isCancelBtn = true, classNameSize = "max-w-5xl"}: modalProps) => {

    return createPortal(
        <div className={`modal ${isOpen ? "modal-open" : ""}`}>
            <div className={`modal-box w-11/12 rounded shadow-sm bg-white ${classNameSize}`}>
                <div className="border border-lightGray-100 rounded-xl p-6 relative">
                    {
                        closeButton && (<i className="icon icon-24 icon-close-circle absolute cursor-pointer right-3 top-3" onClick={onClose} />)
                    }
                    {children}
                    <div className={`modal-action ${!isSubmitBtn && !isCancelBtn ? "mt-0" : ""}`}>
                        {
                            isCancelBtn ? (
                                <button className="btn btn-outline btn-primary px-12 rounded font-medium"
                                        onClick={onClose}>Cancel
                                </button>
                            ) : null
                        }
                        {
                            isSubmitBtn ? (
                                <button className="btn btn-primary px-12 rounded font-medium"
                                        onClick={onSubmit}>Submit</button>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        </div>
        , document.body)

}

export default Modal;