import { useEffect } from 'react';

const useFrameBusting = () => {
    useEffect(() => {
        if (window.top !== window.self) {
            // @ts-ignore
            window.top.location = window.self.location;
        }
    }, []);
};

export default useFrameBusting;