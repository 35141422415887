import React from "react";

type LearnTagProps = {
    tag: string
}
const LearnTag = ({tag}: LearnTagProps) => {
    return (
        <span className="cursor-pointer bg-lightGray-100 px-1">{tag}</span>
    )
}

export default LearnTag;