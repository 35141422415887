import React from "react";
import {IChannel} from "../../shared/interfaces/IChannel";

type ChannelItemProps = {
    onSelectChannel: (channel: IChannel) => void,
    channel: IChannel,
    isSelectedChannel: boolean,
    handleDeleteChannel: (uuid: string) => void,
    onEditChannel: (uuid: string) => void
}
const ChannelItem = ({onSelectChannel, channel, isSelectedChannel, handleDeleteChannel, onEditChannel}: ChannelItemProps) => {

    const onDelete = async (e) => {
        e.stopPropagation();
        handleDeleteChannel(channel.uuid)
    }

    const onSelect = (e) => {
        onSelectChannel(channel)
    }

    const onEdit = (e) => {
        e.stopPropagation();
        onEditChannel(channel.uuid)
    }

    return (
        <div onClick={onSelect}
            className={`p-3 pr-2 flex justify-between text-sm rounded cursor-pointer items-center gap-x-3 ${isSelectedChannel ? "bg-blue-100 text-primary" : ""}`}>
            <span className="truncate">{channel.title}</span>
            <div className="flex gap-x-1">
                <i className="icon icon-24 icon-delete" onClick={onDelete}/>
                <i className="icon icon-24 icon-edit-circle" onClick={onEdit}/>
            </div>
        </div>
    )
}

export default ChannelItem;