import ActionBar from "../../components/ActionBar";
import Form from "./Form";
import React from "react";
import {useTicket} from "../../shared/hooks/useTicket";
import {useLocation, useNavigate} from "react-router-dom";
import {FormikState, FormikValues} from "formik";
import {SupportValuesProps} from "../../shared/formValidator/supportFormValidator";

const CreateTicket = () => {

    const {handleSubmitTicket} = useTicket();
    const navigate = useNavigate();
    const {pathname} = useLocation();

    const onClose = () => {
        navigate(-1);
    }

    const currentUrl = pathname.split("/");
    const redirectUrl = currentUrl.slice(1, currentUrl.length - 1).join("/");

    const onSubmit = async (values: FormikValues, resetForm: (nextState?: Partial<FormikState<SupportValuesProps>>) => void, related_uuid: string) => {
        await handleSubmitTicket(values, resetForm, related_uuid);
        navigate(`/${redirectUrl}`)
    }

    return (
        <div className="flex flex-col gap-x-1 gap-y-1 w-full">
            <div className="flex flex-col flex-1 gap-y-1">
                <ActionBar goBack={false} pageTitle="Create Ticket" topic="ticket"/>
            </div>
            <div className="flex h-[calc(100vh-133px)] gap-x-1">
                <section className="h-full flex flex-col p-6 bg-white shadow-sm flex-1 overflow-y-auto">
                    <div className="border border-light-100 rounded-lg p-6">
                        <Form onSubmit={onSubmit} onClose={onClose}/>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default CreateTicket;