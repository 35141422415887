import React from "react";
import TableRowCell from "./TableRowCell";

type TableRowProps = {
    columns: { key: string, title: string, render?: (_: any, {}: any) => React.ReactNode }[],
    data: { [key: string]: string | number | boolean }[],
}
const TableRow = ({columns, data}: TableRowProps) => {
    return (
        <>
            {
                data.map((item) => {
                    return (
                        <tr key={`table-row-${item.id}`}>
                            {
                                columns.map((column, index) => {
                                    return (
                                        <TableRowCell item={item} column={column}
                                                      key={`table-row-cell-${index}-${item.id}`}/>
                                    )
                                })
                            }
                        </tr>
                    )
                })
            }
        </>
    )

}

export default TableRow;