import React from "react";

type DescriptionPageProps = {
    children?: React.ReactNode
}
const DescriptionPage = ({children}: DescriptionPageProps) => {
    return (
        <div className="w-[265px] h-[calc(100vh-133px)] flex flex-col overflow-y-auto">
            <div className="py-5 px-4 bg-white shadow-sm flex-1">
                {
                    children
                }
            </div>
        </div>
    )
}

export default DescriptionPage;