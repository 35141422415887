import Input from "../../../components/Input/input";
import MultiSelect from "../../../components/MultiSelect";
import React, {useEffect, useRef, useState} from "react";
import {useFormik} from "formik";
import {
    userCreateSchema,
    userEditSchema,
    userValues,
    UserValuesType
} from "../../../shared/formValidator/userFormValidator";
import {roles, teams, uploadAvatar, workspacesByOrg} from "../../../services";
import {orgUserRoleToList, teamsToList, workSpacesToList} from "../../../shared/utils/toList";
import {useError} from "../../../context/ErrorContext";
import {useNavigate} from "react-router-dom";
import UploadAvatar from "../../../components/Profile/UploadAvatar";
import {useAuth} from "../../../context/AuthContext";

type FormProps = {
    onSubmit: (values: UserValuesType) => void,
    data?: UserValuesType | null,
    handleChangeAvatar?: (files: File[]) => void
}

const Form = ({onSubmit, data, handleChangeAvatar}: FormProps) => {

    const [teamList, setTeamList] = useState<{ value: string, label: string }[] | []>([]);
    const [workspaceList, setWorkspaceList] = useState<{ value: string, label: string }[] | []>([]);
    const [organizationUserRole, setOrganizationUserRole] = useState<{ value: string, label: string }[] | []>([]);

    const inputRef = useRef<HTMLInputElement>(null);

    const {setError} = useError();
    const navigate = useNavigate();
    const {user} = useAuth();

    const formik = useFormik({
        initialValues: data ? data : userValues,
        onSubmit: onSubmit,
        validationSchema: data ? userEditSchema : userCreateSchema,
        validateOnMount: false,
        validateOnBlur: true,
        enableReinitialize: true,
        validateOnChange: false
    });

    useEffect(() => {
        setTimeout(() => {
            inputRef.current?.focus()
        }, 200)
    }, [])

    useEffect(() => {
        if (user && user.organization_uuid) {
            loadTeamList();
            loadWorkspaceList(user?.organization_uuid);
            loadUserRole();
        }
    }, [user]);

    const loadTeamList = async () => {
        try {
            const data = await teams();
            setTeamList(teamsToList(data));
        } catch (error: any) {
            setError({message: error?.response?.data?.detail, type: 'error'});
        }
    }

    const loadWorkspaceList = async (organizationId: string) => {
        try {
            const data = await workspacesByOrg(organizationId);
            setWorkspaceList(workSpacesToList(data));
        } catch (error: any) {
            setError({message: error?.response?.data?.detail, type: 'error'});
        }
    }

    const loadUserRole = async () => {
        try {
            const data = await roles();
            setOrganizationUserRole(orgUserRoleToList(data));
        } catch (error: any) {
            setError({message: error?.response?.data?.detail, type: 'error'});
        }
    }

    useEffect(() => {
        if (data && data.workspace_uuids && workspaceList.length > 0) {
            let res: { value: string, label: string }[] | [] = []
            if (data?.workspace_uuids?.length > 0) {
                res = data.workspace_uuids.map(workspace => workspaceList.filter(item => item?.value === workspace)).flat(1);
            }
            formik.setFieldValue("workspaces", res);
        } else if (data && data.workspace_uuids && workspaceList.length === 0) {
            formik.setFieldValue("workspaces", []);
        }

        if (data && data.user_name) {
            formik.setFieldValue("user_name_field", data?.user_name);
        }

        if (data && data.team_uuids && teamList.length > 0) {
            let res: { value: string, label: string }[] = []
            if (data?.team_uuids?.length > 0) {
                res = data.team_uuids.map(team => teamList.filter(item => item?.value === team)).flat(1);
            }
            formik.setFieldValue("teams", res);
        } else if (data && data.teams && teamList.length === 0) {
            formik.setFieldValue("teams", []);
        }
    }, [data, workspaceList, teamList]);

    useEffect(() => {
        if (data && organizationUserRole.length > 0) {
            const res = data.role_uuids?.map(role => organizationUserRole.filter(item => item?.value === role)).flat(1);

            if (res) {
                formik.setFieldValue("user_role", res);
            } else {
                formik.setFieldValue("user_role", null);
            }
        }
    }, [data, organizationUserRole]);

    const onCancel = () => {
        navigate(`/setting/users`);
    }

    const userProfile = () => {
        return data?.first_name && data.last_name ? data.first_name.charAt(0).toUpperCase() + data.last_name.charAt(0).toUpperCase() : "";
    }

    return (
        <div>
            {
                handleChangeAvatar && <UploadAvatar avatar={data?.avatar_url ? data.avatar_url : null} onChange={handleChangeAvatar}
                                                    textAvatar={userProfile()}/>
            }
            <form onSubmit={formik.handleSubmit}>
                <Input
                    label="First Name"
                    name="first_name"
                    type="text"
                    formik={formik}
                    ref={inputRef}
                />
                <Input
                    label="Last Name"
                    name="last_name"
                    type="text"
                    formik={formik}
                />
                <Input
                    label="User Name"
                    name="user_name_field"
                    type="text"
                    formik={formik}
                    required={true}
                />
                {
                    !data ? (<Input
                        label="Password"
                        name="password_field"
                        type="password"
                        formik={formik}
                        required={!data}
                    />) : null
                }
                <Input
                    label="Phone"
                    name="phone"
                    type="tel"
                    formik={formik}
                />
                <Input
                    label="Email"
                    name="email"
                    type="email"
                    formik={formik}
                    required={true}
                />
                <MultiSelect
                    options={organizationUserRole}
                    values={formik.values.user_role}
                    onChange={value => formik.setFieldValue("user_role", value)}
                    placeholder="Select An Item ..."
                    classNameWrapper="!w-full"
                    label="User Role"
                    required={true}
                    error={formik?.errors["user_role"] && formik?.touched["user_role"] ? formik?.errors["user_role"] : ""}
                />
                <MultiSelect
                    options={teamList}
                    values={formik.values.teams ? formik.values.teams : []}
                    onChange={values => formik.setFieldValue("teams", values)}
                    placeholder="Select an item ..."
                    label="Teams"
                    classNameWrapper="!w-full"
                    error={formik?.errors["teams"] && formik?.touched["teams"] ? formik?.errors["teams"] : ""}
                />
                <MultiSelect
                    options={workspaceList}
                    values={formik.values.workspaces ? formik.values.workspaces : []}
                    onChange={values => formik.setFieldValue("workspaces", values)}
                    placeholder="Select an item ..."
                    label="Workspaces"
                    classNameWrapper="!w-full"
                    error={formik?.errors["workspaces"] && formik?.touched["workspaces"] ? formik?.errors["workspaces"] : ""}
                />
                {
                    data ? (<div className="flex gap-x-2">
                        <label className="label cursor-pointer p-0">
                            <input type="checkbox" checked={formik.values.is_active} onChange={formik.handleChange}
                                   name="is_active" className="checkbox checkbox-primary border-dark-400 rounded-sm"/>
                        </label>
                        <span>Active</span>
                    </div>) : null
                }
                <div className="mt-8 flex gap-x-4">
                    <button className="btn btn-outline btn-primary px-4" type="button" onClick={onCancel}>Cancel
                    </button>
                    <button className="btn btn-primary px-12" type="submit">{data ? "Save" : "Create"}</button>
                </div>
            </form>
        </div>
    )
}

export default Form;