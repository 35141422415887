import {ISupport} from "../interfaces/ISupport";
import {number, object, string} from "yup";


export interface SupportValuesProps extends ISupport {
    subject: { value: string, label: string } | null,
    priority: { value: string, label: string } | null
}
export const supportValue: SupportValuesProps  = {
    title: "",
    subject: null,
    priority: null,
    body: "",
}

export const supportSchema = object({
    title: string().required("The Title is required.").max(40, "Title cannot be longer than 40 characters."),
    subject: object().shape({
        value: string(),
        label: string()
    }).required("The Subject is required."),
    priority: object().shape({
        value: string(),
        label: string()
    }).required("The Priority is required."),
    body: string().required("The Body is required.").max(400, "Message cannot be longer than 400 characters."),
})