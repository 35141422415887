import {object, string} from "yup";
import {IWorkSpace} from "../interfaces/IWorkSpace";

export const workspaceValues : Omit<IWorkSpace, "id" | "uuid"> = {
    title: "",
    description: "",
};

export const workspaceSchema = object({
    title: string().required("The Title is required.").max(85, "Max Length is 85 Character"),
    description: string().max(85, "Max Length is 85 Character"),
});