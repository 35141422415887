import {array, boolean, object, string} from "yup";
import {ITeam} from "../interfaces/ITeam";

export interface TeamValuesType extends ITeam {
    member_uuids: { value: string, label: string }[],
    workspaces: { value: string, label: string }[],
    workspace_uuids?: string[]
}
export const teamValues : TeamValuesType = {
    title: "",
    description: "",
    is_active: false,
    member_uuids: [],
    workspaces: []
};


export const teamSchema = object({
    title: string().required("The title is required.").max(15, 'Title cannot be longer than 15 characters.'),
    description: string(),
    is_active: boolean(),
    member_ids: array().of(
        object().shape({
            value: string(),
            label: string()
        })
    ),
    workspace_ids: array().of(
        object().shape({
            value: string(),
            label: string()
        })
    )
})