import React, {forwardRef} from "react";
import {FormikProps} from "formik";

type InputProps = {
    type?: string,
    className?: string,
    label?: string,
    value?: string,
    onChange?: (e) => void,
    name?: string,
    required?: boolean,
    formik?: FormikProps<any>
}

const Input = forwardRef<HTMLInputElement, InputProps>(({
                   type = "text",
                   className = "",
                   label = "",
                   value,
                   onChange,
                   name = "",
                   required = false,
                   formik
               }, ref) => {

    return (
        <div className="form-group">
            <label className={`form-label ${required ? "required" : ""}`}>{label}</label>
            <input type={type} className={`form-input ${className}`} onBlur={formik?.handleBlur} ref={ref}
                   value={value || formik?.values[name]} autoComplete={type === "password" ? "new-password" : "off"} onChange={onChange || formik?.handleChange} name={name}/>
            <span className="absolute text-xs text-red top-full left-0 whitespace-nowrap">
            {
                formik?.errors[name] && formik?.touched[name] ? <>{formik?.errors[name]}</> : null
            }
            </span>
        </div>
    )
})

export default Input;