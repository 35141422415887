import React, {useEffect, useState} from 'react';
import ActionBar from "../../../components/ActionBar";
import SettingSidebar from "../../../components/SettingSidebar";
import {useNavigate} from "react-router-dom";
import {organizationMe} from "../../../services";
import {useError} from "../../../context/ErrorContext";
import {IOrganization} from "../../../shared/interfaces/IOrganization";

const Organization = () => {

    const [organization, setOrganization] = useState<IOrganization | null>();

    const navigate = useNavigate();
    const {setError} = useError();

    const handleEdit = () => {
        navigate('/setting/organization/edit')
    }

    const loadOrganization = async () => {
        try {
            const data = await organizationMe();
            setOrganization(data);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }
    
    const buttons = [
        {
            icon: "copy",
            title: "Edit",
            action: handleEdit
        },
        {
            icon: "refresh-circle",
            title: "Refresh",
            action: loadOrganization
        }
    ]

    useEffect(() => {
        loadOrganization();
    }, []);

    return (
        <>
            <div className="flex gap-x-1">
                <div className="flex h-[calc(100vh-64px)]">
                    <SettingSidebar/>
                </div>
            </div>
            <div className="flex flex-col flex-1 gap-y-1">
                <section className="h-full flex flex-col">
                    <ActionBar goBack={false} pageTitle="Organization" buttons={buttons} topic="organization" isShow={false}/>
                    <div className="flex-1 bg-white shadow-sm mt-1 p-6">
                        <div className="border border-light-100 rounded-lg p-6">
                            {
                             organization ? (
                                 <>
                                     <div>
                                         <h2 className="text-center text-2xl">{organization?.title}</h2>
                                     </div>
                                     <div className="flex flex-wrap justify-evenly mt-8">
                                         <div className="flex flex-col">
                                             <div className="border border-dark-300 rounded-full w-40 h-40 flex items-center justify-center">
                                                 {organization?.users}
                                             </div>
                                             <span className="mx-auto mt-3">Users</span>
                                         </div>
                                         <div className="flex flex-col">
                                             <div className="border border-dark-300 rounded-full w-40 h-40 flex items-center justify-center">
                                                 {organization?.teams}
                                             </div>
                                             <span className="mx-auto mt-3">Teams</span>
                                         </div>
                                         <div className="flex flex-col">
                                             <div className="border border-dark-300 rounded-full w-40 h-40 flex items-center justify-center">
                                                 {organization?.workspaces}
                                             </div>
                                             <span className="mx-auto mt-3">Workspaces</span>
                                         </div>
                                     </div>
                                 </>
                             ) : null
                            }
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Organization;