import React from "react";
import toast from "react-hot-toast";


type AlertProps = {
    message: string,
    t: any
}

const Alert = ({message, t}: AlertProps) => {
    return (
        <div className="flex items-center">
            <span>{message}</span>
            <div className="ml-4 mr-[-8px] pl-2 border-l border-lightGray-100">
                <button onClick={() => toast.dismiss(t.id)}>
                    <i className="icon icon-24 icon-close-circle"/>
                </button>
            </div>
        </div>
    )
}


export default Alert;