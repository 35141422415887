import React, {useState} from "react";
import ModelTitle from "./ModelTitle";
import data from "../../mockData/data.json";
import CollapseMenu from "./CollapseMenu";

const SecondSidebar = () => {

    const {SecondSidebarMenuItems} = data;

    const [isExpanded, setIsExpanded] = useState(true);

    const handleClick = () => {
        setIsExpanded(prevState => !prevState)
    }

    return (
        <div className={`h-[calc(100vh-64px)] flex flex-col relative transition-all ${isExpanded ? "max-w-[265px] w-[265px]" : "max-w-[72px] is-collapsed"}`}>
            <ModelTitle/>
            <nav className="py-5 px-3 bg-white shadow-sm mt-1 flex-1 overflow-y-auto">
                <ul className="flex list-none flex-col second-slider-links">
                    {
                        SecondSidebarMenuItems.map(item => {
                            return (
                                <CollapseMenu {...item} key={item.id} isExpanded={isExpanded}/>
                            )
                        })
                    }
                </ul>
                <button onClick={handleClick} className="shadow-sm absolute inset-y-1/2 right-[-12px] bg-white h-fit rounded-full p-1"><i className={`icon icon-24 icon-expanded transition-all ${isExpanded ? "rotate-180" : ""}`} /></button>
            </nav>
        </div>
    )
}

export default SecondSidebar;