import React, {useState} from "react";
import {renderDate} from "../../shared/utils/helper";
import {Rating, ThinRoundedStar} from "@smastrom/react-rating";
import {useTicket} from "../../shared/hooks/useTicket";
import {ITicket} from "../../shared/interfaces/ITicket";
import InitialProfileImage from "../Profile/InitialProfileImage";

type TicketItemProps = {
    ticket: ITicket,
    isLastAnswer?: boolean,
    onClickReply?: () => void
}

const myStyles = {
    itemShapes: ThinRoundedStar,
    itemStrokeWidth: 2,
    activeFillColor: '#0A22FF',
    activeStrokeColor: '#0A22FF',
    inactiveFillColor: '#ffffff',
    inactiveStrokeColor: '#0A22FF'
}

const SupportItem = ({ticket, isLastAnswer = false, onClickReply}: TicketItemProps) => {

    const {handleUpdateRate} = useTicket();
    const [rating, setRating] = useState<number>(ticket.ticket_rating)

    const onClickRating = async (rate: number) => {
        await handleUpdateRate(ticket.uuid, rate);
        setRating(rate);
    }

    const userProfile = () => {
        return ticket.user_info?.first_name && ticket.user_info.last_name ? ticket.user_info.first_name.charAt(0).toUpperCase() + ticket.user_info.last_name.charAt(0).toUpperCase() : null;
    }

    return (
        <div className={`flex items-start gap-x-3 w-10/12 mt-3 ${ticket.ticket_type === "question" ? "mr-auto" : "ml-auto flex-row-reverse"}`}>
            {
                ticket.user_info?.avatar_url ? <img
                        src={ticket?.user_info.avatar_url}
                        className="w-5 h-5 rounded-full object-cover"/> :
                    <InitialProfileImage userProfile={userProfile()}/>
            }
            <div
                className={`flex-1 shadow-sm`}>
                <div className="px-4 py-2 border-b border-lightGray-200 flex justify-between">
                    <span>{ticket.title}</span>
                    <span>{renderDate(ticket.created_at)}</span>
                </div>
                <div className="p-4">
                    {ticket.text}
                </div>
                {
                    ticket.ticket_type === "answer" ? (
                        <div className="px-4 pb-2 flex justify-end">
                            <Rating
                                style={{maxWidth: 150}}
                                value={rating}
                                onChange={onClickRating}
                                itemStyles={myStyles}
                            />
                        </div>
                    ) : null
                }
                {
                    isLastAnswer ? (
                        <div className="px-4 py-2 border-t border-lightGray-200">
                            <button onClick={onClickReply}>Reply</button>
                        </div>
                    ) : null
                }
            </div>
        </div>

    )
}

export default SupportItem;