import React, {useEffect, useRef, useState} from "react";
import Input from "../Input/input";
import Modal from "../Modal";
import {useFormik} from "formik";
import {workspaceSchema, workspaceValues} from "../../shared/formValidator/workspaceFormValidator";
import {createWorkspace} from "../../services";
import {useError} from "../../context/ErrorContext";
import {useAuth} from "../../context/AuthContext";
import Loading from "../Loading";

type CreateNewWorkspaceProps = {
    isOpen: boolean,
    onClose: () => void,
    loadWorkspaces: () => void,
}

const CreateNewWorkspace = ({isOpen, onClose, loadWorkspaces}: CreateNewWorkspaceProps) => {

    const {setError} = useError();
    const {user} = useAuth();
    const inputRef = useRef<HTMLInputElement>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleCreateWorkspace = async (values) => {
        setIsLoading(true)
        const formValues  = {
            title: values.title.trim(),
            description: values.description.trim(),
        }
        try {
            await createWorkspace({...formValues, organization_uuid: user?.organization_uuid});
            loadWorkspaces();
            onClose();
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        } finally {
            setIsLoading(false)
        }
    }

    const formik = useFormik({
        initialValues: workspaceValues,
        onSubmit: handleCreateWorkspace,
        validationSchema: workspaceSchema,
        validateOnMount: false,
        validateOnBlur: true,
        validateOnChange: false
    })

    useEffect(() => {
        if (!isOpen) formik.resetForm();
        if (inputRef.current === null) return;
        if (isOpen) setTimeout(() => {inputRef.current?.focus()},200)
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={onClose} isSubmitBtn={false} isCancelBtn={false} closeButton={false}
               classNameSize="max-w-lg">
            <div>
                <form onSubmit={formik.handleSubmit}>
                    <Input type="text" name="title" label="Name: " formik={formik} ref={inputRef}/>
                    {/*<Input type="text" name="description" label="Description: " formik={formik}/>*/}
                    <div className="form-group">
                        <label className={`form-label`}>Description: </label>
                        <textarea className={`form-input`} onBlur={formik?.handleBlur}
                               value={formik?.values["description"]} onChange={formik?.handleChange}
                               name={"description"}/>
                        <span className="absolute text-xs text-red top-full left-0 whitespace-nowrap">
                            {
                                formik?.errors["description"] && formik?.touched["description"] ? <>{formik?.errors["description"]}</> : null
                            }
                        </span>
                    </div>
                    <div className="mt-8 flex gap-x-4 justify-end">
                        <button className="btn btn-primary btn-outline px-6" type="button" onClick={onClose}>Cancel
                        </button>
                        <button className={`btn btn-primary px-12 min-w-[151px] ${isLoading ? "pointer-events-none" : ""}`} type="submit">{isLoading ? <Loading /> : "Create"}</button>
                    </div>
                </form>
            </div>
        </Modal>
    )
}

export default CreateNewWorkspace;