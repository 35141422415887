import React, {useEffect} from "react";
import {Route, Routes, Outlet, useParams} from "react-router-dom";
import {workspaceLayoutRoutes} from "../routes";
import MainSidebar from "../components/MainSidebar/MainSidebar";
import {useError} from "../context/ErrorContext";
import {useWorkSpace} from "../context/WorkSpaceContext";
import {workspace} from "../services";
import {isNullOrUndefinedOrEmpty} from "../shared/utils/helper";
import NotFound from "../pages/NotFound";
import {useRouteGenerator} from "../shared/hooks/useRouteGenerator";

const WorkspaceLayout = () => {

    const {workspaceUUId} = useParams();
    const {setError} = useError();
    const {workSpace, setWorkSpace} = useWorkSpace();
    const {routeGenerator} = useRouteGenerator();

    const loadWorkspace = async (uuid: string) => {
        try {
            const data = await workspace(uuid);
            setWorkSpace(data);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    useEffect(() => {
        if (isNullOrUndefinedOrEmpty(workSpace) && workspaceUUId) {
            loadWorkspace(workspaceUUId);
        }
    }, []);

    return (
        <div className="flex gap-x-1 mt-[2px]">
            <div className="flex h-[calc(100vh-64px)]">
                <MainSidebar/>
            </div>
            {
                <Routes>
                    {
                        workspaceLayoutRoutes.map(route => {
                            return <Route key={route.id} path={route.path} element={routeGenerator(route)}/>
                        })
                    }
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            }
            <Outlet/>
        </div>
    )
}

export default WorkspaceLayout;