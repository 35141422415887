import React, {createContext, ReactNode, useContext, useState} from "react";
import {IModel} from "../shared/interfaces/IModel";

type ModelProviderProps = {
    children?: ReactNode
}

export const ModelContext = createContext<{ model: IModel | null, setModel: (model: IModel) => void }>({model: null, setModel: () => {}})
export const ModelProvider = ({children}: ModelProviderProps) => {

    const [model, setModel] = useState<IModel | null>(null);

    return (
        <ModelContext.Provider value={{model, setModel}}>
            {children}
        </ModelContext.Provider>
    )
}

export const useModel = () => useContext(ModelContext);
