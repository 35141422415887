import React, {useEffect, useState} from 'react';
import ActionBar from "../../components/ActionBar";
import Modal from "../../components/Modal";
import ModelExplorerType from "../../components/CreateModel/ModelExplorerType";
import data from '../../mockData/data.json';
import Tab from "../../components/Tab";
import {deleteModel, models} from "../../services";
import TreeDiagram from "../../components/TreeDiagram";
import {useNavigate, useParams} from "react-router-dom";
import {useError} from "../../context/ErrorContext";
import {IModel} from "../../shared/interfaces/IModel";
import {SUCCESS} from "../../shared/constant";
import {useWorkSpace} from "../../context/WorkSpaceContext";
import ModalDeleteModel from "../../components/Modal/ModalDeleteModel";


export interface IModelNodes extends IModel {
    children?: any
}

let modelTimeOut: NodeJS.Timer;

const Model = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const {ModelExplorerTypes} = data;
    const [selectedTab, setSelectedTab] = useState<number>(1);
    const [modelNodes, setModelNodes] = useState<IModelNodes[]>([]);
    const [isShowBuildModel, setIsShowBuildModel] = useState<boolean>(false);
    const [selectedModel, setSelectedModel] = useState<IModel | null>(null);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isHorizontal, setIsHorizontal] = useState<boolean>(false);

    const {setError} = useError();
    const {workspaceUUId} = useParams();
    const navigate = useNavigate();
    const {workSpace} = useWorkSpace();

    const handleOpenModal = () => {
        // setIsOpen(true);
        navigate(`/workspace/${workSpace?.uuid}/model-composer`)
    }

    const handleCloseModal = () => setIsOpen(false)

    const handleOpenDeleteModal = () => setIsOpenModal(true);
    const handleCloseDeleteModal = () => setIsOpenModal(false);

    const handleTabClick = (key) => {
        setSelectedTab(key)
    }

    useEffect(() => {
        if (workspaceUUId) {
            loadModels()
        }

        return () => {
            clearTimeout(modelTimeOut);
        }
    }, [])

    const loadModels = async () => {
        try {
            const data: IModel[] = await models(workspaceUUId);
            setModelNodes(data.filter(item => item.model_status === SUCCESS));
            if (data?.length === 0) {
                setIsShowBuildModel(true);
            }
            if (data.some(item => item.model_status === "running")) {
                modelTimeOut = setTimeout(() => {
                    loadModels()
                }, 10000)
            }
        } catch (error: any) {
            setError({message: error?.response?.data?.detail, type: 'error'});
        }
    }

    const handleDeleteModel = async (uuid: string) => {
        setIsLoading(true)
        try {
            await deleteModel(uuid);
            await loadModels();
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        } finally {
            handleCloseDeleteModal()
            setSelectedModel(null);
            setIsLoading(false)
        }
    }

    const resultData = (parent: number | null = null) => {
        return modelNodes.filter(item => item?.base_model_id === parent).map(item => {
            item.children = resultData(item?.id)
            return item;
        })
    }

    const buttons = [
        {
            icon: "change-orientation",
            title: "Change View",
            action: () => setIsHorizontal(prevState => !prevState),
        }
    ]
    const tabText = "To get started, click the 'Create Model' button and \n follow the prompts to upload your data. Lagrange \n will analyze your data and create a base model \n tailored to your company's specific operations."

    return (
        <>
            <div className="flex flex-col flex-1 gap-y-1 h-[calc(100vh-64px)] w-[calc(100%-77px)]">
                <ActionBar pageTitle="Model Explorer" topic="model" buttons={buttons}/>
                <div
                    className="shadow-sm bg-white flex-1 flex flex-col py-3 px-6 h-[calc(100vh-172px)] overflow-y-auto">
                    {/*<Tab*/}
                    {/*    activeKey={selectedTab}*/}
                    {/*    onClick={handleTabClick}*/}
                    {/*>*/}
                    {/*    <Tab.Pane key={1} label="Genealogy">*/}
                    {/*        */}
                    {/*    </Tab.Pane>*/}
                    {/*    <Tab.Pane key={2} label="Comparison">*/}
                    {/*        <div>Empty Tab</div>*/}
                    {/*    </Tab.Pane>*/}
                    {/*</Tab>*/}
                    <div className="flex items-center flex-col justify-center h-full overflow-auto w-full">
                        {
                            isShowBuildModel ? (
                                    <>
                                        <img src="/images/network-image.png" alt="network-image"/>
                                        <p className="mt-10 whitespace-pre-line text-sm text-dark-100 text-center">
                                            {tabText}
                                        </p>
                                        <div className="flex gap-x-3 mt-6">
                                            {/*<button className="btn btn-primary btn-outline py-4 px-7">Learn</button>*/}
                                            <button className="btn btn-primary py-4 px-12"
                                                    onClick={handleOpenModal}>Create
                                                Model
                                            </button>
                                        </div>
                                    </>
                                ) :
                                modelNodes.length > 0 ? (
                                    <TreeDiagram dataDiagram={resultData()} isHorizontal={isHorizontal} onSelect={setSelectedModel}
                                                 onOpenModal={handleOpenDeleteModal}/>
                                ) : null
                        }
                    </div>
                </div>
            </div>

            <Modal isOpen={isOpen} onClose={handleCloseModal}>
                <h4 className="bg-light-200 px-6 py-3 text-lg text-dark-300">Create the base model</h4>
                <p className="text-dark-200 indent-6 mt-4 py-1">Select the data import mode</p>
                {
                    ModelExplorerTypes.map(item => {
                        return <ModelExplorerType {...item} key={item.id}/>
                    })
                }
            </Modal>

            <ModalDeleteModel isOpen={isOpenModal} onDelete={handleDeleteModel} model={selectedModel}
                              onClose={handleCloseDeleteModal} isLoading={isLoading}/>
        </>
    )
}

export default Model;