import React from "react";
import ActionBar from "../../components/ActionBar";
import Table from "../../components/Table";
import {useNavigate} from "react-router-dom";
import {useExecutionProgress} from "../../shared/hooks/useExecutionProgress";
import {renderDate} from "../../shared/utils/helper";

const ExecutionProgress = () => {

    const navigate = useNavigate();
    const {executionProgressList, selectedItem, handleSelectedItems, loadExecutionProgressList} = useExecutionProgress();

    const handleDetail = () => {
        if (selectedItem) navigate(`detail/${selectedItem}`);
    }

    const handleClick = (uuid: string) => {
        navigate(`detail/${uuid}`);
    }

    const buttons = [
        {
            icon: "details",
            title: "Detail",
            action: handleDetail,
            className: selectedItem !== null ? "" : "disabled"
        },
        // {
        //     icon: "filter",
        //     title: "Filter",
        //     action: () => window.location.reload(),
        // },
        {
            icon: "refresh-circle",
            title: "Refresh",
            action: loadExecutionProgressList
        }
    ]

    const columns = [
        {
            key: 'select',
            title: 'select',
            render: (_, {uuid}) => (
                <label className="label cursor-pointer p-0">
                    <input type="checkbox" onChange={(e) => handleSelectedItems(e, uuid)}
                           checked={selectedItem === uuid}
                           className="checkbox checkbox-primary border-dark-400 rounded-sm"/>
                </label>
            )
        },
        {
            key: 'name',
            title: 'Name',
            render: (_, {uuid, name}) => (
                <span className="cursor-pointer" onClick={() => handleClick(uuid)}>{name}</span>
            )
        },
        {
            key: 'start_date',
            title: 'Start Date',
            render: (_, {start_date}) => (
                <>{renderDate(start_date)}</>
            )
        },
        {
            key: 'status',
            title: 'Status',
            render: (_, {status}) => (
                <span className="capitalize">{status ? status : "In Progress"}</span>
            )
        }
    ]

    return (
        <div className="flex flex-col flex-1 gap-y-1 h-[calc(100vh-64px)]">
            <ActionBar pageTitle="Execution Progress" buttons={buttons} topic="execution_item"/>
            <div className="flex-1 h-[calc(100vh-132px)] flex flex-col gap-y-1 py-3 px-6 shadow bg-white overflow-y-auto">
                <Table columns={columns} data={executionProgressList} />
            </div>
        </div>
    )
}

export default ExecutionProgress;