import React from "react";
import Modal from "../Modal";
import Input from "../Input/input";
import MultiSelect from "../MultiSelect";
import {FormikProps} from "formik";
type CreateChannelModalProps = {
    isOpen: boolean,
    onClose: () => void,
    shareItems: { label: string, value: string, type?: string }[] | [],
    formik: FormikProps<any>
}

const CreateChannelModal = ({isOpen, onClose, shareItems, formik}: CreateChannelModalProps) => {

    return (
        <Modal isOpen={isOpen} onClose={onClose} isCancelBtn={false} closeButton={false}>
            <form onSubmit={formik.handleSubmit}>
                <Input type="text" name="title" formik={formik} label="Channel Name:"/>

                <MultiSelect
                    options={shareItems}
                    values={formik.values["member_uuids"]}
                    onChange={values => formik.setFieldValue("member_uuids", values)}
                    placeholder="seleted Items"
                    label="Channel users & team"
                    error={formik?.errors["member_uuids"] && formik?.touched["member_uuids"] ? formik.errors["member_uuids"].toString() : ""}
                />
                <div className="flex justify-end gap-x-4">
                    <button className="btn btn-primary btn-outline" type="button" onClick={onClose}>Cancel</button>
                    <button className={`btn btn-primary ${!formik.isValid ? "btn-disabled" : ""}`} type="submit">Submit</button>
                </div>
            </form>
        </Modal>
    )
}

export default CreateChannelModal;