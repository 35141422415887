import React, {createContext, useContext, useState} from "react";

type supportProps = {
    isOpen: boolean,
    topic: string
}

type SupportProviderProps = {
    children: React.ReactNode
}

const SupportContext = createContext<{support: supportProps, setSupport: (value: supportProps) => void}>({
    support: {isOpen: false, topic: ''},
    setSupport: () => {}
});


export const SupportProvider = ({children}: SupportProviderProps) => {

    const [support, setSupport] = useState<{isOpen: boolean, topic: string}>({isOpen: false, topic: ''});
    return (
        <SupportContext.Provider value={{support, setSupport}}>
            {children}
        </SupportContext.Provider>
    )
}

export const useSupport = () => useContext(SupportContext);