import React, {useEffect} from "react";
import {FormikState, useFormik} from "formik";
import {supportSchema, supportValue, SupportValuesProps} from "../../shared/formValidator/supportFormValidator";
import Input from "../../components/Input/input";
import Select from "../../components/Select";
import TextArea from "../../components/TextArea";
import {useTicket} from "../../shared/hooks/useTicket";

type FormProps = {
    onSubmit: (values: SupportValuesProps, resetForm: (nextState?: Partial<FormikState<SupportValuesProps>>) => void, related_uuid: string) => void,
    onClose: () => void
}


const Form = ({onSubmit, onClose}: FormProps) => {

    const {subjectList, priorityList, loadSubjects, loadPriority} = useTicket()

    const handleSubmit = (values) => {
        onSubmit(values, formik.resetForm, "")
    }

    const formik = useFormik({
        initialValues: supportValue,
        validationSchema: supportSchema,
        onSubmit: handleSubmit,
        validateOnBlur: true,
        validateOnChange: true,
        enableReinitialize: true,
        validateOnMount: true,
        isInitialValid: false
    })

    useEffect(() => {
        loadPriority();
        loadSubjects();
    },[])

    return (
        <form onSubmit={formik.handleSubmit}>
            <Input
                label="Title"
                formik={formik}
                name="title"
                type="text"
                required={true}
            />
            <div className="grid grid-cols-2">
                <Select
                    label="Subject"
                    options={subjectList}
                    value={formik.values.subject}
                    onChange={value => formik.setFieldValue("subject", value)}
                    placeholder="Select An Item ..."
                    required={true}
                />
                <Select
                    label="Priority"
                    options={priorityList}
                    value={formik.values.priority}
                    onChange={value => formik.setFieldValue("priority", value)}
                    placeholder="Select An Item..."
                    required={true}
                />
            </div>
            <TextArea name="body" formik={formik} value={formik.values.body} onChange={formik?.handleChange}
                      label="Message" placeholder="Write Your Mesasge..." required={true}/>
            <div className="flex justify-end gap-x-4">
                <button className="btn btn-primary btn-outline" type="button" onClick={onClose}>Cancel</button>
                <button className={`btn btn-primary ${formik.isValid ? "" : "btn-disabled"}`} type="submit">Submit</button>
            </div>
        </form>
    )
}


export default Form;