import React from "react";
import {useNavigate} from "react-router-dom";

const SettingTitle = () => {

    const navigate = useNavigate();
    const handleClick = () => {
        navigate('/workspace');
    }

    return (
        <div className="py-5 px-6 bg-white shadow-sm flex items-center">
            <button onClick={handleClick} className="flex"><i className="icon icon-24 icon-backward" /></button><div className="ml-3 truncate max-w-[calc(100%-36px)]" title="setting">Setting</div>
        </div>
    )
}

export default SettingTitle;