import React, {forwardRef, useEffect, useState} from "react";
import Loading from "../Loading";
import {MentionsInput, Mention} from "react-mentions";
import {useAuth} from "../../context/AuthContext";

type QuestionFormProps = {
    onSubmit: (e) => void,
    onSubmitMessageAsDraft: () => void,
    userQuestion: string,
    setUserQuestion: (e) => void,
    isLoading: boolean,
    ref: React.Ref<HTMLInputElement>,
    mentionItems: { label: string, value: string, type?: string, display?: string }[] | [],
}

const MessageForm = forwardRef<HTMLInputElement, QuestionFormProps>(({
                                                                         onSubmit,
                                                                         onSubmitMessageAsDraft,
                                                                         userQuestion,
                                                                         setUserQuestion,
                                                                         isLoading,
                                                                         mentionItems
                                                                     }, ref) => {

    const [mentionList, setMentionList] = useState<{ id: string, display: string }[] | []>([]);
    const {user} = useAuth();

    useEffect(() => {
        if (mentionItems.length > 0) {
            const temp = mentionItems.filter(item => item.value !== user.uuid).map(item => {
                return {id: item.value, display: item.label}
            });

            setMentionList(temp)
        }
    }, [mentionItems]);

    const onSubmitAsDraft = () => {
        onSubmitMessageAsDraft();
    }

    return (
        <div className="bg-white shadow-sm py-4 px-6">
            <form onSubmit={onSubmit}>
                <div className="flex pr-2 h-[36px] relative">
                    <MentionsInput inputRef={ref} value={userQuestion}
                                   className="flex-1 h-full border border-lightGray-200 outline-0 pl-3 bg-light-300 rounded ml-3 py-[5px] rounded-r-none border-r-0 pr-[100px]"
                                   onChange={e => setUserQuestion(e.target.value)} singleLine={true}>
                        <Mention trigger="@" data={mentionList} className="top-unset bottom-0"
                                 markup="@__display__(__id__)"/>
                    </MentionsInput>
                    <div className="btn-group absolute right-0">
                        <button
                                className={`btn btn-primary px-6 w-[103px] text-sm h-7 min-h-[36px]  ${isLoading ? "cursor-not-allowed" : "cursor-pointer"}`}
                                type="submit">
                            {!isLoading ? "Submit" : <Loading/>}
                        </button>
                        <button type="button" className="btn btn-primary h-7 min-h-[36px]" onClick={onSubmitAsDraft}><i className="icon icon-24 icon-draft" /></button>
                    </div>
                </div>
            </form>
        </div>
    )
})

export default MessageForm;