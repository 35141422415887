import Spinner from "../Loading/Spinner";
import React from "react";

type DataReportItemProps = {
    isLoading: boolean,
    icon: string,
    data: number | null,
    title: string,
    iconTitle: string,
    percent?: string | null,
    onClick?: () => void,
    descriptionRecords?: string
}
const DataReportItem = ({isLoading, icon, data, title, iconTitle, percent, onClick, descriptionRecords}: DataReportItemProps) => {
    return (
        <>
            {
                isLoading ? <Spinner/> : <i className={`icon icon-24 icon-${icon}`}/>
            }
            <span>{title}</span>
            {
                !isLoading && data !== null ? (
                    <>
                        <span>{Math.trunc(data)} {percent ? percent : ""}</span>
                        {descriptionRecords && descriptionRecords !== "" ? <span>{descriptionRecords}</span> : null}
                        <i className={`icon icon-20 icon-search ${data === 0 ? "disabled cursor-default pointer-events-none" : "cursor-pointer"}`}
                           title={iconTitle} onClick={onClick}/>
                    </>
                ) : null
            }
        </>
    )
}

export default DataReportItem;