import React, {useEffect, useState} from 'react';
import ActionBar from "../../../components/ActionBar";
import DescriptionPage from "../../../components/SettingSidebar/DescriptionPage";
import Form from "./Form";
import {organizationMe, updateOrganization} from "../../../services";
import {IOrganization} from "../../../shared/interfaces/IOrganization";
import {useError} from "../../../context/ErrorContext";
import {OrganizationValuesType} from "../../../shared/formValidator/organizationFormValidator";
import {useNavigate} from "react-router-dom";

const EditOrganization = () => {

    const [organization, setOrganization] = useState<IOrganization | null>();

    const {setError} = useError();
    const navigate = useNavigate();

    const onSubmit = async (values: OrganizationValuesType) => {

        const formValues = {
            title: values.title.trim(),
            is_active: values.is_active,
            is_two_fa_enable: values.is_two_fa_enable,
            website: values.website?.trim(),
            admin_email: values.organization_admin?.email ? values.organization_admin?.email : null,
            country_uuid: values.country?.value,
            industry_uuid: values.industry_type?.value,
        }

        try {
            await updateOrganization(values.uuid!, formValues);
            navigate('/setting/organization')
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    const loadOrganization = async () => {
        try {
            const data = await organizationMe();
            setOrganization(data);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    useEffect(() => {
        loadOrganization();
    }, []);

    return (
        <div className="flex flex-col gap-x-1 gap-y-1 w-full">
            <div className="flex flex-col flex-1 gap-y-1">
                <ActionBar goBack={true} pageTitle="Organization" topic="organization" isShow={false}/>
            </div>
            <div className="flex h-[calc(100vh-133px)] gap-x-1">
                <DescriptionPage>
                    <p>edit organization info</p>
                </DescriptionPage>
                <section className="h-full flex flex-col p-6 bg-white shadow-sm flex-1 overflow-y-auto">
                    <div className="border border-light-100 rounded-lg p-6 grid grid-cols-3">
                        {
                            organization ? (<Form onSubmit={onSubmit} data={organization}/>) : null
                        }
                    </div>
                </section>
            </div>
        </div>
    )
}

export default EditOrganization;