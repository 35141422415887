import React, {useState} from "react";
import WorkSpaceTitle from "./WorkSpaceTitle";
import data from "../../mockData/data.json";
import SidebarMenuItem from "./SidebarMenuItem";
import {useLocation} from "react-router-dom";

const MainSidebar = () => {
    const {SidebarMenuItems} = data;

    const {pathname} = useLocation();

    const [isExpanded, setIsExpanded] = useState(false);

    const handleClick = () => {
        setIsExpanded(prevState => !prevState)
    }

    return (
        <div className={`h-[calc(100vh-64px)] flex flex-col transition-all ${isExpanded ? "max-w-[265px] w-[265px]" : "max-w-[73px]"}`}>
            <WorkSpaceTitle isExpanded={isExpanded}/>
            <nav className="py-5 px-4 bg-white shadow-sm mt-1 flex-1 relative">
                <ul className={`flex list-none flex-col main-sidebar-list`}>
                    {
                        SidebarMenuItems.map(item => {
                            return (
                                <SidebarMenuItem {...item} key={item.id} isExpanded={isExpanded} isActive={pathname.split('/').includes(item.path)}/>
                            )
                        })
                    }
                    {
                        !isExpanded && <span className="menu-item"/>
                    }
                </ul>
                <button onClick={handleClick} className="shadow-sm absolute inset-y-1/2 right-[-12px] bg-white h-fit rounded-full p-1"><i className={`icon icon-24 icon-expanded transition-all ${isExpanded ? "rotate-180" : ""}`} /></button>
            </nav>
        </div>
    )
}

export default MainSidebar;