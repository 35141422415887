import React, {forwardRef} from "react";
import Loading from "../Loading";

type QuestionFormProps = {
    onSubmit: (e) => void,
    userQuestion: string,
    setUserQuestion: (e) => void,
    isLoading: boolean,
    ref: React.Ref<HTMLInputElement>
}

const QuestionForm = forwardRef<HTMLInputElement, QuestionFormProps>(({onSubmit, userQuestion, setUserQuestion, isLoading}, ref) => {
    return (
        <div className="bg-white shadow-sm py-4 px-6">
            <form onSubmit={onSubmit}>
                <div className="flex relative pr-2">
                    <label className="self-center text-sm">Ask form AI Copilot</label>
                    <input type="text"
                           className="flex-1 border border-lightGray-200 focus:border-lightGray-200 outline-0 pl-3 bg-light-300 rounded ml-3 py-[5px] rounded-r-none border-r-0 pr-[100px]"
                           value={userQuestion}
                           ref={ref}
                           onChange={e => setUserQuestion(e.target.value)}/>
                    <button
                        className={`btn btn-primary px-6 absolute w-[103px] text-sm h-7 min-h-[36px] right-0 ${isLoading ? "cursor-not-allowed" : "cursor-pointer"}`}
                        type="submit">
                        {!isLoading ? "Submit" : <Loading/>}
                    </button>
                </div>
            </form>
        </div>
    )
})

export default QuestionForm;