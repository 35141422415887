import {useDropzone} from "react-dropzone";
import React, {useCallback, useEffect} from "react";
import {useError} from "../../context/ErrorContext";

type DropZoneProps = {
    files: File[] | null,
    setFiles: (files: File[]) => void,
    className?: string,
    percentUploaded?: number,
    acceptedFileType?: any,
    isDisabled?: boolean
}

const DropZone = ({files, setFiles, className = "", percentUploaded = 0, acceptedFileType, isDisabled}: DropZoneProps) => {

    const {setError} = useError();

    const onDrop = useCallback((acceptedFiles: File[]) => {
        setFiles(acceptedFiles);
    }, [])

    const {getRootProps, getInputProps, isDragActive, fileRejections} = useDropzone({
        onDrop,
        accept: acceptedFileType,
        disabled: isDisabled,
        multiple: false
    })

    useEffect(() => {
        if (fileRejections?.length > 0) {
            if (fileRejections[0].errors[0].code === 'file-invalid-type') {
                setError({message: "This File Type Not Supported.", type: 'error'});
            }
        }
    }, [fileRejections])

    return (
        <div className={`drop-zone-wrapper ${className}`}>
            <div {...getRootProps()} className={`drop-zone ${isDisabled ? "" : "cursor-pointer"}`}>
                <i className="icon icon-28 icon-upload"/>
                <input {...getInputProps()} />
                {
                    isDragActive ?
                        <p>Drop the files here ...</p> :
                        <p>Drag 'n' drop file here, or click to select a file</p>
                }
            </div>
            {
                files && files.length > 0 && files.map((file, index) => {
                    return (
                        <div className="uploading-item" key={index}>
                            <span>{file.name}</span>
                            <div className="w-full bg-lightGray-100 rounded-full h-2.5 ">
                                <div className="bg-blue h-2.5 rounded-full" style={{width: `${percentUploaded}%`}}></div>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    )

}

export default DropZone;