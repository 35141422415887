import client from "./config";
import {IWorkSpace} from "../shared/interfaces/IWorkSpace";

export const login = async (values) => {
    const {data} = await client.post(`login/access-token`, values, {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    })
    return data;
}

export const logout = async () => {
    const {data} = await client.post(`logout`);
    return data;
}

export const forgetPassword = async (email:string) => {
    const {data} = await client.post(`/password-recovery/${email}`);
    return data;
}
export const resetPassword = async (values) => {
    const {data} = await client.post(`/reset-password`, values);
    return data;
}

export const verifyCaptcha = async (value) => {
    const {data} = await client.post(`/verify-recaptcha/${value}`);
    return data
}

export const updatePassword = async (values) => {
    const {data} = await client.put(`/update-password`, values)
    return data;
}

export const supersetGuestToken = async (values) => {
    const {data} = await client.post(`login/superset-guest-token`, values, {
        headers: {
            "Content-Type": "application/json"
        }
    });
    return data;
}

export const models = async (uuid) => {
    const {data} = await client.get(`models/workspace/${uuid}`);
    return data;
}

export const getModel = async (uuid: string) => {
    const {data} = await client.get(`models/${uuid}`);
    return data;
}

export const deleteModel = async (uuid: string) => {
    const {data} = await client.delete(`models/${uuid}`);
    return data;
}

export const dashboards = async (uuid) => {
    const {data} = await client.get(`dashboards/model/${uuid}?skip=0&limit=100`);
    return data;
}

export const makeFailedRunAnalytics = async (modelUUId: string) => {
    const {data} = await client.put(`models/make-model-faild/${modelUUId}`);
    return data;
}

export const analytics = async (id) => {
    const {data} = await client.get(`configurations/analytics?workspace_id=${id}`);
    return {properties: data.result.properties, type: "object", rules: findRules({allOf: data.result.allOf})};
}

const findRules = (schema) => {
    if (!schema?.allOf || !Array.isArray(schema.allOf))
        return {};
    const rules = schema.allOf.reduce((allRules, rule) => {
        const fieldNames = Object.keys(rule.if?.properties);
        fieldNames.forEach((n) => {
            if (allRules[n]) {
                allRules[n] = [...allRules[n], rule];
            } else {
                allRules[n] = [rule];
            }
        })
        return allRules;
    }, {})
    return rules;
}

export const modelRunAnalytics = async (values) => {
    const {data} = await client.post(`models/analytic`, values);
    return data;
}

//  users end points
export const users = async () => {
    const {data} = await client.get(`users/?skip=0&limit=100`);
    return data;
}

export const user = async (id) => {
    const {data} = await client.get(`users/${id}`);
    return data;
}

export const userOrg = async (uuid) => {
    const {data} = await client.get(`users/org-user/${uuid}`);
    return data;
}

export const createUser = async (values) => {
    const {data} = await client.post(`users`, values);
    return data;
}

export const createUserOrg = async (values) => {
    const {data} = await client.post(`users/org-user`, values);
    return data;
}
export const updateUser = async (uuid, values) => {
    const {data} = await client.put(`users/${uuid}`, values);
    return data;
}

export const updateUserOrg = async (uuid, values) => {
    const {data} = await client.put(`users/org-user/${uuid}`, values);
    return data;
}

export const uploadAvatar = async (values, uuid: string) => {
    const {data} = await client.post(`users/avatar/${uuid}`, values, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    return data;
}

export const verificationCode = async (token: string, verificationCode: string) => {
    const {data} = await client.post(`users/2fa/verify-code/${token}/${verificationCode}`);
    return data;
}

export const sendTwoFAEmail = async (uuid: string) => {
    const {data} = await client.post(`users/2fa/send-2fa-email/${uuid}`);
    return data;
}


export const me = async () => {
    const {data} = await client.get(`users/me`);
    return data;
}

// copilot end points
export const conversations = async (modelUUId: string) => {
    const {data} = await client.get(`copilot/by-model/${modelUUId}`);
    return data;
}

export const conversation = async (conversationUUId: string, cancel) => {
    const {data} = await client.get(`copilot/${conversationUUId}`, {cancelToken: cancel});
    return data;
}

export const createConversation = async (values) => {
    const {data} = await client.post(`copilot/ai-query/`, values);
    return data;
}

export const createConversationOnBoarding = async (values) => {
    const {data} = await client.post(`copilot/onboarding/`, values);
    return data;
}

export const createChatItem = async (conversationId, values) => {
    const {data} = await client.post(`copilot/${conversationId}`, values);
    return data;
}


export const deleteConversation = async (id) => {
    const {data} = await client.delete(`copilot/${id}`);
    return data;
}

export const shareConversation = async (id, values) => {
    const {data} = await client.post(`copilot/share/${id}`, values);
    return data;
}


//  teams end points
export const teams = async () => {
    const {data} = await client.get(`teams/?skip=0&limit=100`);
    return data;
}

export const team = async (uuid:string) => {
    const {data} = await client.get(`teams/${uuid}`);
    return data;
}

export const createTeam = async (values) => {
    const {data} = await client.post('teams/', values);
    return data;
}


export const updateTeam = async (uuid, values) => {
    const {data} = await client.put(`teams/${uuid}`, values);
    return data;
}

export const deleteTeam = async (uuid) => {
    const {data} = await client.delete(`teams/${uuid}`);
    return data;
}
//  team member end points

export const teamMembers = async () => {
    const {data} = await client.get(`team-member/?skip=0&limit=100`);
    return data;
}

// country end points

export const countries = async () => {
    const {data} = await client.get(`country/?skip=0&limit=100`);
    return data;
}


// industry end points

export const industries = async () => {
    const {data} = await client.get(`industry/?skip=0&limit=100`);
    return data;
}


// workspaces end point
export const workspaces = async () => {
    const {data} = await client.get(`workspaces/?skip=0&limit=100`);
    return data;
}

export const workspacesByOrg = async (organizationId: string) => {
    const {data} = await client.get(`workspaces/by-organization/${organizationId}`);
    return data;
}

export const workspace = async (uuid: string) => {
    const {data} = await client.get(`workspaces/${uuid}`);
    return data;
}

export const organization = async (id) => {
    const {data} = await client.get(`organization/${id}`);
    return data;
}

export const organizationMe = async () => {
    const {data} = await client.get(`organization/organization-data`);
    return data;
}

export const updateOrganization = async (uuid: string, values) => {
    const {data} = await client.put(`organization/${uuid}`, values);
    return data;
}
export const createWorkspace = async (values) => {
    const {data} = await client.post(`workspaces/`, values);
    return data;
}
export const deleteWorkspace = async (uuid: string) => {
    const {data} = await client.delete(`workspaces/${uuid}`);
    return data;
}

export const getNotifications = async (page: number, type: string) => {
    const {data} = await client.get(`notification/user-notification/?skip=${page}&limit=10&notification_type=${type}`);
    return data;
}

export const markAsRead = async (uuid: string) => {
    const {data} = await client.put(`notification/single-mark-read/${uuid}`);
    return data;
}

export const markAllAsRead = async () => {
    const {data} = await client.put(`notification/mark-all-read`);
    return data;
}

export const getNotificationCount = async (uuid: string) => {
    const {data} = await client.get(`notification/user-notification/${uuid}/count`);
    return data;
}
// connector type
export const connectorTypes = async () => {
    const {data} = await client.get(`connector-type/`);
    return data;
}


// organization role

export const organizationRoles = async () => {
    const {data} = await client.get(`organization-role/?skip=0&limit=100`);
    return data;
}
// data composer
export const preProcessing = async (values) => {
    const {data} = await client.post(`data-import/preprocessing`, values);
    return data;
}
export const integration = async (values) => {
    const {data} = await client.post(`data-import/integration`, values)
    return data;
}

export const summary = async (id) => {
    const {data} = await client.get(`data-import/overview/${id}`);
    return data;
}

export const azureStatus = async (values) => {
    const {data} = await client.get(`data-import/data-import/${values?.dataImportId}/datafactory-status/${values?.runId}`)
    return data;
}

export const airFlowStatus = async (values) => {
    const {data} = await client.get(`data-import/data-import/${values?.dataImportId}/airflow-status/${values.dagaId}/run/${values.runId}`);
    return data;
}

export const dataComposerConfirm = async (id) => {
    const {data} = await client.put(`data-import/confirm/${id}`);
    return data;
}

export const dataImportReport = async (values) => {
    const {data} = await client.get(`data-import-result/data-import/${values.dataImportId}/phase-name/${values.phaseName}`)
    return data;
}

export const modelCompose = async (values) => {
    const {data} = await client.post(`data-composer/`, values);
    return data;
}

export const getDataImports = async (workspaceUUId: string) => {
    const {data} = await client.get(`/data-import/unconfirm/${workspaceUUId}`);
    return data;
}

export const getDataImportStatus = async (dataImportUUId: string) => {
    const {data} = await client.get(`/data-import/status/${dataImportUUId}`);
    return data;
}

// analytic data type

export const analyticsDataModel = async () => {
    const {data} = await client.get(`analytic-data-type/analytic`);
    return data;
}

export const analyticDataModel = async (id) => {
    const {data} = await client.get(`analytic-data-type/analytic/${id}`);
    return data;

}

export const getDataTypeStatus = async (uuid) => {
    const {data} = await client.get(`workspaces/data-type-status/${uuid}`);
    return data;
}

// upload file

export const uploadDataImportFile = async (values) => {
    const {data} = await client.post(`data-import/local-upload`, values, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
    return data;
}

export const dataImportFiles = async (workspaceUUId, data_type_uuid) => {
    const {data} = await client.get(`data-import/files/${workspaceUUId}/${data_type_uuid}`);
    return data;
}

export const dataImportNotification = async (values) => {
    const {data} = await client.post(`data-import/data-import/`, values);
    return data;
}

// report data
export const getReportData = async (dataImportId, phaseName, metricName, count) => {
    const {data} = await client.get(`data-import-result/data-import/${dataImportId}/phase-name/${phaseName}/metric-name/${metricName}/top-rows/${count}`);
    return data;
}

export const getReportDataSummary = async (dataImportId, phaseName, metricName) => {
    const {data} = await client.get(`data-import-result/data-import/${dataImportId}/phase-name/${phaseName}/metric-name/${metricName}/summary`);
    return data;
}

export const getCheckStatus = async (workspaceId) => {
    const {data} = await client.get(`data-import-status/${workspaceId}`);
    return data;
}

export const getExecutionItem = async (workspaceUUId: string) => {
    const {data} = await client.get(`execution-item/${workspaceUUId}`);
    return data;
}


export const getExecutionStep = async (uuid: string) => {
    const {data} = await client.get(`execution-step/${uuid}`);
    return data;
}

export const roles = async () => {
    const {data} = await client.get('access-control/role/?skip=0&limit=100');
    return data;
}

export const ticketPriorities = async () => {
    const {data} = await client.get(`ticket-priority/?skip=0&limit=100`);
    return data;
}

export const ticketSubjects = async () => {
    const {data} = await client.get(`ticket-subject/?skip=0&limit=100`);
    return data;
}

export const tickets = async () => {
    const {data} = await client.get('ticket/');
    return data;
}

export const ticket = async (uuid: string) => {
    const {data} = await client.get(`ticket/${uuid}`);
    return data
}

export const createTicket = async (values) => {
    const {data} = await client.post(`ticket/`, values);
    return data;
}

export const updateRateTicket = async (uuid: string, rate:number) => {
    const {data} = await client.put(`ticket/update-rate/${uuid}?rate=${rate}`);
    return data;
}

export const updateTicketStatus = async (uuid: string, value) => {
    const {data} = await client.put(`ticket/update-status/${uuid}`, value);
    return data;
}

export const learnContent = async (resource: string) => {
    const {data} = await client.get(`learn-academy-content/read-by-resource/${resource}`);
    return data;
}
export const channels = async (workspaceUUId: string) => {
    const {data} = await client.get(`message/message-channel/read-by-workspace/${workspaceUUId}`);
    return data;
}

export const getChannelByResource = async (workspaceUUId: string, resource: string, values) => {
    const {data} = await client.post(`/message/message-channel/read-by-workspace-and-page/${workspaceUUId}/${resource}`, values);
    return data;
}

export const channel = async (uuid: string) => {
    const {data} = await client.get(`message/message-channel/${uuid}`);
    return data;
}

export const createChannel = async (values) => {
    const {data} = await client.post(`message/message-channel/`, values);
    return data;
}

export const updateChannel = async (uuid: string, values) => {
    const {data} = await client.put(`message/message-channel/${uuid}`, values)
    return data;
}

export const deleteChannel = async (uuid: string) => {
    const {data} = await client.delete(`message/message-channel/${uuid}`);
    return data
}

export const channelMessages = async (channelUUId: string) => {
    const {data} = await client.get(`message/by-channel-uuid/${channelUUId}`);
    return data;
}

export const getMessage = async (uuid: string) => {
    const {data} = await client.get(`/message/${uuid}`);
    return data;
}

export const createMessage = async (values) => {
    const {data} = await client.post(`message/`, values);
    return data;
}

export const updateMessage = async (values, uuid: string) => {
    const {data} = await client.put(`message/${uuid}`, values);
    return data;
}

export const deleteMessage = async (uuid: string) => {
    const {data} = await client.delete(`message/${uuid}`);
    return data;
}

export const createMessageMention = async (values) => {
    const {data} = await client.post(`message/message-mention/`, values);
    return data;
}

export const messageMentions = async (uuid: string) => {
    const {data} = await client.get(`message/message-mention/get-by-message/${uuid}`);
    return data;
}

export const usersMention = async (resource: string, workspaceId: string) => {
    const {data} = await client.get(`message/user/${resource}/${workspaceId}`);
    return data;
}

export const markMessageAsRead = async (values) => {
    const {data} = await client.put(`message/audience/by-user-and-message`, values);
    return data;
}

export const audiences = async (uuid: string) => {
    const {data} = await client.get(`message/audience/get-by-message/${uuid}`);
    return data;
}