import React, {useEffect, useState} from "react";
import Header from "../components/Header";
import {mainLayoutRoutes} from "../routes";
import {Route, Routes, Navigate} from "react-router-dom";
import {ModelProvider} from "../context/ModelContext";
import {useAuth} from "../context/AuthContext";
import Notification from "../components/Notification";
import {IRoute} from "../shared/interfaces/IRoute";
import {DashboardsProvider} from "../context/DashboardsContext";
import {useError} from "../context/ErrorContext";
import {NotificationProvider} from "../context/NotificationContext";
import PermissionProvider from "../context/PermissionContext";
import NotFound from "../pages/NotFound";
import {NotificationCountProvider} from "../context/NotificationCountContext";
import {MessageProvider} from "../context/MessageContext";
import MessageCard from "../components/Message/MessageCard";
import {SupportProvider} from "../context/SupportContext";
import SupportCard from "../components/Support/SupportCard";
import {LearnProvider} from "../context/LearnContext";
import Learn from "../components/Learn";
import {useRouteGenerator} from "../shared/hooks/useRouteGenerator";
import {IsShowModalCopilotProvider, useIsShowModalCopilot} from "../context/IsShowModalCopilotContext";
import {useWorkSpace} from "../context/WorkSpaceContext";
import LockOutTimer from "../shared/HOC/LockOutTimer";
import {me} from "../services";

type MainLayoutProps = {
    children?: React.ReactNode
}

export const MainLayout = ({children}: MainLayoutProps) => {

    const {setUser} = useAuth();
    const {setError} = useError();

    const [notificationIsOpen, setNotificationIsOpen] = useState<boolean>(false);
    const {routeGenerator} = useRouteGenerator();
    const {setIsShowModalCopilot} = useIsShowModalCopilot();
    const {workSpace} = useWorkSpace();
    if (!localStorage.getItem('token')) return <Navigate replace to="/sign-in"/>

    const handleShowNotification = () => setNotificationIsOpen(true)
    const handleCloseNotification = () => setNotificationIsOpen(false)

    const handleChangeStorage = (event) => {
        if (event.key === "token" && event.newValue !== null) {
            loadUser()
        }
    }

    const loadUser = async () => {
        try {
            const data = await me();
            setUser(data);
        } catch (error: any) {
            setError({message: error?.response?.data?.detail, type: 'error'});
        }
    }

    useEffect(() => {
        window.addEventListener("storage", handleChangeStorage);

        return () => {
            window.removeEventListener("storage", handleChangeStorage)
        }
    }, [])

    useEffect(() => {
        setIsShowModalCopilot(true);
    }, [workSpace]);

    return (
        <LockOutTimer>
            <main className="w-screen h-screen bg-light">
                <NotificationProvider>
                    <NotificationCountProvider>
                        <PermissionProvider>
                            <IsShowModalCopilotProvider>
                                <MessageProvider>
                                    <SupportProvider>
                                        <LearnProvider>
                                            <Header onOpenNotification={handleShowNotification}/>
                                            <ModelProvider>
                                                <DashboardsProvider>
                                                    {
                                                        <Routes>
                                                            {
                                                                mainLayoutRoutes.map((route: IRoute) => {
                                                                    return <Route key={route.id} path={route.path}
                                                                                  element={
                                                                                      route?.to ?
                                                                                          <Navigate to={route.to}/> :
                                                                                          routeGenerator(route)}/>
                                                                })
                                                            }
                                                            <Route path="*"
                                                                   element={<div
                                                                       className="h-[calc(100%-64px)] flex mt-[2px]">
                                                                       <NotFound/></div>}/>
                                                        </Routes>
                                                    }
                                                    {
                                                        children
                                                    }
                                                </DashboardsProvider>
                                            </ModelProvider>

                                            <MessageCard/>
                                            <Notification isOpen={notificationIsOpen}
                                                          onClose={handleCloseNotification}/>
                                            <Learn/>
                                            <SupportCard/>
                                        </LearnProvider>
                                    </SupportProvider>
                                </MessageProvider>
                            </IsShowModalCopilotProvider>
                        </PermissionProvider>
                    </NotificationCountProvider>
                </NotificationProvider>
            </main>
        </LockOutTimer>
    )
}

export default MainLayout;