import Modal from "../Modal";
import React, {useEffect} from "react";
import {IWorkSpace} from "../../shared/interfaces/IWorkSpace";
import Loading from "../Loading";

type ModalDeleteWorkspaceProps = {
    isOpen: boolean,
    onClose: () => void,
    onDelete: (uuid: string) => void,
    workspace: IWorkSpace | null,
    isLoading: boolean
}
const ModalDeleteWorkspace = ({isOpen, onClose, onDelete, workspace, isLoading}: ModalDeleteWorkspaceProps) => {

    const [value, setValue] = React.useState("");

    useEffect(() => {
        if (isOpen) {
            setValue("")
        }
    }, [isOpen]);

    const handleDelete = () => {
        if (workspace?.uuid) {
            onDelete(workspace.uuid)
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} classNameSize="sm" isCancelBtn={false} closeButton={false}>
            <h2 className="text-center text-xl font-bold">
                Confirm Workspace Deletion
            </h2>
            <p className="mt-6 text-sm text-center">
                Are you sure you want to delete this workspace? This action is irreversible and will permanently remove the workspace, along with all associated data, models, workflows, dashboards, and results.
            </p>
            <p className="mt-4 text-sm text-center">Note: Deleting a workspace does not automatically adjust your billing plan. To modify your subscription, please visit your Billing Settings.</p>
            <p className="mt-4 text-sm text-center">To confirm deletion, please enter the name of the workspace below:</p>
            <div className="form-group mt-1">
                <input type="text" className="form-input"
                       value={value} autoComplete="off"
                       onChange={e => setValue(e.target.value)}/>
                <span className="absolute text-xs text-red top-full left-0 whitespace-nowrap">
            </span>
            </div>
            <button type="button" className={`btn btn-primary w-full ${workspace?.title.trim() === value ? "" : "btn-disabled"} ${isLoading ? "pointer-events-none" : ""}`} onClick={handleDelete}>{isLoading ? <Loading /> : "Delete"}</button>
            <button type="button" className="btn btn-primary btn-outline w-full mt-2" onClick={onClose}>Cancel</button>
        </Modal>
    )
}

export default ModalDeleteWorkspace;