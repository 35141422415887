import React, {useEffect, useRef} from "react";
import {IConversationCopilot} from "../../shared/interfaces/IConversationCopilot";

import MessageItem from "./MessageItem";
import Loading from "../Loading";

type ConversationHistoryProps = {
    selectedConversation: IConversationCopilot | {},
    isLoading?: boolean,
    selectedOption?: string,
    setSelectedOption?: (title: string) => void,
    isError?: boolean,
    handleResendQuestion?: () => void
}

const ConversationHistory = ({selectedConversation, isLoading, setSelectedOption, selectedOption, isError, handleResendQuestion}: ConversationHistoryProps) => {
    const conversationRef1 = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const scrollEl = conversationRef1.current;
        scrollEl?.scroll({top: scrollEl?.scrollHeight, behavior: 'smooth'});
    }, [selectedConversation])

    return (
        <div ref={conversationRef1}
             className="border border-light-100 rounded-lg flex flex-col overflow-y-auto gap-y-4 px-6 py-3 h-full">
            {
                "items" in selectedConversation && selectedConversation?.items.length > 0 && selectedConversation?.items.map((message, index) => {
                    return (<MessageItem message={message} key={index} selectedOption={selectedOption} onClick={setSelectedOption}/>)
                })
            }
            {
                isLoading && (
                    <>
                        <div
                            className={`flex items-start gap-x-4 mr-auto`}>
                            <img src="/images/avatar-bot.png"
                                 className="w-6 h-6 rounded-full"/>
                            <div className={`rounded flex-1 py-3 px-4 bg-blue-400`}>
                                <Loading />
                            </div>
                        </div>
                    </>
                )
            }
            {
                isError ? (
                    <button className="btn btn-primary px-4 btn-outline w-fit mx-auto mt-4" onClick={handleResendQuestion}>Resend Your Question</button>
                ) : null
            }
        </div>
    )
}

export default ConversationHistory;