import React from "react";

type ItemProps = {
    item: {value: number, label: string},
    onClick: (item) => void,
    isActive: boolean
}
const Item = ({item, onClick, isActive}: ItemProps) => {

    const handleClick = () => {
        onClick && onClick(item);
    }

    return (
        <li value={item.value} onClick={handleClick} className={isActive ? "active" : ""}>
            {item.label}
        </li>
    )
}


export default Item;