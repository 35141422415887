import React, {createContext, ReactNode, useContext, useState} from "react";
import {IWorkSpace} from "../shared/interfaces/IWorkSpace";

type WorkSpaceProviderProps = {
    children?: ReactNode
}

export const WorkSpaceContext = createContext<{ workSpace: IWorkSpace | null, setWorkSpace: (workSpace: IWorkSpace) => void }>({workSpace: null, setWorkSpace: () => {}})
export const WorkSpaceProvider = ({children}: WorkSpaceProviderProps) => {

    const [workSpace, setWorkSpace] = useState<IWorkSpace | null>(null);

    return (
        <WorkSpaceContext.Provider value={{workSpace, setWorkSpace}}>
                {children}
        </WorkSpaceContext.Provider>
    )
}

export const useWorkSpace = () => useContext(WorkSpaceContext);
