import React, {useEffect, useRef, useState} from "react";
import Tab from "../Tab";
import NotificationItem from "./NotificationItem";
import {useNotifications} from "../../context/NotificationContext";
import {INotification} from "../../shared/interfaces/INotification";
import {useError} from "../../context/ErrorContext";
import {getNotifications, markAllAsRead} from "../../services";
import {useNotificationCount} from "../../context/NotificationCountContext";
import Spinner from "../Loading/Spinner";

type notificationProps = {
    isOpen: boolean,
    onClose: () => void
}

const Notification = ({isOpen, onClose}: notificationProps) => {

    const [activeTab, setActiveTab] = useState(1);
    const {notifications, setNotifications} = useNotifications();
    const {setNotificationCount} = useNotificationCount();
    const {setError} = useError();
    const notificationRef = useRef<HTMLDivElement>(null)
    const [page, setPage] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [endList, setEndList] = useState<boolean>(false);
    const listInnerRef = useRef<HTMLDivElement>(null);
    const listMentionsRef = useRef<HTMLDivElement>(null);

    const handleClickOutSide = (event) => {
        if (notificationRef.current && !notificationRef.current.contains(event.target)) {
            onClose()
        }
    }

    const handleActiveTab = key => {
        setActiveTab(key);
    }

    useEffect(() => {
        if (!isOpen) {
            setPage(0);
            setEndList(false);
            setNotifications([])
        }
    }, [isOpen]);

    const handleReadAll = async () => {
        try {
            await markAllAsRead();
            checkAsReadAllNotification()
            setNotificationCount(prevState => 0);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    const loadNotifications = async () => {
        setIsLoading(true)
        try {
            const data = await getNotifications(page, activeTab === 1 ? "system" : "mention");
            if (data.length < 10) {
                setEndList(true);
            }
            if (page === 0) {
                setNotifications(sortNotifications(data));
            } else {
                setNotifications([...notifications, ...sortNotifications(data)]);
            }
            setIsLoading(false)
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
            setIsLoading(false)
        }
    }

    useEffect(() => {
        if (isOpen) {
            setNotifications([])
            loadNotifications();
            setPage(0)
            setEndList(false);
        }
    }, [activeTab]);

    const checkAsReadAllNotification = () => {
        if (notifications.length > 0) {
            const cloneNotification: INotification[] = [...notifications];
            const notificationMarked = cloneNotification.map(item => {
                item.is_read = true;
                return item
            });
            setNotifications(notificationMarked);
        }
    }

    const sortNotifications = (notificationList: INotification[]) => {
        return notificationList.sort((a, b) => {
            return new Date(b.create_time).getTime() - new Date(a.create_time).getTime();
        })
    }

    const onScrollSystemNotification = () => {
        if (listInnerRef.current) {
            const {scrollTop, scrollHeight, clientHeight} = listInnerRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                if (!isLoading && !endList) {
                    setPage(prevState => prevState + 10);
                }
            }
        }
    };

    const onScrollMentionNotification = () => {
        if (listMentionsRef.current) {
            const {scrollTop, scrollHeight, clientHeight} = listMentionsRef.current;
            if (scrollTop + clientHeight === scrollHeight) {
                if (!isLoading && !endList) {
                    setPage(prevState => prevState + 10);
                }
            }
        }
    };

    useEffect(() => {
        if (isOpen) {
            loadNotifications()
        }
    }, [page, isOpen]);

    return (
        <section className={`notification-overlay ${isOpen ? "flex" : "hidden"}`} onClick={handleClickOutSide}>
            <div className="notifications p-6 rounded bg-white w-[401px] max-h-[calc(100vh-162px)]"
                 ref={notificationRef}>
                <div className="flex justify-between items-center">
                    <div className="flex gap-x-4 items-center">
                        <span className="py-2">Notification</span>
                        <span className="px-1 cursor-pointer text-xs h-fit text-primary" onClick={handleReadAll}>Mark All</span>
                    </div>
                    <i className="icon icon-24 icon-close-circle cursor-pointer" onClick={onClose}/>
                </div>
                <Tab activeKey={activeTab} onClick={handleActiveTab} TabItemClassName="flex-1">
                    <Tab.Pane label="Execution Report" key={1}>
                        <div className="flex flex-col gap-y-6 h-[calc(100vh-318px)] overflow-y-auto"
                             onScroll={onScrollSystemNotification}
                             ref={listInnerRef}>
                            <>
                                {
                                    notifications.length > 0 ? (
                                        notifications.map((notification: INotification) => {
                                            return (
                                                <NotificationItem {...notification}
                                                                  key={notification.id}
                                                                  onClose={onClose}/>
                                            )
                                        })
                                    ) : null
                                }
                                {
                                    isLoading ? <div className="mx-auto"><Spinner/></div> : null
                                }
                            </>
                        </div>
                    </Tab.Pane>
                    <Tab.Pane label="Mentions" key={2}>
                        <div className="flex flex-col gap-y-6 h-[calc(100vh-318px)] overflow-y-auto"
                             onScroll={onScrollMentionNotification}
                             ref={listMentionsRef}>
                            <>
                                {
                                    notifications.length > 0 ? (
                                        notifications.map((notification: INotification) => {
                                            return (
                                                <NotificationItem {...notification} key={notification.id}
                                                                  onClose={onClose}/>
                                            )
                                        })

                                    ) : null
                                }
                                {
                                    isLoading ? <div className="mx-auto"><Spinner/></div> : null
                                }
                            </>
                        </div>
                    </Tab.Pane>
                </Tab>
            </div>
        </section>
    )
}

export default Notification;