import Modal from "./index";
import React from "react";
import {IModel} from "../../shared/interfaces/IModel";
import Loading from "../Loading";

type ModalDeleteModelProps = {
    isOpen: boolean,
    onClose: () => void,
    onDelete: (uuid: string) => void,
    model: IModel | null,
    isLoading: boolean
}

const ModalDeleteModel = ({isOpen, onClose, onDelete, model, isLoading}: ModalDeleteModelProps) => {

    const handleDelete = () => {
        if (model?.uuid) {
            onDelete(model.uuid)
        }
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} classNameSize="sm" isCancelBtn={false} closeButton={false}>
            <h2 className="text-center text-xl font-bold">
                Confirm Model Deletion
            </h2>
            <p className="mt-6 text-sm text-center">
                Are you sure you want to delete this model? This action is irreversible and will permanently remove the model, along with all associated data, workflows, dashboards, and results.
            </p>
            <div className="flex gap-x-4 justify-end mt-6">
                <button type="button" className="btn btn-primary btn-outline" onClick={onClose}>Cancel</button>
                <button type="button" className={`btn btn-primary min-w-[85px] ${isLoading ? "pointer-events-none" : ""}`} onClick={handleDelete}>{isLoading ? <Loading /> : "Delete"}</button>
            </div>
        </Modal>
    )
}

export default ModalDeleteModel;