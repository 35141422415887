import React from "react";
import LearnTag from "./LearnTag";

type LearnContentProps = {
    learnContent: {
        title: string,
        link: string,
        body: string,
        tags: string[],
        id: number,
        uuid: string
    },
}

const LearnContent = ({learnContent}: LearnContentProps) => {
    return (
        <div className="flex flex-col px-4">
            <a href={learnContent.link} target="_blank"
                  className="border-b border-lightGray-200 w-fit mt-4">{learnContent.title}</a>
            <div className="flex flex-wrap gap-x-4 mt-3 text-sm">
                {
                    learnContent.tags.length > 0 && learnContent.tags.map((tag, j) => {
                        return <LearnTag tag={tag} key={j} />
                    })
                }
            </div>
            <p className="mt-2 text-sm">{learnContent.body}</p>
        </div>
    )
}

export default LearnContent;