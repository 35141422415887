import React, {useEffect, useState} from "react";
import {useAuth} from "../../context/AuthContext";
import {IMessage} from "../../shared/interfaces/IMessage";
import {renderDate} from "../../shared/utils/helper";
import {MESSAGE_STATUS} from "../../shared/constant";
import InitialProfileImage from "../Profile/InitialProfileImage";
import {useMessages} from "../../shared/hooks/useMessages";

type MessageItemProps = {
    message: IMessage,
    onDelete: (uuid: string) => void,
    onEdit: (uuid: string) => void,
    onReply: (message: IMessage) => void
}

const MessageItem = ({message, onDelete, onEdit, onReply}: MessageItemProps) => {

    const [messageStatus, setMessageStatus] = useState<string>(message.message_status_uuid);
    const {user} = useAuth();
    const {handleMarkAsRead, audienceList, loadAudiencesMessage, resetAudienceList} = useMessages()
    const userProfile = () => {
        return message.creator_info?.name && message.creator_info.last_name ? message.creator_info.name.charAt(0).toUpperCase() + message.creator_info.last_name.charAt(0).toUpperCase() : null;
    }

    const onReadMessage = async () => {
        const elem = document.activeElement as HTMLDivElement;
        if (elem) {
            elem?.blur();
        }
        await handleMarkAsRead(message.uuid!);
        setMessageStatus(MESSAGE_STATUS.READ);
    }

    const onDeleteMessage = () => {
        const elem = document.activeElement as HTMLDivElement;
        if (elem) {
            elem?.blur();
        }
        onDelete(message.uuid!)
    }

    const handleLoadAudience = async () => {
        await loadAudiencesMessage(message.uuid!);
    }

    const onEditMessage = () => {
        const elem = document.activeElement as HTMLDivElement;
        if (elem) {
            elem?.blur();
        }
        onEdit(message.uuid!)
    }

    const onReplyMessage = () => {
        const elem = document.activeElement as HTMLDivElement;
        if (elem) {
            elem?.blur();
        }
        onReply(message)
    }

    useEffect(() => {
        setMessageStatus(message.message_status_uuid)
    }, [message.message_status_uuid]);

    return (
        <div
            className={`w-10/12 flex items-end gap-x-4 chat ${message?.creator_info?.uuid === user.uuid ? "flex-row-reverse ml-auto chat-end" : "mr-auto chat-start"}`}>
            {
                message.creator_info?.avatar_url ? <img
                    src={message?.creator_info.avatar_url}
                    className="w-6 h-6 rounded-full object-cover"/> : <InitialProfileImage userProfile={userProfile()}/>
            }
            <div
                className={`rounded chat-bubble text-dark flex-1 relative py-3 dropdown dropdown-end px-4 ${message?.creator_info?.uuid === user.uuid ? "bg-blue-200" : "bg-blue-400"}`}>
                <label tabIndex={0}>
                    <i className="icon icon-24 icon-three-dots cursor-pointer absolute right-1 top-1"/>
                </label>
                <ul tabIndex={0} className="dropdown-content shadow bg-white menu p-0 rounded">
                    {
                        message.creator_info?.uuid !== user.uuid ? (
                            <li onClick={onReadMessage}><a>Mark As Read</a></li>
                        ) : null
                    }
                    {
                        message.creator_info?.uuid === user.uuid ? (
                                <li onClick={onDeleteMessage}><a>Delete</a></li>
                        ) : null
                    }
                    {
                        message.creator_info?.uuid === user.uuid && message.message_status_uuid === MESSAGE_STATUS.DRAFT ? (
                            <li onClick={onEditMessage}><a>Edit</a></li>
                        ) : null
                    }
                    {
                        message.replied_message_id === null ? (
                            <li onClick={onReplyMessage}><a>Reply</a></li>
                        ) : null
                    }
                    <li onMouseEnter={handleLoadAudience} onMouseLeave={resetAudienceList}><a>Viewers</a>
                        <ul className="shadow bg-white menu right-[100%] left-[unset] rounded">
                            {
                                audienceList.length > 0 && audienceList.filter(item => item.message_status_uuid === MESSAGE_STATUS.READ).map(item => {
                                    return (
                                        <li key={item.uuid}><a>{`${item.user_info.name} ${item.user_info.last_name}`}</a></li>
                                    )
                                })
                            }
                        </ul>
                    </li>
                </ul>
                {
                    message.replied_message_id ? (
                        <div className="text-sm flex flex-col border-l-2 border-primary pl-2">
                            <strong className="text-xs">{message.replied_message?.creator_info.name}</strong>
                            <span className="truncate">{message.replied_message?.text}</span>
                        </div>
                    ) : null
                }
                <p className="whitespace-pre-line">{message?.text}</p>
                <p className="text-xs flex items-center justify-end">
                    <span>{renderDate(message.created_at)}</span>
                    <span><i
                        className={`icon icon-16 icon-${messageStatus === MESSAGE_STATUS.SENT ? "unread-message" : messageStatus === MESSAGE_STATUS.DRAFT ? "draft-dark" : "read-message"}`}/></span>
                </p>
            </div>
        </div>
    )
}

export default MessageItem;