import React from "react";
import {IModelExplorerType} from "../../../shared/interfaces/IModelExplorerType";
import {useNavigate} from "react-router-dom";
import {useWorkSpace} from "../../../context/WorkSpaceContext";

const ModelExplorerType = ({id, subject, description}: IModelExplorerType) => {

    const {workSpace} = useWorkSpace();
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/workspace/${workSpace?.uuid}/model-composer`)
    }

    return (
        <div className="mt-4 bg-light-200 border border-light-100 p-6">
            <p className="py-1 text-dark">{subject}</p>
            <div className="flex justify-between mt-4 gap-x-16">
                <p className="text-dark-100 text-sm text-justify">
                    {description}
                </p>
                <button onClick={handleClick} className="btn btn-outline btn-primary px-8 rounded font-medium">Select</button>
            </div>
        </div>
    )
}

export default ModelExplorerType;