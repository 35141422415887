import ActionBar from "../../../components/ActionBar";
import DescriptionPage from "../../../components/SettingSidebar/DescriptionPage";
import React from "react";
import Form from "./Form";
import {createUserOrg} from "../../../services";
import {useError} from "../../../context/ErrorContext";
import {useNavigate} from "react-router-dom";
import {UserValuesType} from "../../../shared/formValidator/userFormValidator";
import {useAuth} from "../../../context/AuthContext";

const AddUser = () => {

    const {setError} = useError();
    const navigate = useNavigate();
    const {user} = useAuth();

    const onSubmit = async (values: UserValuesType) => {

        const {user_role, user_name_field, password_field, ...rest} = values;

        const formValues = {
            first_name: values.first_name?.trim(),
            last_name: values.last_name?.trim(),
            phone: values.phone?.trim(),
            email: values.email?.trim(),
            team_uuids: values.teams?.map(item => item.value),
            workspace_uuids: values.workspaces?.map(item => item.value),
            organization_uuid: user.organization_uuid,
            role_uuids: values.user_role?.map(item => item.value),
            password: values.password_field.trim(),
            user_name: values.user_name_field.trim(),
            is_active: values.is_active,
        }

        try {
            await createUserOrg(formValues);
            navigate('/setting/users')
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    return (
        <div className="flex flex-col gap-x-1 gap-y-1 w-full">
            <div className="flex flex-col flex-1 gap-y-1">
                <ActionBar goBack={true} pageTitle="Users" topic="user" isShow={false}/>
            </div>
            <div className="flex h-[calc(100vh-133px)] gap-x-1">
                <DescriptionPage>
                    <p>add / edit users and team belongs to it</p>
                </DescriptionPage>
                <section className="h-full flex flex-col p-6 bg-white shadow-sm flex-1 overflow-y-auto">
                    <div className="border border-light-100 rounded-lg p-6 grid grid-cols-3">
                        <Form onSubmit={onSubmit}/>
                    </div>
                </section>
            </div>
        </div>
    )
}


export default AddUser;