import React from "react";
import Modal from "../Modal";

type ModalImportProps = {
    isOpen: boolean,
    handleClose: () => void,
    content: string
}
const ModalImport = ({isOpen, handleClose, content}) => {
    return (
        <Modal isOpen={isOpen} onClose={handleClose} isCancelBtn={false}>
            <div dangerouslySetInnerHTML={{ __html: content}}></div>
        </Modal>
    )
}

export default ModalImport;