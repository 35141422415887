import React, {useState} from "react";
import ConversationHistory from "../Copilot/ConversationHistory";

const ModalSpecialCopilot = ({isOpen, onClose}) => {

    const [isEmptyHistory, setIsEmptyHistory] = useState<boolean>(true);

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const handleCreateNewConversation = () => {

    }

    const specialCopilotText = `Lorem ipsum is placeholder text \n commonly used in the graphic, print, and \n publishing industries for previewing layouts and visual mockups.`

    return (
        <div className={`modal ${isOpen ? "modal-open" : ""}`}>
            <div className="modal-box w-11/12 max-w-5xl rounded shadow-sm bg-white">
                <div className="relative">
                    <i className="icon icon-24 icon-close-circle absolute cursor-pointer right-3 top-3"
                       onClick={onClose}/>
                    {
                        isEmptyHistory ? (
                            <div
                                className="h-[400px] border border-lightGray-100 rounded-lg flex items-center justify-center flex-col">
                                <div className="flex flex-col gap-x-3">
                                    <h3 className="text-base text-center">Special Copilot</h3>
                                    <p className="whitespace-pre-line text-center text-sm mt-4">{specialCopilotText}</p>
                                </div>
                            </div>
                        ) : (
                            <div
                                className="h-[400px] overflow-y-auto flex-1 flex flex-col">
                                <ConversationHistory selectedConversation={[]} isLoading={false}/>
                            </div>
                        )
                    }
                </div>
                <form onSubmit={handleSubmit}
                      className="flex border-t border-lightGray-100 pt-3 items-center gap-x-4 mt-4">
                    <div className="flex-1">
                        <input type="text" className="input input-bordered border-dark-400 w-full border mt-2 rounded"/>
                    </div>
                    <div className="flex flex-col gap-y-1">
                        <button className="btn btn-primary btn-outline px-4 py-2 h-fit min-h-fit" type="button">reset
                        </button>
                        <button className="btn btn-primary px-4 py-2 h-fit min-h-fit" type="submit">submit</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ModalSpecialCopilot;