import React, {createContext, ReactNode, useContext, useState} from "react";
import {INotification} from "../shared/interfaces/INotification";

type NotificationProviderProps = {
    children?: ReactNode
}

const NotificationContext = createContext<{notifications: INotification[] | [], setNotifications: (notifications: INotification[] | []) => void}>({notifications: [], setNotifications: () => {}});

export const NotificationProvider = ({children}: NotificationProviderProps) => {
    const [notifications, setNotifications] = useState<INotification[] | []>([]);

    return (
        <NotificationContext.Provider value={{notifications, setNotifications}}>
            {children}
        </NotificationContext.Provider>
    )
}

export const useNotifications = () => useContext(NotificationContext);