import React, {useEffect, useState} from "react";
import {ISidebarMenuItem} from "../../shared/interfaces/ISidebarMenuItem";
import CollapseButtonMenuItem from "./CollapseButtonMenuItem";
import {useLocation} from "react-router-dom";
import {useDashboards} from "../../context/DashboardsContext";
import CollapseLinkMenuItem from "./CollapseLinkMenuItem";
import {useDashboard} from "../../context/DashboardContext";
import {sortDashboardsMasterData, sortDashboardsProcess, sortDashboardsStatistics} from "../../shared/utils/helper";

interface SecondSidebarMenuItemProps extends ISidebarMenuItem {
    items?: ISidebarMenuItem[],
    slug?: string | null,
    isExpanded: boolean
}

const CollapseMenu = ({id, icon, title, items, isExpanded, slug}: SecondSidebarMenuItemProps) => {

    const {dashboards} = useDashboards();
    const {dashboard} = useDashboard();
    const {pathname} = useLocation();
    const [isOpen, setIsOpen] = useState(pathname.split('/')[5] === slug);

    const handleClick = (e) => {
        e.preventDefault();
        setIsOpen(prev => !prev);
    }

    useEffect(() => {
        setIsOpen(pathname.split('/')[5] === slug);
    }, [pathname]);

    const getNavLinkClass = () => {
        return pathname.split('/')[5] === slug
            ? "text-blue"
            : "";
    };

    return (
        <div tabIndex={0}
             className={`collapse collapse-arrow rounded-box ${isOpen ? "collapse-open" : "collapse-close"}`}>
            <div
                className={`collapse-title py-3 pl-3 flex items-center text-sm cursor-pointer whitespace-nowrap ${getNavLinkClass()}`}
                onClick={handleClick} title={title}>
                <i className={`icon icon-24 icon-${icon}`}/>
                <span className="ml-3">{title}</span>
            </div>
            <div className={`collapse-content pt-0 pr-0 ${isOpen ? "overflow-visible pb-4" : ""}`}>
                <ul className="collapse-menu flex list-none flex-col">
                    {
                        title === "Statistics" ? (
                            <>
                                {
                                    dashboards.length > 0 && sortDashboardsStatistics(dashboards).map(item => {
                                        return <CollapseButtonMenuItem {...item} key={item.id} isExpanded={isExpanded} parentItem="statistics"
                                                                       isActive={pathname.split('/')[5] === slug && dashboard?.title === item.title}/>
                                    })
                                }
                            </>
                        ) : title === "Master Data" ? (
                            <>
                                {
                                    dashboards.length > 0 && sortDashboardsMasterData(dashboards).map(item => {
                                        return <CollapseButtonMenuItem {...item} key={item.id} isExpanded={isExpanded} parentItem="master-data"
                                                                       isActive={pathname.split('/')[5] === slug && dashboard?.title === item.title}/>
                                    })
                                }
                            </>
                        ) : title === "Processes" ? (
                            <>
                                {
                                    dashboards.length > 0 && sortDashboardsProcess(dashboards).map(item => {
                                        return <CollapseButtonMenuItem {...item} key={item.id} isExpanded={isExpanded} parentItem="processes"
                                                                       isActive={pathname.split('/')[5] === slug && dashboard?.title === item.title}/>
                                    })
                                }
                            </>
                            ) : (
                            <>
                                {
                                    items && items.length > 0 && items.map(item => {
                                        return (
                                            <CollapseLinkMenuItem {...item} key={item.id} isExpanded={isExpanded}/>
                                        )
                                    })
                                }
                            </>
                        )
                    }
                </ul>
            </div>
        </div>
    )
}


export default CollapseMenu