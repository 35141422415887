import React, {useEffect, useRef, useState} from "react";
import ActionBar from "../../components/ActionBar";
import ImportDataType from "../../components/SelectorDataType";
import data from "../../mockData/data.json";
import {analytics, modelRunAnalytics} from "../../services";
import FormGenerator from "../../components/FormGenerator";
import {useNavigate, useParams} from "react-router-dom";
import {useError} from "../../context/ErrorContext";

const RunAnalytics = () => {

    const {ResultModelDataTypes} = data;
    const [formProperties, setFormProperties] = useState<{ schema: object, rules?: object[] }>();
    const modelNameRef = useRef<HTMLInputElement>(null);
    const [selectedDataTypeId, setSelectedDataTypeId] = useState<number>(1)
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const navigate = useNavigate();
    const {workspaceUUId, modelUUId} = useParams();

    const {setError} = useError();

    useEffect(() => {
        modelNameRef?.current?.focus();
    }, [])

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        try {
            const result = await analytics("1");
            setFormProperties({schema: {properties: result.properties, type: "object"}, rules: result.rules});
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    const handleChange = (e, id) => {
        setSelectedDataTypeId(id);
    }

    const handleCancel = () => {
        navigate(-1)
    }

    const handleSubmit = async (values) => {
        setIsLoading(true);
        const {analytics_uuid, title, ...rest} = values.formData;

        const formValues = {
            title: values.formData.title,
            description: "",
            type_id: 1,
            base_model_uuid: modelUUId,
            analytics_id: values.formData.analytics_id,
            workspace_uuid: workspaceUUId,
            model_parameter: rest,
            folder_name: ""
        }
        try {
            const data = await modelRunAnalytics(formValues);
            setIsLoading(false);
            navigate(`/workspace/${workspaceUUId}/execution-progress/detail/${data.execution_item_uuid}`)
        } catch (error: any) {
            setIsLoading(false);
            setError({message: error.response.data.detail, type: 'error'});
        }
        setIsLoading(false);
    }

    return (
        <div className="flex flex-col gap-x-1 gap-y-1 mt-[2px]">
            <div className="flex flex-col flex-1 gap-y-1">
                <ActionBar pageTitle="Run Analytics" goBack={true} topic="run-analytics"/>
            </div>
            <div className="flex h-[calc(100vh-133px)] gap-x-1">
                <ImportDataType data={ResultModelDataTypes} selectedItem={selectedDataTypeId} onChange={handleChange}/>
                <div className="flex flex-col flex-1 bg-white shadow-sm p-6 gap-y-6 overflow-y-auto">
                    <div className="flex-1 border border-lightGray-100 rounded-xl py-6 px-4">
                        <FormGenerator formData={formProperties?.schema || {}} rules={formProperties?.rules}
                                       onSubmit={handleSubmit} onCancel={handleCancel} isLoading={isLoading}/>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RunAnalytics;