import React, {useRef, useState} from "react";
import ReCAPTCHA from "react-google-recaptcha";
import Loading from "../../components/Loading";
import {forgetPassword, verifyCaptcha} from "../../services";
import {useError} from "../../context/ErrorContext";
import {Link} from "react-router-dom";

const ForgetPassword = () => {

    const [formValues, setFormValues] = useState({email: ""});
    const [isCaptchaToken, setIsCaptchaToken] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const recaptcha = useRef<ReCAPTCHA>(null);

    const {setError} = useError();

    const handleChangeInput = e => {
        setFormValues({...formValues, [e.target.name]: e.target.value});
    }

    const handleChangeCaptcha = (token) => {
        if (token && token !== "") setIsCaptchaToken(true)
        else setIsCaptchaToken(false)
    }

    const handleSubmit = async e => {
        setIsLoading(true);
        e.preventDefault();
        const captchaValue = recaptcha.current?.getValue();
        if (captchaValue) {
            try {
                const data = await verifyCaptcha(captchaValue);
                if (data.status) {
                    await handleForgetPassword()
                }
            } catch (error: any) {
                setIsLoading(false)
                setError({message: error.response.data.detail, type: 'error'})
            }
        }
        else {
            setIsLoading(false)
            setError({message: "Please verify the reCAPTCHA!", type: 'error'})
        }
    }

    const handleForgetPassword = async () => {
        setIsLoading(true)
        if (formValues.email !== "" && isCaptchaToken) {
            try {
                await forgetPassword(formValues.email.trim());
                setIsLoading(false);
                setError({message: 'Check your inbox for password reset instructions.', type: 'success'})
            } catch (error: any) {
                setIsLoading(false)
                recaptcha.current?.reset();
                setIsCaptchaToken(false);
                setError({message: error?.response?.data?.detail, type: 'error'});
            }
        }
    }

    return (
        <div className="w-full h-screen mx-auto bg-white">
            <div className="flex items-center justify-center w-full h-screen">
                <div
                    className="flex-grow-0 flex-shrink-0 flex flex-col items-center justify-center h-screen w-4/5 md:w-1/2 z-10">
                    <div className="border border-rounded border-lightGray-100 px-4 py-6 rounded min-w-[400px]">
                        <h3 className="text-center text-dark text-xl">Forget Password</h3>
                        <form onSubmit={handleSubmit}
                              className="flex flex-col">
                            <div className="form-control w-full">
                                <label className="label">Email:</label>
                                <input type="email" name="email" value={formValues.email}
                                       onChange={handleChangeInput}
                                       className="input input-bordered w-full bg-white"/>
                            </div>
                            <div className="mt-2">
                                <Link to="/sign-in" className="" type="button">
                                    Sign In
                                </Link>
                            </div>
                            <div className="flex justify-center mt-2">
                                <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY || ""}
                                           onChange={handleChangeCaptcha}/>
                            </div>
                            <button type="submit"
                                    className={`btn btn-primary mt-3 ml-auto w-[140px] ${(formValues.email !== "" && isCaptchaToken) ? "" : "btn-disabled"}`}>
                                {
                                    isLoading ? <Loading/> : "Send Request"
                                }
                            </button>
                        </form>
                    </div>
                </div>
                <div className="flex-1 p-0 m-0 z-0 h-screen hidden md:block">
                    <img src="/images/sign-in-image.png" alt="" className="h-full w-full"/>
                </div>
            </div>
        </div>
    )
}

export default ForgetPassword;