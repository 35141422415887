import React, {createContext, ReactNode, useContext, useState} from "react";

type ExpandedSidebarProviderProps = {
    children?: ReactNode
}

export const ExpandedSidebarContext = createContext<{expandedSidebar : boolean, setExpandedSidebar: (expandedSidebar: (prevState) => boolean) => void }>({expandedSidebar: false, setExpandedSidebar: () => {}});


export const ExpandedSidebarProvider = ({children}: ExpandedSidebarProviderProps) => {

    const [expandedSidebar, setExpandedSidebar] = useState<boolean>(true)

    return (
        <ExpandedSidebarContext.Provider value={{expandedSidebar, setExpandedSidebar}}>
            {children}
        </ExpandedSidebarContext.Provider>
    )
}


export const useExpandedSidebar = () => useContext(ExpandedSidebarContext);