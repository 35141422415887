import React, {useEffect, useState} from "react";
import ActionBar from "../../../components/ActionBar";
import {loadSuperset, sortDashboardsMasterData} from "../../../shared/utils/helper";
import {useNavigate, useParams} from "react-router-dom";
import {useDashboards} from "../../../context/DashboardsContext";
import {useDashboard} from "../../../context/DashboardContext";
import {IDashboard} from "../../../shared/interfaces/IDashboard";

const MasterData = () => {

    const navigate = useNavigate();
    const {dashboards} = useDashboards();
    const [masterDataDashboards, setMasterDataDashboards] = useState<IDashboard[] | []>([])
    const {dashboard , setDashboard} = useDashboard();
    const {workspaceUUId, modelUUId} = useParams();

    const {title} = useParams();

    const handleClick = () => {
        console.log('clicked')
    }

    const handleRunAnalytics = () => navigate(`/workspace/${workspaceUUId}/model-explorer/${modelUUId}/run-analytics`)

    const buttons = [
        // {
        //     icon: "copy",
        //     title: "Clone",
        //     action: handleClick
        // },
        // {
        //     icon: "scan",
        //     title: "Scan",
        //     action: handleClick
        // },
        {
            icon: "run",
            title: "Run Analytics",
            action: handleRunAnalytics
        },
        // {
        //     icon: "tracker",
        //     title: "Model Tracker",
        //     action: handleClick
        // },
        // {
        //     icon: "delete",
        //     title: "Delete",
        //     action: handleClick
        // }
    ]

    useEffect(() => {
        if (dashboard?.superset_dashboard_id) {
            loadSuperset(dashboard.superset_dashboard_id);
        }
    }, [dashboard])

    useEffect(() => {
        if (masterDataDashboards.length > 0) {
            const selectedDashboard = masterDataDashboards.find(item => item.title.toLowerCase() === title?.toLowerCase());
            if (selectedDashboard) {
                setDashboard(selectedDashboard)
            } else if (!title){
                setDashboard(masterDataDashboards[0]);
                navigate(`${masterDataDashboards[0].title.toLowerCase()}`, {replace: true})
            }
        }
    }, [masterDataDashboards]);

    useEffect(() => {
        if (dashboards.length > 0) {
            setMasterDataDashboards(sortDashboardsMasterData(dashboards))
        }
    }, [dashboards]);

    return (
        <>
            <ActionBar pageTitle={dashboard?.title} buttons={buttons} topic="dashboard"/>
            <div className="shadow-sm bg-white flex-1 flex flex-col py-3 px-6">
                <div id="superset-container" className="h-full w-full"></div>
            </div>
        </>
    )
}

export default MasterData