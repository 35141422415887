import React, {useEffect, useRef} from "react";
import {useSupport} from "../../context/SupportContext";
import {Link, useLocation} from "react-router-dom";
import Input from "../Input/input";
import {useFormik} from "formik";
import {supportSchema, supportValue} from "../../shared/formValidator/supportFormValidator";
import Select from "../Select";
import TextArea from "../TextArea";
import {useWorkSpace} from "../../context/WorkSpaceContext";
import {useTicket} from "../../shared/hooks/useTicket";

const SupportCard = () => {

    const {support, setSupport} = useSupport();
    const {workSpace} = useWorkSpace();
    const {pathname} = useLocation();
    const {subjectList, priorityList, loadSubjects, loadPriority, handleSubmitTicket} = useTicket();
    const supportCardRef = useRef<HTMLDivElement>(null)

    const onClose = () => {
        setSupport({isOpen: false, topic: ''});
        formik.resetForm()
    }

    const onSubmit = async (values) => {
        await handleSubmitTicket(values, formik.resetForm, "")
    }

    const formik = useFormik({
        initialValues: supportValue,
        validationSchema: supportSchema,
        onSubmit: onSubmit,
        validateOnBlur: true,
        validateOnMount: true,
        enableReinitialize: true,
        validateOnChange: true,
        isInitialValid: false
    })

    useEffect(() => {
        loadPriority();
        loadSubjects();
    },[])

    useEffect(() => {
        setSupport({isOpen: false, topic: ''})
    }, [pathname]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, []);

    const handleClickOutside = event => {
        if (supportCardRef.current && !supportCardRef.current.contains(event.target) && event.target.tagName !== 'I' && event.target.className !== "icon icon-24 icon-comment") {
            onClose()
        }
    }

    return (
        <div ref={supportCardRef}
            className={`h-[calc(100%-64px)] max-h-[calc(100%-64px)] overflow-y-auto w-[360px] transition-all shadow-sm bg-white absolute p-4 top-[64px] bottom-0 ${support.isOpen ? "right-0" : "right-[-360px]"} `}>
            <h3 className="border-b border-lightGray-200 bg-white flex items-center justify-between">
                <span>Support</span>
                <Link to={`/workspace/${workSpace?.uuid}/support`}>
                    <span>Support Center</span>
                    <i className="icon icon-24 icon-backward rotate-180"/>
                </Link>
            </h3>

            <form className="mt-4" onSubmit={formik.handleSubmit}>
                <Input
                    label="Title"
                    formik={formik}
                    name="title"
                    type="text"
                    required={true}
                />
                <Select
                    label="Subject"
                    options={subjectList}
                    value={formik.values.subject}
                    onChange={value => formik.setFieldValue("subject", value)}
                    placeholder="Select An Item ..."
                    required={true}
                    searchable={true}
                />
                <Select
                    label="Priority"
                    options={priorityList}
                    value={formik.values.priority}
                    onChange={value => formik.setFieldValue("priority", value)}
                    placeholder="Select An Item..."
                    required={true}
                />
                <TextArea name="body" formik={formik} value={formik.values.body} onChange={formik?.handleChange}
                          label="Message" placeholder="Write Your Mesasge..." required={true}/>
                <div className="flex justify-end gap-x-4">
                    <button className="btn btn-primary btn-outline" type="button" onClick={onClose}>Cancel</button>
                    <button className={`btn btn-primary ${formik.isValid ? "" : "btn-disabled"}`} type="submit">Submit</button>
                </div>
            </form>
        </div>
    )
}

export default SupportCard;