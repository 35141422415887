import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {useWorkSpace} from "../../context/WorkSpaceContext";
import {IWorkSpace} from "../../shared/interfaces/IWorkSpace";
import {renderDate} from "../../shared/utils/helper";

type WorkspaceItemProps = {
    onOpenDeleteModal: (e) => void,
    workspace: IWorkSpace,
    onSelect: (workspace: IWorkSpace) => void
}

const WorkspaceItem = ({onSelect, workspace, onOpenDeleteModal}: WorkspaceItemProps) => {

    const [isShowMenu, setIsShowMenu] = useState<boolean>(false);
    const contextMenuRef = useRef<HTMLDivElement>(null);
    const {setWorkSpace} = useWorkSpace();
    const navigate = useNavigate();

    const handleWorkspace = () => {
        setWorkSpace(workspace);
        navigate(`/workspace/${workspace.uuid}/model-explorer`);
    }

    useEffect(() => {
        window.addEventListener('mousedown', handleClickOutSide);

        return () => {
            window.removeEventListener('mousedown', handleClickOutSide);
        }
    }, [])

    const handleClickOutSide = (e) => {
        if (contextMenuRef?.current && !contextMenuRef.current.contains(e.target)) {
            setIsShowMenu(false)
        }
    }

    const handleClick = (e) => {
        e.stopPropagation();
        setIsShowMenu(prevState => !prevState);
    }

    const handleOpenDeleteModal = (e) => {
        onSelect(workspace)
        setIsShowMenu(false);
        onOpenDeleteModal(e)
    }

    return (
        <>
            <div className="rounded shadow cursor-pointer" onClick={handleWorkspace}>
                <div className="border-b border-lightGray-100 py-2 pl-4 pr-2 flex justify-between relative">
                    <span className="w-[calc(100%-24px)] truncate">{workspace.title}</span>
                    <i className="icon icon-24 icon-more" onClick={handleClick}/>
                    <div className={`context-menu right-0 !left-auto z-10 ${isShowMenu ? "block" : "hidden"}`}
                         ref={contextMenuRef}>
                        <ul className="menu bg-white shadow-md w-56 rounded gap-y-[2px] cursor-default">
                            {/*<li className="py-3 text-left px-4 hover:bg-blue-100 cursor-pointer" onClick={(e) => e.stopPropagation()}>Statistics</li>*/}
                            <li className="py-3 text-left px-4 hover:bg-blue-100 cursor-pointer"
                                onClick={handleWorkspace}>Preview Dashboard
                            </li>
                            {/*<li className="py-3 text-left px-4 hover:bg-blue-100 cursor-pointer" onClick={(e) => e.stopPropagation()}>Deploy Dashboard</li>*/}
                            <li className="py-3 text-left px-4 hover:bg-blue-100 cursor-pointer"
                                onClick={handleOpenDeleteModal}>Delete Workspace
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="flex flex-col gap-y-2 p-4">
                    <p className="workspace-description"><strong>Description: </strong>{workspace.description}</p>
                    {/*<p><strong>Data Period: </strong>Jan 2022 to Dec 2022</p>*/}
                    <p><strong>Last Modified On: </strong>{renderDate(workspace.created_at)}</p>
                </div>
            </div>
        </>
    )
}

export default WorkspaceItem