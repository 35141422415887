import React, {useEffect, useState} from "react";
import {useError} from "../../context/ErrorContext";
import {airFlowStatus, dataImportReport, integration} from "../../services";
import {FAILED, SUCCESS} from "../../shared/constant";
import DataReportItem from "./DataReportItem";
import {isNullOrUndefinedOrEmpty, renderDataReport} from "../../shared/utils/helper";


type IntegrationProps = {
    onNext: (key: number) => void,
    selectedTab: number,
    dataImportId: string,
    onLoadReports: (phaseName: string, metricName: string, count: string, displayName: string) => void,
    selectedDataModel?: {
        data_type_name: string,
        data_type_uuid: string,
        is_required: boolean
    } | null,
    setStatusAirFlow: (value: boolean) => void,
    statusAirFlow: boolean,
    selectedDataImportStatus: {
        [key: string]: { status: string, dag_id: string, dag_run_id: string }
    } | null
}

let intervalCheckStatus: NodeJS.Timer;
const Integration = ({
                         onNext,
                         selectedTab,
                         dataImportId,
                         onLoadReports,
                         selectedDataModel,
                         statusAirFlow,
                         setStatusAirFlow,
                         selectedDataImportStatus
                     }: IntegrationProps) => {

    const [progress, setProgress] = useState<number>(0);
    const [dataIntegration, setDataIntegration] = useState<{ dag_id: string, dag_run_id: string } | null>(null)
    const [dataIntegrationReport, setDataIntegrationReport] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isFailed, setIsFailed] = useState<boolean>(false);

    const {setError} = useError();

    const handleClick = () => {
        onNext(4)
    }

    useEffect(() => {
        if (selectedTab === 3 && dataImportId && selectedDataImportStatus && selectedDataImportStatus['data_integration']) {
            setDataIntegration({dag_id: selectedDataImportStatus['data_integration'].dag_id, dag_run_id: selectedDataImportStatus['data_integration'].dag_run_id})
        } else if (selectedTab === 3 && isNullOrUndefinedOrEmpty(dataIntegration) && dataImportId) {
            loadIntegration()
        }
    }, [selectedTab]);

    const loadIntegration = async () => {
        try {
            const values = {
                data_import_uuid: dataImportId
            }
            const data = await integration(values);
            if (data?.dag_id && data?.dag_run_id) {
                setDataIntegration(data);
            } else {
                loadDataImportReport()
            }
        } catch (error: any) {
            setIsFailed(true);
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    useEffect(() => {
        return () => {
            clearInterval(intervalCheckStatus);
        }
    },[])

    useEffect(() => {
        if (dataIntegration) {
            intervalCheckStatus = setInterval(() => {
                handleCheckStatusAirFlow()
            }, 5000)
        }
    }, [dataIntegration]);

    const handleCheckStatusAirFlow = async () => {
        try {
            const values = {
                dataImportId,
                dagaId: dataIntegration?.dag_id,
                runId: dataIntegration?.dag_run_id
            }
            const data = await airFlowStatus(values)
            if (data.status === SUCCESS) {
                setStatusAirFlow(true);
                clearInterval(intervalCheckStatus);
                loadDataImportReport()
            } else if (data.status === FAILED) {
                clearInterval(intervalCheckStatus);
                setIsFailed(true);
                setIsLoading(false)
                setError({
                    message: 'There was an error while importing the data model. To resolve this, please ensure that it meets the specified format requirements.',
                    type: 'error'
                });
            }
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    const loadDataImportReport = async () => {
        try {
            const values = {
                dataImportId,
                phaseName: "data_integration"
            }
            const data = await dataImportReport(values);
            setDataIntegrationReport(data);
            setStatusAirFlow(true);
            setIsLoading(false);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    useEffect(() => {
        setDataIntegrationReport(null);
        setDataIntegration(null);
        setStatusAirFlow(false);
        setIsLoading(true);
        clearInterval(intervalCheckStatus);
        setIsFailed(false);
    }, [selectedDataModel, dataImportId]);

    return (
        <div
            className="flex flex-col flex-1 border border-lightGray-100 p-6 rounded relative h-full">
            <div className="h-[calc(100%-48px)] overflow-y-auto">
                <div className="w-full bg-lightGray-100 h-1 absolute left-0 top-0 right-0">
                    <div className="bg-green-200 h-1" style={{width: `${progress}%`}}></div>
                </div>
                <div className="">
                    <div><strong>Conflicts & Resolutions:</strong></div>
                    <ul className="flex flex-col gap-y-3 mt-2">
                        <li className="flex items-center gap-x-2">
                            <DataReportItem
                                isLoading={isLoading}
                                icon={isFailed ? "failed" : "danger"}
                                iconTitle="View Records Details"
                                title="Inconsistent Data Detected: "
                                data={dataIntegrationReport ? renderDataReport(dataIntegrationReport, "data_integration", "number_of_conflicted_data") : null}
                                onClick={() => onLoadReports('data_integration', 'number_of_conflicted_data', '10', 'inconsistent')}
                            />
                            {/*<i className="icon icon-24 icon-config cursor-pointer" title="Resolve Conflicts"/>*/}
                        </li>
                    </ul>
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    className={`btn btn-primary w-fit px-4 ${statusAirFlow ? "" : "btn-disabled"}`}
                    onClick={handleClick}>Next
                </button>
            </div>
        </div>
    )
}

export default Integration;