import {embedDashboard} from "@superset-ui/embedded-sdk";
import {supersetGuestToken} from "../../services";
import {IDashboard} from "../interfaces/IDashboard";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import CryptoJS from "crypto-js";

dayjs.extend(utc);
dayjs.extend(timezone);
export const isNullOrUndefined = (value: any): boolean => {

    return value === null || value === undefined;
};
export const isNullOrUndefinedOrEmpty = (value: any): boolean => {
    return isNullOrUndefined(value) || value === '' || Object.keys(value).length === 0;
};

export const loadSuperset = (dashboardId: string) => {
    embedDashboard({
        id: dashboardId,
        supersetDomain: process.env.REACT_APP_SUPERSET_URL!,
        mountPoint: document.getElementById("superset-container") as HTMLElement,
        fetchGuestToken: () => fetchGuestTokenFromBackend(dashboardId),
        dashboardUiConfig: {
            hideTitle: true,
        },
    });
}

const fetchGuestTokenFromBackend = async (dashboardId: string) => {
    const values = [dashboardId]
    try {
        return await supersetGuestToken(values);
    } catch (ex) {
        console.log(ex);
    }
}


export const generateTableColumns = (values) => {
    return Object.keys(values)?.map(item => {
        return (
            {key: item, title: item}
        )
    })
}

export const renderDataReport = (data, section, title) => {
    const value = data[section].find(item => item.metric_name === title);
    return value ? value.metric_value.value : null;
}

export const renderDataReportPercents = (data, section, title) => {
    const value = data[section].find(item => item.metric_name === title);
    return value ? `(${value.metric_value.percent.toFixed(2)}%)` : "";
}

export const sortDashboardsStatistics = (dashboards: IDashboard[]) => {
    const primaryDashboards = ["Overview", "Service", "Supply&Demand", "Network", "Utilization", "Inventory", "Finance", "Sustainability", "Resilience", "Needs Assessment", "Demand Planning"];

    const sortDashboardArray: IDashboard[] = [];

    primaryDashboards.map(item => {
        const existDashboard = dashboards.find(el => el.title === item);
        if (existDashboard) {
            sortDashboardArray.push(existDashboard);
        }
    })

    return sortDashboardArray
}

export const sortDashboardsMasterData = (dashboards: IDashboard[]) => {
    const primaryDashboards = ["Factory", "Customer", "Facility", "Supplier", "Product", "Warehouse"];

    const sortDashboardArray: IDashboard[] = [];

    primaryDashboards.map(item => {
        const existDashboard = dashboards.find(el => el.title === item);
        if (existDashboard) {
            sortDashboardArray.push(existDashboard);
        }
    })

    return sortDashboardArray
}

export const sortDashboardsProcess = (dashboards: IDashboard[]) => {
    const primaryDashboards = ["Shipment", "Sales Order", "Purchase Order", "Production Order", "Production", "Inbound Handling", "Outbound Handling", "Replenishment"];

    const sortDashboardArray: IDashboard[] = [];

    primaryDashboards.map(item => {
        const existDashboard = dashboards.find(el => el.title === item);
        if (existDashboard) {
            sortDashboardArray.push(existDashboard);
        }
    })

    return sortDashboardArray
}


export const renderDate = (date) => {
    return dayjs.utc(date).tz(dayjs.tz.guess()).format('YYYY-MM-DD HH:mm:ss')
}

export const encryptData = (data1, data2) => {

    const passKey = process.env.ENCRYPT_PASS_KEY!

    const key = CryptoJS.enc.Base64.parse(passKey);
    // Generate a random IV
    const iv = CryptoJS.lib.WordArray.random(16);
    // Encrypt the data

    const encrypted1 = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(data1),
        key,
        {iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7}
    );

    const cipherText1 = encrypted1.ciphertext.toString(CryptoJS.enc.Base64);//CryptoJS.enc.Base64.stringify(iv.concat(encrypted.ciphertext));
    const encrypted2 = CryptoJS.AES.encrypt(
        CryptoJS.enc.Utf8.parse(data2),
        key,
        {iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7}
    );

    const cipherText2 = encrypted2.ciphertext.toString(CryptoJS.enc.Base64);//CryptoJS.enc.Base64.stringify(iv.concat(encrypted.ciphertext));
    // Convert encrypted data to Base64
    return {
        iv: iv.toString(CryptoJS.enc.Base64),
        encryptedData1: cipherText1,
        encryptedData2: cipherText2,
    };
}