import Input from "../../../components/Input/input";
import MultiSelect from "../../../components/MultiSelect";
import TextArea from "../../../components/TextArea";
import React, {useEffect, useRef, useState} from "react";
import {useFormik} from "formik";
import {teamSchema, teamValues, TeamValuesType} from "../../../shared/formValidator/teamFormValidator";
import {uploadAvatar, users, workspacesByOrg} from "../../../services";
import {usersToList, workSpacesToList} from "../../../shared/utils/toList";
import {useError} from "../../../context/ErrorContext";
import {useNavigate} from "react-router-dom";
import UploadAvatar from "../../../components/Profile/UploadAvatar";
import {useAuth} from "../../../context/AuthContext";

type FormProps = {
    onSubmit: (values: TeamValuesType) => void,
    data?: TeamValuesType | null
}

const Form = ({onSubmit, data}: FormProps) => {

    const [workspaceList, setWorkspaceList] = useState<{ value: string, label: string }[] | []>([]);
    const [userList, setUserList] = useState<{ value: string, label: string }[] | []>([]);
    const {user} = useAuth();
    const inputRef = useRef<HTMLInputElement>(null);

    const {setError} = useError();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: data ? data : teamValues,
        onSubmit,
        validationSchema: teamSchema,
        validateOnChange: false,
        validateOnBlur: true,
        validateOnMount: false,
        enableReinitialize: true
    })

    useEffect(() => {
        setTimeout(() => {
            inputRef.current?.focus()
        }, 200)
    }, [])

    useEffect(() => {
        if (user && user.organization_uuid) {
            fetchData(user.organization_uuid);
        }

    }, [user]);

    const fetchData = async (organizationId: string) => {
        await Promise.all([
            loadUserList(),
            loadWorkspaceList(organizationId),
        ])
    }

    const loadUserList = async () => {
        try {
            const data = await users();
            setUserList(usersToList(data));
        } catch (error: any) {
            setError({message: error?.response?.data?.detail, type: 'error'});
        }
    }

    const loadWorkspaceList = async (organizationId: string) => {
        try {
            const data = await workspacesByOrg(organizationId);
            setWorkspaceList(workSpacesToList(data));
        } catch (error: any) {
            setError({message: error?.response?.data?.detail, type: 'error'});
        }
    }

    useEffect(() => {
        if (data && data.members && userList.length > 0) {
            let res: { value: string, label: string }[] | [] = []
            if (data?.members?.length > 0) {
                res = data.members.map(member => userList.filter(item => item?.value === member.uuid)).flat(1);
            }
            formik.setFieldValue("member_uuids", res);
        }

        if (data && data.workspace_uuids && workspaceList.length > 0) {
            let res: { value: string, label: string }[] | [] = []
            if (data?.workspace_uuids?.length > 0) {
                res = data.workspace_uuids.map(workspace => workspaceList.filter(item => item?.value === workspace)).flat(1);
            }
            formik.setFieldValue("workspaces", res);
        } else if (data && data.workspace_uuids && workspaceList.length === 0) {
            formik.setFieldValue("workspaces", []);
        }

    }, [data, userList, workspaceList])

    const onCancel = () => {
        navigate(`/setting/teams`);
    }

    const userProfile = () => {
        return ""
    }

    const handleUploadAvatar = async (e) => {
        // const formData = new FormData();
        // formData.append("avatar", e.target.files[0]);
        //
        // try {
        //     const data = await uploadAvatar(formData)
        // } catch (error: any) {
        //     setError({message: error?.response?.data?.detail, type: 'error'})
        // }
    }

    return (
        <div>
            <UploadAvatar avatar={data?.avatar_url ? data.avatar_url : null} onChange={handleUploadAvatar}
                          textAvatar={userProfile()}/>
            <form onSubmit={formik.handleSubmit} autoComplete="off">
                <Input
                    label="Title"
                    name="title"
                    type="text"
                    formik={formik}
                    required={true}
                    ref={inputRef}
                />
                <MultiSelect
                    options={userList}
                    values={formik.values.member_uuids ? formik.values.member_uuids : []}
                    onChange={values => formik.setFieldValue("member_uuids", values)}
                    placeholder="Select an item ..."
                    label="Team Members"
                    classNameWrapper="!w-full"
                    searchable={true}
                    error={formik?.errors['member_uuids'] && formik?.touched['member_uuids'] ? formik?.errors['member_uuids'] : ""}
                />
                <MultiSelect
                    options={workspaceList}
                    values={formik.values.workspaces ? formik.values.workspaces : []}
                    onChange={values => formik.setFieldValue("workspaces", values)}
                    placeholder="Select an item ..."
                    label="Workspaces"
                    classNameWrapper="!w-full"
                    error={formik?.errors['workspaces'] && formik?.touched['workspaces'] ? formik?.errors['workspaces'] : ""}
                />
                <TextArea
                    label="Description"
                    name="description"
                    rows={6}
                    formik={formik}
                />
                {
                    data ? (
                        <div className="flex gap-x-2">
                            <label className="label cursor-pointer p-0">
                                <input type="checkbox" checked={formik.values["is_active"]}
                                       onChange={formik.handleChange}
                                       name="is_active"
                                       className="checkbox checkbox-primary border-dark-400 rounded-sm"/>
                            </label>
                            <span>Active</span>
                        </div>
                    ) : null
                }
                <div className="mt-8 flex gap-x-4">
                    <button className="btn btn-outline btn-primary px-4" type="button" onClick={onCancel}>Cancel
                    </button>
                    <button className="btn btn-primary px-12" type="submit">{data ? "Save" : "Create"}</button>
                </div>
            </form>
        </div>
    )
}


export default Form;