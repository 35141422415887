import React from "react";
import LearnContent from "./LearnContent";
import {ILearnContent} from "../../shared/interfaces/ILearnContent";


type LearnTopicProps = {
    learnTopic: ILearnContent;
}
const LearnTopic = ({learnTopic} : LearnTopicProps) => {
    return (
        <div className="shadow-sm bg-white flex flex-col mt-2 pb-4">
            <h4 className="bg-lightGray-100 px-4 py-1">{learnTopic.title}</h4>
            {
                learnTopic.contents.length > 0 && learnTopic.contents.map((content, i) => {
                    return (
                        <LearnContent key={learnTopic.uuid} learnContent={content} />
                    )
                })
            }
            <a href={learnTopic.topic_link} target="_blank" className="cursor-pointer mt-3 ml-auto mr-4">
                <span>view more</span>
                <i className="icon icon-24 icon-backward rotate-180"/>
            </a>
        </div>
    )
}

export default LearnTopic;