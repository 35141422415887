import React, {useEffect, useState} from "react";
import DataReportItem from "./DataReportItem";
import {useSummary} from "../../shared/hooks/ModelComposer/useSummary";
import {renderDataReport, renderDataReportPercents} from "../../shared/utils/helper";

type SummaryProps = {
    selectedTab: number,
    dataImportId: string,
    setDataModelStatusList: (value: { analyticsDataModelName: string, status: boolean, data_import_uuid: string | null }[]) => void,
    onLoadReports: (phaseName: string, metricName: string, count: string, displayName: string) => void,
    dataModelStatusList: { analyticsDataModelName: string, status: boolean, data_import_uuid: string | null }[] | [],
    disableConfirm: boolean,
    selectedDataModel?: {
        data_type_name: string,
        data_type_uuid: string,
        is_required: boolean
    } | null,
    setSelectedDataModel: (value: {
        data_type_name: string,
        data_type_uuid: string,
        is_required: boolean
    } | null) => void,
    dataModelList: {
        data_type_name: string,
        data_type_uuid: string,
        is_required: boolean
    }[] | [],
}

const Summary = ({selectedTab, dataImportId, setDataModelStatusList, onLoadReports, dataModelStatusList, disableConfirm, selectedDataModel, setSelectedDataModel, dataModelList}: SummaryProps) => {

    const [statusDataImport, setStatusDataImport] = useState<boolean>(false);
    const {isLoading, loadSummary, handleConfirm, summaryData} = useSummary({setDataModelStatusList, dataImportId, dataModelStatusList, selectedDataModel, setSelectedDataModel, dataModelList})

    useEffect(() => {
        if (selectedTab === 4 && dataImportId) {
            loadSummary()
        }
    }, [selectedTab]);

    return (

        <div className="flex flex-col flex-1 border border-lightGray-100 p-6 rounded h-full">
            <div className="h-[calc(100%-48px)] overflow-y-auto">
                <div className="">
                    <div><strong>Data Quality Metrics:</strong></div>
                    <ul className="mt-2 flex flex-col gap-y-3">
                        <li className="flex items-center gap-x-2">
                            <DataReportItem
                                isLoading={isLoading}
                                icon="success"
                                iconTitle="View Valid Records Details"
                                title="Valid Records: "
                                descriptionRecords="Of All Records"
                                data={summaryData ? renderDataReport(summaryData, "data_validation", "number_of_valids") : null}
                                percent={summaryData ? renderDataReportPercents(summaryData, "data_validation", "number_of_valids") : null}
                                onClick={() => onLoadReports('data_validation', 'number_of_valids', '10', 'valid')}
                            />
                        </li>
                        <li className="flex items-center gap-x-2">
                            <DataReportItem
                                isLoading={isLoading}
                                icon="warning"
                                iconTitle="View Invalid Record Details"
                                title="Records Containing Invalid Data: "
                                descriptionRecords="Of All Records"
                                data={summaryData ? renderDataReport(summaryData, "data_validation", "number_of_invalids") : null}
                                percent={summaryData ? renderDataReportPercents(summaryData, "data_validation", "number_of_invalids") : null}
                                onClick={() => onLoadReports('data_validation', 'number_of_invalids', '10', 'invalid')}
                            />
                        </li>
                        <li className="flex items-center gap-x-2">
                            <DataReportItem
                                isLoading={isLoading}
                                icon="danger"
                                iconTitle="View Missing Data Details"
                                title="Records Containing Missing Data: "
                                descriptionRecords="Of Valid Records"
                                data={summaryData ? renderDataReport(summaryData, "missing_data_treatment", "number_of_missing_data") : null}
                                percent={summaryData ? renderDataReportPercents(summaryData, "missing_data_treatment", "number_of_missing_data"): null}
                                onClick={() => onLoadReports('missing_data_treatment', 'number_of_missing_data', '10', 'missing')}
                            />
                        </li>
                    </ul>
                </div>
                <div className="mt-6">
                    <div><strong>Integration Overview:</strong></div>
                    <ul className="mt-2 flex flex-col gap-y-3">
                        <li className="flex items-center gap-x-2">
                            <DataReportItem
                                isLoading={isLoading}
                                icon="danger"
                                iconTitle="View Conflict Details"
                                title="Records Containing Inconsistent Data: "
                                descriptionRecords="Of Valid And Non-Missing Records"
                                data={summaryData ? renderDataReport(summaryData, "data_integration", "number_of_conflicted_data") : null}
                                percent={summaryData ? renderDataReportPercents(summaryData, "data_integration", "number_of_conflicted_data") : null}
                                onClick={() => onLoadReports('data_integration', 'number_of_conflicted_data', '10', 'inconsistent')}
                            />
                        </li>
                    </ul>
                </div>
            </div>
            <div className="flex justify-end">
                <button
                    className={`btn btn-primary w-fit px-4 ${summaryData && !disableConfirm? "" : "btn-disabled"}`}
                    onClick={handleConfirm}>Confirm
                </button>
            </div>
        </div>
    )
}

export default Summary;