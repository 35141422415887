import React from "react";
import TableHeader from "./TableHeader";
import TableRow from "./TableRow";

type TableProps<T> = {
    columns: {key: string, title: string, render?: (_: any, {}:any ) => React.ReactNode}[],
    data: T[],
    tableClassName?: string,
    tHeadClassName?: string,
}

const Table = <T extends NonNullable<unknown>>({columns, data, tableClassName = "", tHeadClassName = ""}: TableProps<T>) => {
    return (
        <div className="whitespace-nowrap overflow-x-auto">
            <table className={`table ${tableClassName} dataTable border-collapse border border-lightGray table-striped w-full`}>
                <thead className={tHeadClassName}>
                <TableHeader columns={columns} />
                </thead>
                {
                    data?.length > 0 && (<tbody>
                    <TableRow data={data} columns={columns}/>
                    </tbody>)
                }
            </table>
        </div>
    )
}


export default Table;