import React, {useEffect, useState} from "react";
import TimeAgo from "react-timeago";
import englishString from 'react-timeago/lib/language-strings/en'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import {INotification} from "../../shared/interfaces/INotification";
import {useError} from "../../context/ErrorContext";
import {markAsRead} from "../../services";
import {useNavigate} from "react-router-dom";
import {useNotificationCount} from "../../context/NotificationCountContext";
import {renderDate} from "../../shared/utils/helper";

const formatter = buildFormatter(englishString)

type NotificationItemProps = {
    onClose: () => void
}
const NotificationItem = ({
                              short_text,
                              create_time,
                              id,
                              uuid,
                              is_read,
                              title,
                              onClose,
                              action,
                              description
                          }: INotification & NotificationItemProps) => {

    const [isRead, setIsRead] = useState<boolean>(false);
    const {setError} = useError();
    const navigate = useNavigate();
    const {setNotificationCount} = useNotificationCount();

    const handleClick = async () => {
        if (!is_read) {
            try {
                await markAsRead(uuid);
                setIsRead(true);
                setNotificationCount(prevState => prevState - 1);
            } catch (error: any) {
                setError({message: error.response.data.detail, type: 'error'})
            }
        }
    }

    useEffect(() => {
        setIsRead(is_read);
    }, [is_read]);

    const handleGoToConversation = (e) => {
        e.stopPropagation();
        if (action && action?.conversation_id) {
            onClose();
            navigate(`/workspace/${action?.conversation_id.workspace_uuid}/model-explorer/${action?.conversation_id.model_uuid}/ai-copilot/ai-query`)
        }
    }
    const handleGoToModel = (e) => {
        e.stopPropagation();
        if (action && action?.model_id) {
            onClose();
            navigate(`/workspace/${action?.model_id.workspace_uuid}/model-explorer/${action?.model_id.model_uuid}/statistics`)
        }
    }

    const handleGoToExecution = (e) => {
        e.stopPropagation();
        if (action && action?.workspace_id) {
            const parameters = JSON.parse(action?.workspace_id)
            onClose();
            navigate(`/workspace/${parameters?.workspace_uuid}/execution-progress/detail/${parameters?.execution_item_uuid}`)
        }
    }

    const handleGoToUser = (e) => {
        e.stopPropagation();
        if (action && action?.user_id) {
            onClose();
            navigate('/setting/users')
        }
    }

    const handleGoToTeam = (e) => {
        e.stopPropagation();
        if (action && action?.team_id) {
            onClose();
            navigate('/setting/teams')
        }
    }

    const handleGoToDataImport = (e) => {
        e.stopPropagation();
        if (action && action?.data_import_id) {
            onClose();
            navigate(`/workspace/${action?.data_import_id.workspace_uuid}/model-composer`)
        }
    }

    const handleGoToMessage = (e) => {
        e.stopPropagation();
        if (action && action?.message_id) {
            onClose();
            navigate(`/workspace/${action?.message_id.workspace_uuid}/message`)
        }
    }

    const handleGoToWorkspace = (e) => {
        e.stopPropagation();
        if (action && action?.workspace_id) {
            onClose();
            navigate(`/workspace`)
        }
    }

    const renderButton = (action) => {
        if ("data_import_id" in action) {
            return <button className="btn btn-primary btn-sm text-sm" onClick={handleGoToDataImport}>See
                Data Import</button>
        } else if ("workspace_id" in action) {
            return <button className="btn btn-primary btn-sm text-sm" onClick={handleGoToWorkspace}>See
                Workspace</button>
        } else if ("user_id" in action) {
            return <button className="btn btn-primary btn-sm text-sm" onClick={handleGoToUser}>See
                User</button>
        } else if ("team_id" in action) {
            return <button className="btn btn-primary btn-sm text-sm" onClick={handleGoToTeam}>See
                Team</button>
        } else if ("model_id" in action) {
            return <button className="btn btn-primary btn-sm text-sm" onClick={handleGoToModel}>See
                Model</button>
        } else if ("message_id" in action) {
            return <button className="btn btn-primary btn-sm text-sm" onClick={handleGoToMessage}>See
                Message</button>
        } else if ("conversation_id" in action) {
            return <button className="btn btn-primary btn-sm text-sm" onClick={handleGoToConversation}>See
                Conversation</button>
        }
    }

    return (
        <div className="notification cursor-pointer" onClick={handleClick}>
            <div className="notification-icon">
                <i className={`icon icon-24 icon-${isRead ? "read" : "unread"}-notification`}/>
            </div>
            <div className="notification-content">
                <h2 className="text-base text-black mt-1 w-full truncate">{title}</h2>
                <p className="notification-body" title={description}>
                    {description}
                </p>
                <div className="notification-footer">
                    {
                        renderButton(action)
                    }
                    <span className="notification-time"><TimeAgo
                        date={renderDate(create_time)}
                        formatter={formatter}/></span>
                </div>
            </div>
        </div>
    )
}

export default NotificationItem;