import React, {useEffect} from 'react';
import {logout} from "../../services";
import {useError} from "../../context/ErrorContext";
import {useAuth} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import {lockOutTimerMessage} from "../constant";

type LockOutTimerProps = {
    children: React.ReactNode;
}

const timeout: number = Number(process.env.REACT_APP_TIME_LEFT);

const LockOutTimer = ({children}: LockOutTimerProps) => {

    const {setError} = useError();
    const {setUser} = useAuth();
    const navigate = useNavigate();
    let worker;

    useEffect(() => {
        worker = new Worker('lockoutTimerWorker.js');

        // Start the timer when the component mounts
        worker.postMessage({action: lockOutTimerMessage.START, newTimeout: timeout});

        // Listen for messages from the web worker
        worker.addEventListener('message', function (e) {
            const {action} = e.data;
            if (action === lockOutTimerMessage.LOGOUT) {
                onLogout();
            }
        });

        // Cleanup function to terminate the web worker when the component unmounts
        return () => {
            worker.terminate();
        };
    }, [timeout]);

    const handleActivity = () => {
        worker.postMessage({action: lockOutTimerMessage.ACTIVITY, newTimeout: timeout});
    };

    useEffect(() => {
        // Attach event listeners for user activity
        window.addEventListener('mousemove', handleActivity);
        window.addEventListener('keypress', handleActivity);
        window.addEventListener('click', handleActivity);

        return () => {
            // Cleanup: Remove event listeners
            window.removeEventListener('mousemove', handleActivity);
            window.removeEventListener('keypress', handleActivity);
            window.removeEventListener('click', handleActivity);
        };
    }, []);

    const onLogout = async () => {
        try {
            await logout();
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        } finally {
            setUser({})
            localStorage.removeItem('token');
            navigate('/sign-in');
        }
    }

    return (
        <>
            {children}
        </>
    )
}
export default LockOutTimer;