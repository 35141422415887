import React from "react";
import {ISidebarMenuItem} from "../../shared/interfaces/ISidebarMenuItem";
import {useDashboard} from "../../context/DashboardContext";
import {useDashboards} from "../../context/DashboardsContext";
import {useNavigate} from "react-router-dom";

interface CollapseMenuItemProps extends ISidebarMenuItem {
    isExpanded: boolean,
    slug: string | null,
    isActive: boolean,
    parentItem: string,
    uuid: string
}
const CollapseButtonMenuItem = ({id, title, isExpanded, slug, isActive, parentItem, uuid}: Omit<CollapseMenuItemProps, "icon" | "path">) => {

    const {setDashboard} = useDashboard()
    const {dashboards} = useDashboards();
    const navigate = useNavigate();

    const handleClick = () => {
        const selectedDashboard = dashboards.find(item => item.uuid === uuid);
        if (selectedDashboard) {
            setDashboard(selectedDashboard)
            navigate(`${parentItem}/${selectedDashboard.title.toLowerCase()}`);
        }
    }

    return (
        <button onClick={handleClick} className={`collapse-menu-item p-3 text-sm text-left rounded mt-2 first:mt-0 ${isActive ? "text-blue font-medium active" : ""} ${isExpanded && isActive ? "bg-blue-100" : ""}`} title={title}><li className="whitespace-nowrap"><i className={`icon icon-24 icon-${slug ? slug.toLowerCase() : "overview"} ${isExpanded ? "" : "bg-white"}`}/><span className="ml-3">{title}</span></li></button>
    )
}

export default CollapseButtonMenuItem