import React from "react";
import {useExecutionProgressStep} from "../../shared/hooks/useExecutionProgress";
import {useWorkSpace} from "../../context/WorkSpaceContext";
import {useNavigate} from "react-router-dom";
import Spinner from "../../components/Loading/Spinner";
import {FAILED, SUCCESS} from "../../shared/constant";
import {isNullOrUndefinedOrEmpty, renderDate} from "../../shared/utils/helper";
import {useModel} from "../../context/ModelContext";


const Detail = () => {

    const {executionProgress} = useExecutionProgressStep();
    const {workSpace} = useWorkSpace();
    const navigate = useNavigate();
    const {model, setModel} = useModel()

    const handleClick = () => {
        if (executionProgress?.execution_item && executionProgress.execution_item.name && executionProgress.execution_item.model_uuid) {
            setModel({model_status: SUCCESS, ...model, id: executionProgress.execution_item.model_id, uuid: executionProgress.execution_item.model_uuid , title: executionProgress?.execution_item.name})
            navigate(`/workspace/${workSpace?.uuid}/model-explorer/${executionProgress?.execution_item.model_uuid}/statistics`)
        }
    }

    const isDisabled = executionProgress?.execution_steps && executionProgress?.execution_steps.length > 3 && executionProgress?.execution_steps.every(item => item.status === "done")

    const generateStatus = stepName => {
        const item = executionProgress?.execution_steps.find(item => item.name === stepName)

        if (isNullOrUndefinedOrEmpty(item)) {
            return <span className="w-6 h-6 inline-block"/>
        }
        if (item?.status === "failed") {
            return <i className="icon icon-24 icon-failed"/>
        }
        if (item) {
            return item.status === "done" ? <i className="icon icon-24 icon-success"/> : <Spinner/>
        }
    }


    return (
        <div className="flex-1 gap-y-1 h-[calc(100vh-64px)] p-6 shadow bg-white overflow-y-auto">
            <div className="border border-lightGray-100 rounded-xl py-3 px-6">
                <div className="flex items-center gap-x-2">
                    <span>Execution Type:</span><span>{executionProgress?.execution_item.execution_refrence_id === 3 ? "Model Compose" : "Run Analytic"}</span>
                </div>
                <div className="flex items-center mt-2 gap-x-2">
                    <span>Output Model: </span><span>{executionProgress?.execution_item.name}</span></div>
                <div className="flex items-center mt-2 gap-x-2">
                    <span>Started At: </span><span>{renderDate(executionProgress?.execution_item?.start_datetime)}</span>
                </div>
                <div className="items-center mt-2 pb-1 border-b border-lightGray-200 inline-flex gap-x-2"><span>Finished At: </span><span>{executionProgress?.execution_item?.finish_datetime ? renderDate(executionProgress?.execution_item?.finish_datetime) : "Not Finished"}</span>
                </div>

                <div className="flex flex-col gap-y-4 text-sm mt-4">
                    {
                        executionProgress?.execution_item.execution_refrence_id === 3 ? (
                            <>
                                {
                                    executionProgress.steps_name["3"].map(stepName => {
                                        return (
                                            <div className="flex gap-x-1 items-center">
                                                {generateStatus(stepName)}
                                                <span className="capitalize ml-2">{stepName}</span>
                                            </div>
                                        )
                                    })
                                }
                            </>
                        ) : (
                            <>
                                {
                                    executionProgress?.steps_name["0"].map(stepName => {
                                        return (
                                            <div className="flex gap-x-1 items-center">
                                                {generateStatus(stepName)}
                                                <span className="capitalize ml-2">{stepName}</span>
                                            </div>
                                        )
                                    })
                                }
                            </>
                        )
                    }

                    {
                        executionProgress?.execution_steps.length === 4 && executionProgress?.execution_steps.every(item => item.status === "done") ? (
                            <div className="flex gap-x-1 items-center border-t border-lightGray-200 w-fit pt-4">
                                <i className="icon icon-24 icon-success"/>
                                <span className="capitalize ml-2">Completed Successfully</span>
                            </div>
                        ) : executionProgress?.execution_item.status === FAILED ? (
                            <div className="flex gap-x-1 items-center border-t border-lightGray-200 w-fit pt-4">
                                <i className="icon icon-24 icon-failed"/>
                                <span className="capitalize ml-2">An Error Was Encountered</span>
                            </div>
                        ) : null

                    }
                    {/*<div className="flex flex-col gap-y-2">*/}
                    {/*    <div className="flex gap-x-1 items-center">*/}
                    {/*        <i className="icon icon-24 icon-checkbox-checked"/>*/}
                    {/*        <span>step 2 done with some warnings</span>*/}
                    {/*    </div>*/}
                    {/*    <ul className="flex flex-col gap-y-1 pl-10">*/}
                    {/*        <li>warning 1</li>*/}
                    {/*        <li>warning 2</li>*/}
                    {/*        <li>warning 3</li>*/}
                    {/*        <li>warning 4</li>*/}
                    {/*    </ul>*/}
                    {/*</div>*/}
                </div>
                <div className="flex gap-x-4 mt-6 justify-end">
                    <button className={`btn btn-primary ${!isDisabled ? "btn-disabled" : ""}`} onClick={handleClick}>see
                        Model
                    </button>
                </div>
            </div>
        </div>
    )
}

export default Detail;