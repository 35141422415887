import {TreeNode} from "../../../custom_node_modules/react-organizational-chart/dist/index";
import React from "react";
import ModelNode from "./ModelNode";
import {IModelNodes} from "../../pages/Model";
import {IModel} from "../../shared/interfaces/IModel";

type treeDiagramNodeProps = {
    item: IModelNodes,
    onSelect: (model: IModel) => void,
    onOpenModal: () => void,
    isHorizontal: boolean
}

const TreeDiagramNode = ({item, onSelect, onOpenModal, isHorizontal}: treeDiagramNodeProps) => {

    const hasChildren = item.children && item.children.length !== 0;

    return (
        !hasChildren ? (
            <TreeNode isHorizontal={isHorizontal} label={<ModelNode item={item} onSelect={onSelect}  onOpenModal={onOpenModal}/>} key={item.id}/>
        ) : (
            <TreeNode isHorizontal={isHorizontal} label={<ModelNode item={item} onSelect={onSelect}  onOpenModal={onOpenModal}/>} key={item.id}>
                {
                    hasChildren && (
                        item.children.map(child => {
                            return (
                                <TreeDiagramNode isHorizontal={isHorizontal} item={child} key={child.uuid} onSelect={onSelect} onOpenModal={onOpenModal}/>
                            )
                        })
                    )
                }
            </TreeNode>
        )
    )
}

export default TreeDiagramNode;