import {array, object, string} from "yup";


export const channelValues = {
    title: "",
    resource: "",
    member_uuids: []
}


export const channelSchema = object({
    title: string().required("The Title is required.").max(25, "Title cannot be longer than 25 characters."),
    resource: string().nullable(),
    member_uuids: array().of(
        object().shape({
            value: string(),
            label: string(),
            type: string()
        })
    )
})