import {IOrganization} from "../interfaces/IOrganization";
import {boolean, object, string} from "yup";

export interface OrganizationValuesType extends IOrganization {
    country?: { value: number, label: string } | null,
    industry_type?: { value: number, label: string } | null,
    organization_admin?: { value: number, label: string, email: string } | null
}

export const organizationValues: OrganizationValuesType = {
    title: "",
    website: "",
    industry_type: null,
    country: null,
    organization_admin: null,
    is_two_fa_enable: false
};


export const organizationSchema = object({
    website: string().matches(/^(?:(?:https?:\/\/)?(?:www\.)?)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,4})(?:\/\S*)?$/, "Url must be a valid url").required("The Url is required."),
    industry_type: object().shape({value: string(), label: string()}).required("The Industry Type is Required."),
    country: object().shape({value: string(), label: string()}).required("The Country is Required."),
    organization_admin: object().shape({value: string(), label: string()}).required("The Organization Admin is required."),
    is_two_fa_enable: boolean()
})