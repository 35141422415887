import MultiSelect from "../MultiSelect";
import Modal from "../Modal";
import React, {useEffect, useState} from "react";
import {shareConversation, teams, usersMention} from "../../services";
import {teamsToList, usersToList} from "../../shared/utils/toList";
import {useError} from "../../context/ErrorContext";
import {IConversationCopilot} from "../../shared/interfaces/IConversationCopilot";
import {useWorkSpace} from "../../context/WorkSpaceContext";
import {useModel} from "../../context/ModelContext";

type ShareConversationProps = {
    isOpenModal: boolean,
    handleCloseModal: () => void,
    selectedConversation: IConversationCopilot
}

const ShareConversation = ({isOpenModal, handleCloseModal, selectedConversation}: ShareConversationProps) => {

    const [shareItems, setShareItems] = useState<{ label: string, value: string, type?: string }[]>([])
    const [selectedShareItems, setSelectedShareItems] = useState<{ label: string, value: string, type: string }[]>([])
    const {workSpace} = useWorkSpace();
    const {model} = useModel();
    const {setError} = useError();

    const handleSubmitShare = async () => {
        const values = {
            user_uuids: selectedShareItems.filter(item => item.type === 'user').map(item => item?.value),
            team_uuids: selectedShareItems.filter(item => item.type === 'team').map(item => item?.value),
            model_uuid: model?.uuid
        }

        try {
            await shareConversation(selectedConversation.conversation.uuid, values);
            handleCloseModal()
        } catch (error: any) {
            setError({message: error.response.date.detail, type: 'error'})
        }
    }

    const loadUserList = async (resource: string, workSpaceId: string) => {
        try {
            return await usersMention(resource, workSpaceId);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    const loadTeamList = async () => {
        try {
            return await teams();
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    const fetchData = async () => {
        if (workSpace) {
            const users = await loadUserList("conversation", workSpace.uuid);
            const teams = await loadTeamList();
            setShareItems([...usersToList(users), ...teamsToList(teams)]);
        }
    }

    useEffect(() => {
        if (isOpenModal) {
            fetchData()
        }
    },[isOpenModal])

    return (
        <Modal isOpen={isOpenModal} onClose={handleCloseModal} isSubmitBtn={true} onSubmit={handleSubmitShare}>
            <div className="w-1/2">
                <MultiSelect
                    options={shareItems}
                    values={selectedShareItems}
                    onChange={setSelectedShareItems}
                    placeholder="Select an item..."
                    label="Share with:"
                    classNameWrapper="!w-full"
                />

                <div className="pt-4 pb-2">
                    <h3 className="text-base">Users and teams with access:</h3>
                    <ul className="grid grid-cols-2 text-sm gap-y-3 mt-4">
                        <li className="flex items-center gap-x-4">
                            <img src="/images/avatar.png" className="rounded-full w-6 h-6"/>
                            <p>Lagrange User</p>
                        </li>
                        <li className="flex items-center gap-x-4">
                            <img src="/images/avatar.png" className="rounded-full w-6 h-6"/>
                            <p>Lagrange User</p>
                        </li>
                        <li className="flex items-center gap-x-4">
                            <img src="/images/avatar.png" className="rounded-full w-6 h-6"/>
                            <p>Lagrange User</p>
                        </li>
                    </ul>
                </div>
            </div>
        </Modal>
    )
}

export default ShareConversation;