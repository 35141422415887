import React, {useEffect, useRef, useState} from "react";
import Modal from "../Modal";
import {IConversationCopilot} from "../../shared/interfaces/IConversationCopilot";
import {useAuth} from "../../context/AuthContext";
import ConversationHistory from "../Copilot/ConversationHistory";
import Loading from "../Loading";
import {createChatItem, createConversationOnBoarding} from "../../services";
import {useWorkSpace} from "../../context/WorkSpaceContext";
import {useError} from "../../context/ErrorContext";
import data from "../../mockData/data.json";
import {useIsShowModalCopilot} from "../../context/IsShowModalCopilotContext";

const initialConversation = {
    conversation: {
        model_id: 0,
        user_id: 0,
        title: '',
        submit_date: '',
        is_active: false,
        last_modified_date: '',
        id: 0,
        uuid: ""
    },
    items: [],
}


const ModalCopilot = ({isOpen, handleClose}) => {

    const [isEmpty, setIsEmpty] = useState<boolean>(true);
    const [conversation, setConversation] = useState<IConversationCopilot>(initialConversation)
    const [userQuestion, setUserQuestion] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedButton, setSelectedButton] = useState<string>("");
    const [step, setStep] = useState<number>(0);
    const [isSameConversation, setIsSameConversation] = useState<boolean>(false);
    const [indexItems, setIndexItem] = useState<number>(1)
    const [isError, setIsError] = useState<boolean>(false);

    const firstQuestionBot = `Hello, I'm here to assist you in creating a tailored supply chain analytics model. To start, please share a bit about the business problem you're looking to address or the goals you have in mind.`

    const { conversationOnboarding : chatItems} = data
    const {workSpace} = useWorkSpace();
    const {user} = useAuth();
    const {setError} = useError();
    const {isShowModalCopilot, setIsShowModalCopilot} = useIsShowModalCopilot()
    const submitButtonRef = useRef<HTMLButtonElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const id = Date.now();

        const newConversationItem = {
            conversation: {
                model_id: 12,
                user_id: user.id,
                title: "New Conversation",
                id,
                uuid: ""
            },
            items: [
                {
                    item_type: 'assistant',
                    item_text: 'To start, please share a bit about the business problem you\'re looking to address or the goals you have in mind. Based on our conversation, I\'ll suggest the most suitable analytics approach'
                },
            ]
        }

        setConversation(newConversationItem);
    }, [])

    const handleSubmit = async (e, question: string) => {
        e.preventDefault();

        if (userQuestion !== "") {
            setIsEmpty(false);
            setIsLoading(true);
            const newMessage = {
                item_type: 'user',
                item_text: question
            }

            const tempSelectedConversation = {...conversation};
            tempSelectedConversation?.items.push(newMessage);

            setUserQuestion("");

            if (tempSelectedConversation?.items.length > 2) {
                sendMessage(userQuestion);
            } else {
                sendFirstMessage(userQuestion)
            }
        }
    }

    const sendMessage = async (question) => {
        if (isSameConversation) {
            setTimeout(() => {
                const cloneConversation = {...conversation};
                cloneConversation.items.push(chatItems[indexItems]);
                setConversation(cloneConversation)
                setIndexItem(prevState => prevState + 2);
                setIsLoading(false);
            }, 2500)
        } else {
            try {
                const values = {
                    user_query: question
                }
                const data: any = await createChatItem(conversation.conversation.uuid, values);
                const tempConversation = {...conversation};
                tempConversation.items.push(data);
                setConversation(tempConversation);
                setIsLoading(false)
            } catch (error) {
                setError({message: 'An unhandled error occurred in copilot', type: 'error'});
                setIsLoading(false);
                setIsError(true)
            }
        }
    }

    const sendFirstMessage = async (question) => {
        if (chatItems[0].item_text === question) {
            setTimeout(() => {
                setIsSameConversation(true);
                const cloneConversation = {...conversation};
                cloneConversation.items.push(chatItems[indexItems]);
                setConversation(cloneConversation)
                setIndexItem(prevState => prevState + 2);
                setIsLoading(false);
            }, 2500)
        } else {
            try {
                const values = {
                    workspace_uuid: workSpace?.uuid,
                    user_query: question
                }
                const data = await createConversationOnBoarding(values);
                data.items.push({
                    item_type: 'assistant',
                    item_text: 'To start, please share a bit about the business problem you\'re looking to address or the goals you have in mind. Based on our conversation, I\'ll suggest the most suitable analytics approach',
                    submit_date: "2023-10-10T09:14:54.080028"
                });
                // @ts-ignore
                data.items = data.items.sort((a, b) => new Date(a.submit_date) - new Date(b.submit_date))
                setConversation(data)
                setIsLoading(false);
            } catch (error: any) {
                setError({message: 'An unhandled error occurred in copilot', type: 'error'});
                setIsLoading(false);
                setIsError(true);
            }
        }
    }

    const handleButtonOption = (title: string) => {
        setSelectedButton(title);
    }

    useEffect(() => {
        if (selectedButton !== "") {
            setIsEmpty(false);
            setIsLoading(true);
            const newMessage = {
                item_type: 'user',
                item_text: selectedButton
            }

            const tempSelectedConversation = {...conversation};
            tempSelectedConversation?.items.push(newMessage);

            setUserQuestion("");

            if (tempSelectedConversation?.items.length > 2) {
                sendMessage(selectedButton);
            } else {
                sendFirstMessage(selectedButton)
            }
        }
    }, [selectedButton]);

    const handleResend = () => {
        setIsError(false);
        setIsLoading(true);
        if (conversation?.items.length > 2) {
            sendMessage(conversation.items[conversation.items.length - 1].item_text);
        } else {
            sendFirstMessage(conversation.items[conversation.items.length - 1].item_text)
        }
    }

    useEffect(() => {
        if (isOpen) setTimeout(() => {inputRef.current?.focus()},200)
    }, [isOpen]);

    return (
        <Modal isOpen={isOpen} onClose={handleClose} isCancelBtn={false}>

            {
                !isEmpty ? (
                    <div>
                        <div className="max-h-[500px] h-[400px] overflow-y-auto">
                            <ConversationHistory isLoading={isLoading} selectedConversation={conversation} selectedOption={selectedButton}
                                                 setSelectedOption={handleButtonOption} isError={isError} handleResendQuestion={handleResend}/>
                        </div>
                        <form className="mt-4" onSubmit={e => handleSubmit(e, userQuestion)}>
                            <div className="flex relative pr-2">
                                <input type="text"
                                       className="flex-1 border border-lightGray-200 focus:border-lightGray-200 outline-0 pl-3 bg-light-300 rounded py-[5px] rounded-r-none border-r-0 pr-[100px]"
                                       value={userQuestion}
                                       onChange={e => setUserQuestion(e.target.value)}/>
                                <button
                                    className={`btn btn-primary px-6 absolute w-[103px] text-sm h-7 min-h-[36px] right-0 ${isLoading ? "cursor-not-allowed" : "cursor-pointer"}`}
                                    type="submit"
                                    ref={submitButtonRef}
                                >
                                    {!isLoading ? "Submit" : <Loading/>}
                                </button>
                            </div>
                        </form>
                        <div className="flex gap-x-2 mt-2">
                            <label className="label cursor-pointer p-0">
                                <input type="checkbox" checked={!isShowModalCopilot} onChange={e => setIsShowModalCopilot(!e.target.checked)}
                                       name="is_active"
                                       className="checkbox checkbox-primary border-dark-400 rounded-sm"/>
                            </label>
                            <span>Don't Show Again</span>
                        </div>
                    </div>
                ) : (
                    <>
                        <h2 className="text-center text-base">Model Composer Copilot</h2>
                        <p className="mt-4 flex items-center justify-center gap-x-4">
                            <img src="/images/avatar-bot.png" className="w-6 h-6" alt="avatar-bot"/>
                            <span className="whitespace-pre-line">{firstQuestionBot}</span>
                        </p>
                        <form className="mt-4" onSubmit={e => handleSubmit(e, userQuestion)}>
                            <div className="flex relative pr-2">
                                <input type="text"
                                       className="flex-1 border border-lightGray-200 focus:border-lightGray-200 outline-0 pl-3 bg-light-300 rounded py-[5px] rounded-r-none border-r-0 pr-[100px]"
                                       value={userQuestion}
                                       ref={inputRef}
                                       onChange={e => setUserQuestion(e.target.value)}/>
                                <button
                                    className={`btn btn-primary px-6 absolute w-[103px] text-sm h-7 min-h-[36px] right-0 ${isLoading ? "cursor-not-allowed" : "cursor-pointer"}`}
                                    type="submit">
                                    {!isLoading ? "Submit" : <Loading/>}
                                </button>
                            </div>
                        </form>
                        <div className="flex gap-x-2 mt-2">
                            <label className="label cursor-pointer p-0">
                                <input type="checkbox" checked={!isShowModalCopilot} onChange={e => setIsShowModalCopilot(!e.target.checked)}
                                       name="is_active"
                                       className="checkbox checkbox-primary border-dark-400 rounded-sm"/>
                            </label>
                            <span>Don't Show Again</span>
                        </div>
                    </>
                )
            }
        </Modal>
    )
}

export default ModalCopilot;