import React, {useEffect, useId, useRef, useState} from "react";
import ActionBar from "../../../components/ActionBar";
import {
    conversation,
    conversations,
    createChatItem,
    createConversation,
    deleteConversation,
} from "../../../services";
import {IConversationsCopilot} from "../../../shared/interfaces/IConversationsCopilot";
import ConversationItem from "../../../components/Copilot/ConversationItem";
import EmptyConversation from "../../../components/Copilot/EmptyConversation";
import ConversationHistory from "../../../components/Copilot/ConversationHistory";
import {IConversationCopilot} from "../../../shared/interfaces/IConversationCopilot";
import {useError} from "../../../context/ErrorContext";
import {useAuth} from "../../../context/AuthContext";
import ShareConversation from "../../../components/Copilot/ShareConversation";
import QuestionForm from "../../../components/Copilot/QuestionForm";
import {useModel} from "../../../context/ModelContext";
import data from "../../../mockData/data.json";
import {useParams} from "react-router-dom";
import {isNullOrUndefinedOrEmpty} from "../../../shared/utils/helper";
import axios from "axios";

const initialConversation = {
    conversation: {
        model_id: 0,
        user_id: 0,
        title: '',
        submit_date: '',
        is_active: false,
        last_modified_date: '',
        id: 0,
        uuid: ""
    },
    items: [],
}

const AIQuery = () => {

    const [isEmptyHistory, setIsEmptyHistory] = useState<boolean>(false);
    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [selectedConversation, setSelectedConversation] = useState<IConversationCopilot>(initialConversation);
    const [conversationList, setConversationList] = useState<IConversationsCopilot[] | []>([]);
    const [userQuestion, setUserQuestion] = useState("");
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);
    const [tempConversation, setTempConversation] = useState<IConversationCopilot>(initialConversation);
    const [isSameConversation, setIsSameConversation] = useState<boolean>(false);
    const [indexSameConversation, setIndexSameConversation] = useState<number | null>(null);
    const [indexItems, setIndexItem] = useState<number>(1)
    const [selectedConversationUUId, setSelectConversationUUId] = useState<string>("")
    const inputRef = useRef<HTMLInputElement>(null);

    const {conversation: localConversation} = data;
    const {modelUUId} = useParams()
    const {user} = useAuth();
    const {setError} = useError();
    const {model} = useModel();
    const handleCloseModal = () => {
        setIsOpenModal(false);
    }

    const generateConversationUUId = useId();

    const handleOpenModal = () => {
        setIsOpenModal(true);
    }

    const handleCreateNewConversation = () => {
        if (tempConversation.conversation.id === 0) {
            const id = Date.now();
            if (model && model?.id) {
                const newConversation = {
                    id,
                    uuid: generateConversationUUId,
                    title: "New Conversation",
                    user_id: user.id,
                    model_id: model?.id,
                }

                const newConversationItem = {
                    conversation: {
                        model_id: model?.id,
                        user_id: user.id,
                        title: "New Conversation",
                        id,
                        uuid: generateConversationUUId
                    },
                    items: []
                }

                setConversationList([newConversation, ...conversationList]);
                setSelectedConversation(newConversationItem);
                setTempConversation(newConversationItem);
                setIsEmptyHistory(false);
            }
        }
    }

    const handleDeleteConversation = async () => {
        setIsSameConversation(false);
        setIndexSameConversation(null);
        setIndexItem(1)
        if (selectedConversation.conversation.id === tempConversation.conversation.id) {
            const indexConversation = conversationList.findIndex(item => item.id === selectedConversation.conversation.id);
            const tempConversations = [...conversationList];
            tempConversations.splice(indexConversation, 1);
            setConversationList(tempConversations);
            setTempConversation(initialConversation);
            setSelectedConversation(initialConversation);
        } else {
            try {
                const data = await deleteConversation(selectedConversation?.conversation?.uuid);
                loadConversationList()
                setSelectedConversation(initialConversation);
                setTempConversation(initialConversation);
            } catch (error: any) {
                setError({message: error?.response?.data?.detail, type: 'error'});
            }
        }
    }

    const buttons = [
        {
            icon: "plus-circle",
            title: "Create",
            action: handleCreateNewConversation
        },
        {
            icon: "delete",
            title: "Delete",
            action: handleDeleteConversation,
            className: selectedConversation.conversation.id !== 0 && user?.id === selectedConversation.conversation.user_id ? "" : "disabled",
        },
        {
            icon: "share",
            title: "Share",
            action: handleOpenModal,
            className: selectedConversation.conversation.id !== 0 && user?.id === selectedConversation.conversation.user_id && selectedConversation.items.length > 0 && selectedConversation.items[0]?.id ? "" : "disabled",
        },
        {
            icon: "refresh-circle",
            title: "Refresh",
            action: () => window.location.reload()
        }
    ]

    useEffect(() => {
        loadConversationList();
    }, [])

    useEffect(() => {
        setIsEmptyHistory(selectedConversation.conversation.id === 0);
        setUserQuestion("");
        setTimeout(() => {
            inputRef.current?.focus()
        }, 200)
        setIsError(false);
    }, [selectedConversation])

    const handleSelectConversation = (uuid: string) => {
        setIsError(false);
        if (tempConversation.conversation.uuid === uuid) {
            setSelectedConversation(tempConversation);
        } else {
            setSelectConversationUUId(uuid)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (userQuestion !== "") {
            setIsLoading(true);
            const newMessage = {
                item_type: 'user',
                item_text: userQuestion
            }

            const tempSelectedConversation = {...selectedConversation};
            tempSelectedConversation?.items.push(newMessage);

            setSelectedConversation(tempSelectedConversation);
            setUserQuestion("");
            if (tempConversation.conversation.id !== 0) {
                setTempConversation(tempSelectedConversation);
            }
            if (selectedConversation?.items.length > 1) {
                sendMessage(userQuestion);
            } else {
                sendFirstMessage(userQuestion)
            }
        }
    }

    const sendMessage = async (question) => {
        if (isSameConversation && indexSameConversation !== null) {
            setTimeout(() => {
                const cloneConversation = {...selectedConversation};
                cloneConversation.items.push(localConversation[indexSameConversation].items[indexItems]);
                setSelectedConversation(cloneConversation)
                setIndexItem(prevState => prevState + 2);
                setIsLoading(false);
            }, 2500)
        } else {
            try {
                const values = {
                    user_query: question
                }
                const data: any = await createChatItem(selectedConversation.conversation.uuid, values);
                const tempConversation = {...selectedConversation};
                tempConversation.items.push(data);
                setSelectedConversation(tempConversation);
                setIsLoading(false)
            } catch (error) {
                setError({message: 'An unhandled error occurred in copilot', type: 'error'});
                setIsLoading(false);
                setIsError(true)
            }
        }
    }

    const sendFirstMessage = async (question) => {
        const indexLocalConversation = localConversation.findIndex(conversationItem => conversationItem.items[0].item_text === question);
        if (indexLocalConversation !== -1) {
            setTimeout(() => {
                setIsSameConversation(true);
                setIndexSameConversation(indexLocalConversation);
                const cloneConversation = {...selectedConversation};
                cloneConversation.conversation.title = localConversation[indexLocalConversation].conversation.title;
                cloneConversation.items.push(localConversation[indexLocalConversation].items[indexItems]);
                const cloneAllConversation = [...conversationList];
                cloneAllConversation[0].title = localConversation[indexLocalConversation].conversation.title;
                setConversationList(cloneAllConversation);
                setSelectedConversation(cloneConversation)
                setIndexItem(prevState => prevState + 2);
                setIsLoading(false);
            }, 2500)
        } else {
            try {
                const values = {
                    model_uuid: model?.uuid,
                    user_query: question
                }
                let data = await createConversation(values);
                const selectedConversationIndex = conversationList.findIndex(item => item.id === selectedConversation.conversation.id);
                const tempConversationList = [...conversationList];
                const newConversation = {
                    id: data.conversation.id,
                    title: data.conversation.title,
                    user_id: data.conversation.user_id,
                    model_id: data.conversation.model_id,
                    submit_date: data.conversation.submit_date,
                    is_active: data.conversation.is_active,
                    last_modified_date: data.conversation.last_modified_date,
                    uuid: data.conversation.uuid
                }
                tempConversationList[selectedConversationIndex] = newConversation;
                setConversationList(tempConversationList);
                // @ts-ignore
                data.items = data.items.sort((a, b) => new Date(a.submit_date) - new Date(b.submit_date))
                setSelectedConversation(data);
                setTempConversation(initialConversation);
                setIsLoading(false);
            } catch (error: any) {
                setError({message: 'An unhandled error occurred in copilot', type: 'error'});
                setIsLoading(false);
                setIsError(true);
            }
        }
    }

    const loadConversationList = async () => {
        try {
            const data = await conversations(modelUUId!);
            setConversationList(data);
        } catch (error: any) {
            setError({message: error?.response?.data?.detail, type: 'error'});
        }
    }

    const loadConversation = async (uuid: string, cancel) => {
        try {
            let data: any = await conversation(uuid, cancel);
            // @ts-ignore
            data.items = data.items.sort((a, b) => new Date(a.submit_date) - new Date(b.submit_date))
            setSelectedConversation(data);
        } catch (error: any) {
            if (axios.isCancel(error)) {
                console.log('Request canceled')
            }
            setError({message: error?.response?.data?.detail, type: 'error'});
        }
    }

    useEffect(() => {
        const cancelTokenSource = axios.CancelToken.source();
        if (selectedConversationUUId !== "") {
            loadConversation(selectedConversationUUId, cancelTokenSource.token);
        }

        return () => {
            cancelTokenSource.cancel('canceled');
        }
    }, [selectedConversationUUId]);

    const handleResend = () => {
        setIsError(false);
        setIsLoading(true);
        if (selectedConversation?.items.length > 1) {
            sendMessage(selectedConversation.items[selectedConversation.items.length - 1].item_text);
        } else {
            sendFirstMessage(selectedConversation.items[selectedConversation.items.length - 1].item_text)
        }
    }

    useEffect(() => {
        if (modelUUId && !isNullOrUndefinedOrEmpty(user)) {
            loadConversationList()
        }
    }, [modelUUId, user]);

    return (
        <>
            <ActionBar pageTitle="AI Query" buttons={buttons} topic="conversation"/>
            <div className="flex-1 flex gap-x-1">
                <div className="shadow-sm h-[calc(100vh-132px)] gap-y-4 bg-white w-[265px] flex flex-col px-3 py-4">
                    <div className="h-1/2 flex flex-col overflow-hidden border-b border-b-lightGray-100">
                        <h2 className="text-sm pb-1">My Conversation:</h2>
                        <div className="flex-1 overflow-y-auto flex flex-col gap-y-2 py-2">
                            {
                                conversationList.filter(conversation => conversation.user_id === user?.id).map((conversation: IConversationsCopilot) => {
                                    return (
                                        <ConversationItem
                                            conversation={conversation}
                                            key={conversation.id}
                                            onClick={handleSelectConversation}
                                            selectedConversation={selectedConversation}
                                            selectedConversationUUId={selectedConversationUUId}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="h-1/2 flex flex-col overflow-hidden">
                        <h2 className="text-sm pb-1">Shared With Me:</h2>
                        <div className="flex-1 overflow-y-auto py-2 flex flex-col gap-y-2">
                            {
                                !isNullOrUndefinedOrEmpty(user) ? (
                                    conversationList.filter(conversation => conversation.user_id !== user?.id).map(conversation => {
                                        return (
                                            <ConversationItem
                                                conversation={conversation}
                                                selectedConversation={selectedConversation}
                                                onClick={handleSelectConversation}
                                                key={conversation.id}
                                                selectedConversationUUId={selectedConversationUUId}
                                            />
                                        )
                                    })
                                ) : null
                            }
                        </div>
                    </div>
                </div>
                <div className="flex-1 h-[calc(100vh-132px)] flex flex-col gap-y-1">
                    {
                        isEmptyHistory ? (
                            <EmptyConversation onClick={handleCreateNewConversation}/>
                        ) : (
                            <>
                                <div
                                    className="shadow-sm bg-white h-[calc(100%-68px)] overflow-y-auto flex-1 flex flex-col p-6">
                                    <ConversationHistory
                                        selectedConversation={selectedConversation}
                                        isLoading={isLoading}
                                        isError={isError}
                                        handleResendQuestion={handleResend}
                                    />
                                </div>
                                {
                                    selectedConversation.conversation.user_id === user?.id ? (
                                        <QuestionForm
                                            userQuestion={userQuestion}
                                            setUserQuestion={setUserQuestion}
                                            onSubmit={handleSubmit}
                                            isLoading={isLoading}
                                            ref={inputRef}
                                        />
                                    ) : null
                                }
                            </>
                        )
                    }
                </div>
            </div>
            <ShareConversation
                selectedConversation={selectedConversation}
                isOpenModal={isOpenModal}
                handleCloseModal={handleCloseModal}
            />
        </>
    )
}

export default AIQuery;