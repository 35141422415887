import React, {useEffect, useState} from "react";
import Spinner from "../Loading/Spinner";
import {airFlowStatus, dataImportReport, preProcessing} from "../../services";
import {useError} from "../../context/ErrorContext";
import {FAILED, SUCCESS} from "../../shared/constant";
import DataReportItem from "./DataReportItem";
import {isNullOrUndefinedOrEmpty, renderDataReport} from "../../shared/utils/helper";


type PreProcessingProps = {
    onNext: (key: number) => void,
    selectedTab: number,
    dataImportId: string,
    onLoadReports: (phaseName: string, metricName: string, count: string, displayName: string) => void,
    selectedDataModel?: {
        data_type_name: string,
        data_type_uuid: string,
        is_required: boolean
    } | null,
    setStatusAirFlow: (value: boolean) => void,
    statusAirFlow: boolean,
    selectedDataImportStatus: {
        [key: string]: { status: string, dag_id: string, dag_run_id: string }
    } | null
}
let intervalCheckStatus: NodeJS.Timer
let intervalPreProcessingReport: NodeJS.Timer

const Preprocessing = ({
                           onNext,
                           selectedTab,
                           dataImportId,
                           onLoadReports,
                           selectedDataModel,
                           statusAirFlow,
                           setStatusAirFlow,
                           selectedDataImportStatus
                       }: PreProcessingProps) => {

    const [progress, setProgress] = useState<number>(0);
    const [dataPreProcessing, setDataPreProcessing] = useState<{
        dag_id: string,
        dag_run_id: string
    } | null>(null);
    const [preProcessingReport, setPreProcessingReport] = useState<any>(null);
    const [isFailed, setIsFailed] = useState<boolean>(false);

    const {setError} = useError();

    const handleClick = () => {
        onNext(3);
    }

    useEffect(() => {
        if (selectedTab === 2 && dataImportId && selectedDataImportStatus && selectedDataImportStatus['preprocessing']) {
            setDataPreProcessing({dag_id: selectedDataImportStatus['preprocessing'].dag_id, dag_run_id: selectedDataImportStatus['preprocessing'].dag_run_id})
        } else if (selectedTab === 2 && isNullOrUndefinedOrEmpty(dataPreProcessing) && dataImportId) {
            loadPreProcessing()
        }
    }, [selectedTab]);

    useEffect(() => {
        return () => {
            clearInterval(intervalCheckStatus);
            clearInterval(intervalPreProcessingReport);
        }
    }, []);

    const loadPreProcessing = async () => {
        try {
            const values = {
                data_import_uuid: dataImportId
            }

            const data = await preProcessing(values);
            if (data?.dag_id && data?.dag_run_id) {
                setDataPreProcessing(data)
                intervalPreProcessingReport = setInterval(() => {
                    loadDataImportReport();
                }, 10000)
            } else {
                loadDataImportReport()
            }
        } catch (error: any) {
            setIsFailed(true);
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    useEffect(() => {
        if (dataPreProcessing) {
            intervalCheckStatus = setInterval(() => {
                handleCheckStatusAirFlow()
            }, 5000)
        }
    }, [dataPreProcessing]);

    const handleCheckStatusAirFlow = async () => {
        try {
            const values = {
                dataImportId,
                dagaId: dataPreProcessing?.dag_id,
                runId: dataPreProcessing?.dag_run_id
            }
            const data = await airFlowStatus(values)
            if (data.status === SUCCESS) {
                clearInterval(intervalCheckStatus);
                await loadDataImportReport()
            } else if (data.status === FAILED) {
                clearInterval(intervalCheckStatus);
                clearInterval(intervalPreProcessingReport);
                setIsFailed(true);
                setError({
                    message: "There was an error while importing the data model. To resolve this, please ensure that it meets the specified format requirements.",
                    type: 'error'
                })
            }
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    const loadDataImportReport = async () => {
        try {
            const values = {
                dataImportId,
                phaseName: "preprocessing"
            }
            const data = await dataImportReport(values);
            setPreProcessingReport(data);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    const handleActiveStatusAirFlow = () => {
        if (preProcessingReport?.data_identification?.length > 0 && preProcessingReport?.data_validation?.length > 0 && preProcessingReport?.missing_data_treatment?.length > 0) {
            setStatusAirFlow(true);
            clearInterval(intervalPreProcessingReport);
        }
    }

    useEffect(() => {
        handleActiveStatusAirFlow()
    }, [preProcessingReport]);

    const showDetectedColumn = () => {
        const columns = preProcessingReport['data_identification'].find(item => item.metric_name === "detected_columns")?.metric_value?.value;

        if (columns) {
            return columns.map((item, index) => {
                return (
                    <span>{item}{index !== columns.length - 1 ? "," : ""}</span>
                )
            })
        }
        return '';
    }

    useEffect(() => {
        setDataPreProcessing(null);
        setPreProcessingReport(null);
        setStatusAirFlow(false);
        clearInterval(intervalCheckStatus);
        clearInterval(intervalPreProcessingReport);
        setIsFailed(false);
    }, [selectedDataModel, dataImportId]);

    const isLoading = (phaseName: string) => {
        if ((preProcessingReport && preProcessingReport[phaseName] && preProcessingReport[phaseName].length > 0) || isFailed) {
            return false
        }
        return true;
    }

    return (
        <div
            className="flex flex-col flex-1 border border-lightGray-100 p-6 rounded relative h-full">
            <div className="h-[calc(100%-48px)] overflow-y-auto">
                <div className="w-full bg-lightGray-100 h-1 absolute left-0 top-0 right-0">
                    <div className="bg-green-200 h-1" style={{width: `${progress}%`}}></div>
                </div>
                <div>
                    <div><strong>Data Identification:</strong></div>
                    <ul className="flex flex-col gap-y-3 mt-2">
                        <li className="flex items-center gap-x-2">
                            <DataReportItem
                                isLoading={isLoading("data_identification")}
                                icon={isFailed ? "failed" : "success"}
                                iconTitle="View Records Details"
                                title="Number of Records: "
                                data={preProcessingReport ? renderDataReport(preProcessingReport, "data_identification", "number_of_records") : null}
                                onClick={() => onLoadReports('data_identification', 'number_of_records', '10', '')}
                            />
                        </li>
                        <li className="flex items-start gap-x-2">
                            {
                                isLoading("data_identification") ? <Spinner/> :
                                    <i className={`icon icon-24 icon-${isFailed ? "failed" : "success"}`}/>
                            }
                            <span>Columns Detected:</span>
                            {
                                !isLoading("data_identification") && preProcessingReport ? (
                                    <div
                                        className="max-w-[calc(100%-210px)] flex flex-wrap gap-x-1">{showDetectedColumn()}</div>
                                ) : null
                            }
                        </li>
                    </ul>
                </div>
                <div className="mt-6">
                    <div><strong>Data Validation:</strong></div>
                    <ul className="mt-2 flex flex-col gap-y-3">
                        <li className="flex items-center gap-x-2">
                            <DataReportItem
                                isLoading={isLoading("data_validation")}
                                icon={isFailed ? "failed" : "success"}
                                iconTitle="View Sample"
                                title="Valid Records: "
                                data={preProcessingReport ? renderDataReport(preProcessingReport, "data_validation", "number_of_valids") : null}
                                onClick={() => onLoadReports('data_validation', 'number_of_valids', '10', 'valid')}
                            />
                        </li>
                        <li className="flex items-center gap-x-2">
                            <DataReportItem
                                isLoading={isLoading("data_validation")}
                                icon={isFailed ? "failed" : "danger"}
                                iconTitle="View Details"
                                title="Invalid Records: "
                                data={preProcessingReport ? renderDataReport(preProcessingReport, "data_validation", "number_of_invalids") : null}
                                onClick={() => onLoadReports('data_validation', 'number_of_invalids', '10', 'invalid')}
                            />
                        </li>
                    </ul>
                </div>
                <div className="mt-6">
                    <div><strong>Missing Data Treatment:</strong></div>
                    <ul className="mt-2 flex flex-col gap-y-3">
                        <li className="flex items-center gap-x-2">
                            <DataReportItem
                                isLoading={isLoading("missing_data_treatment")}
                                icon={isFailed ? "failed" : "warning"}
                                iconTitle="View Affected Columns"
                                title="Missing Data Detected: "
                                data={preProcessingReport ? renderDataReport(preProcessingReport, "missing_data_treatment", "number_of_missing_data") : null}
                                onClick={() => onLoadReports('missing_data_treatment', 'number_of_missing_data', '10', 'missing')}
                            />
                        </li>
                        {/*<li className="flex items-center gap-x-2">*/}
                        {/*    {*/}
                        {/*        isLoading("missing_data_treatment") ? <Spinner/> :*/}
                        {/*            <i className={`icon icon-24 icon-${isFailed ? "failed" : "success"}`}/>*/}
                        {/*    }*/}
                        {/*    <span>Treatment Applied:</span>*/}
                        {/*    {*/}
                        {/*        !isLoading("missing_data_treatment") && preProcessingReport && !isFailed ? (*/}
                        {/*            <i className="icon icon-20 icon-search cursor-pointer"*/}
                        {/*               title="View Applied treatment methods"/>) : null*/}
                        {/*    }*/}
                        {/*</li>*/}
                    </ul>
                </div>
            </div>
            <div className="h-[48px] flex justify-end">
                <button
                    className={`btn btn-primary w-fit px-4 ml-auto ${statusAirFlow ? "" : "btn-disabled"}`}
                    onClick={handleClick}>Next
                </button>
            </div>
        </div>
    )
}

export default Preprocessing;