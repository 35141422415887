import React from "react";


type EmptyConversationProps = {
    onClick: () => void
}
const EmptyConversation = ({onClick}: EmptyConversationProps) => {

    const handleClick = () => {
        onClick && onClick();
    }

    return (
        <div className="shadow-sm bg-white h-full flex items-center justify-center flex-col">
            <div className="flex gap-x-3 mt-6">
                <button className="btn btn-primary py-4 px-12" onClick={handleClick}>Start A New Conversation</button>
            </div>
        </div>
    )
}

export default EmptyConversation