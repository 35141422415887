import React, {createContext, ReactNode, useContext, useState} from "react";
import {IDashboard} from "../shared/interfaces/IDashboard";

type DashboardProviderProps = {
    children?: ReactNode
}

export const DashboardContext = createContext<{ dashboard: Partial<IDashboard> | null, setDashboard: (dashboard: IDashboard) => void }>({dashboard: null, setDashboard: () => {}})
export const DashboardProvider = ({children}: DashboardProviderProps) => {

    const [dashboard, setDashboard] = useState<IDashboard | null>(null);

    return (
        <DashboardContext.Provider value={{dashboard, setDashboard}}>
            {children}
        </DashboardContext.Provider>
    )
}

export const useDashboard = () => useContext(DashboardContext);
