import React, {useEffect, useRef, useState} from "react";
import Item from "./Item";

type selectProps = {
    options: { value: number | string, label: string }[] | [],
    value: { value: number | string, label: string } | null,
    onChange: (item) => void,
    label?: string,
    placeholder: string,
    disabled?: boolean,
    classNameWrapper?: string,
    error?: string,
    required?: boolean,
    searchable?: boolean
}

const Select = ({
                    options,
                    value,
                    onChange,
                    placeholder,
                    disabled = false,
                    classNameWrapper,
                    label = "",
                    required = false,
                    searchable = false,
                    error
                }: selectProps) => {

    const [isOpen, setIsOpen] = useState(false);
    const [optionList, setOptionList] = useState(options || []);
    const selectRef = useRef<HTMLDivElement>(null)
    const [filterText, setFilterText] = useState("")

    useEffect(() => {
        document.addEventListener('mousedown', handleSelectIsOpen);

        return () => {
            document.removeEventListener('mousedown', handleSelectIsOpen);
        };
    }, [])

    const handleSelectIsOpen = (e) => {
        if (selectRef?.current && !selectRef.current.contains(e.target)) {
            setIsOpen(false)
        }
    }

    useEffect(() => {
        setIsOpen(false)
        setFilterText("")
    }, [value])

    useEffect(() => {
        setOptionList(options);
    }, [options])

    const calculateDistanceToBottom = () => {
        const elementRect = selectRef.current?.getBoundingClientRect();
        const windowHeight = window.innerHeight;

        if (elementRect) {
            return windowHeight - elementRect.bottom;
        }
        return 150;
    }

    useEffect(() => {
        if (filterText !== "") {
            const resultSearch = options.filter(option => option.label.toLowerCase().search(filterText.toLowerCase()) > -1);
            setOptionList(resultSearch)
        } else {
            setOptionList(options)
        }
    }, [filterText])

    return (
        <div className="form-group">
            <label className={`form-label ${required ? "required" : ""}`}>{label}</label>
            <div className={`select-wrapper ${classNameWrapper}`} ref={selectRef}>
                <div className={`select-input relative ${isOpen ? "list-open" : ""}${disabled ? "disabled" : ""}`}
                     onClick={() => setIsOpen(prevState => !prevState)}>
                    <span className={`truncate ${!value ? "" : "is-null"}`}
                          title={value ? value?.label : ""}>{value ? value?.label : placeholder}</span>
                    <span className={`transition-all ${isOpen ? "rotate-180" : ""}`}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.40001 9.59844L12 14.3984L17.6 9.59844" stroke="#1C274C" strokeWidth="1.5"
                          strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
                </span>
                </div>
                <ul className={`select-list ${isOpen ? "is-open" : ""} ${calculateDistanceToBottom() < 150 ? "open-top" : ""}`}>
                    {
                        searchable ? <input type="text" className="form-input h-10 mb-1" value={filterText}
                                            onChange={e => setFilterText(e.target.value)}
                                            placeholder="search..."/> : null
                    }
                    {
                        optionList.length > 0 && optionList.map(item => {
                            return (
                                <Item item={item} key={item.value} isActive={item.value === value?.value}
                                      onClick={onChange} setIsOpen={setIsOpen}/>
                            )
                        })
                    }
                </ul>
            </div>
            <span className="absolute text-xs text-red top-full left-0 whitespace-nowrap">
            {
                error !== "" ? <>{error}</> : null
            }
            </span>
        </div>
    )
}

export default Select;