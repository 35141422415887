import React from "react";
import { Tree } from '../../../custom_node_modules/react-organizational-chart/dist/index';
import TreeDiagramNode from "./TreeDiagramNode";
import ModelNode from "./ModelNode";
import {IModelNodes} from "../../pages/Model";
import {IModel} from "../../shared/interfaces/IModel";

type treeDiagramProps = {
    dataDiagram: IModelNodes[],
    onSelect: (model: IModel) => void,
    onOpenModal: () => void,
    isHorizontal: boolean
}
const TreeDiagram = ({ dataDiagram, onSelect, onOpenModal, isHorizontal }: treeDiagramProps) => {

    const parentNode = dataDiagram.find(node => node.base_model_id === null);

    if (!parentNode) return null

    return (
        <Tree isHorizontal={isHorizontal} label={<ModelNode item={parentNode} onSelect={onSelect} onOpenModal={onOpenModal} />}>
            {
                (parentNode?.children || []).map(item => {
                    return (
                        <TreeDiagramNode isHorizontal={isHorizontal} item={item} key={item?.uuid} onSelect={onSelect} onOpenModal={onOpenModal}/>
                    )
                })
            }
        </Tree>
    )
}


export default TreeDiagram;