import React from "react";

type ItemProps = {
    item: {value: number, label: string},
    onClick: (item) => void,
    isActive: boolean,
    setIsOpen: (value: boolean) => void
}
const Item = ({item, onClick, isActive, setIsOpen}: ItemProps) => {

    const handleClick = () => {
        setIsOpen(false)
        onClick && onClick(item);
    }

    return (
        <li value={item.value} onClick={handleClick} className={`truncate ${isActive ? "active" : ""}`} title={item.label}>
            {item.label}
        </li>
    )
}


export default Item;