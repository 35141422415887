import React, {useCallback, useEffect} from "react";
import {useDropzone} from "react-dropzone";
import {useError} from "../../context/ErrorContext";

type UploadAvatarProps = {
    avatar: string | null;
    onChange: (avatar: File[]) => void;
    textAvatar: string
}
const UploadAvatar = ({onChange, avatar, textAvatar}: UploadAvatarProps) => {

    const {setError} = useError();
    const onDrop = (acceptedFiles: File[]) => {
        onChange(acceptedFiles);
    }

    const {getRootProps, getInputProps, fileRejections} = useDropzone({
        onDrop,
        noDrag: false,
        multiple: false,
        maxSize: 200000,
        accept: {
            'image/jpeg': ['.jpeg'],
            'image/png': ['.png']
        }
    })

    useEffect(() => {
        if (fileRejections?.length > 0) {
            if (fileRejections[0].errors[0].code === 'file-invalid-type') {
                setError({message: "This File Type Not Supported.", type: 'error'});
            }
            if (fileRejections[0].errors[0].code === 'file-too-large') {
                setError({message: "File Is Larger Than 2000 KB", type: 'error'});
            }
        }
    }, [fileRejections])

    return (
        <div className="avatar mb-4">
            <div className="w-24 rounded-full relative">
                {
                    avatar ? (
                        <img src={avatar} alt="user-profile"/>
                    ) : (
                        <span
                            className="inline-block w-24 h-24 text-5xl bg-blue-400 rounded-full leading-[96px] text-center select-none">{textAvatar || ""}</span>
                    )}
            </div>
            <div {...getRootProps()} className="bg-white rounded-full cursor-pointer z-10 absolute top-0 left-0">
                <i className="icon icon-24 icon-edit-circle"/>
                <input {...getInputProps()} />
            </div>
        </div>
    )
}

export default UploadAvatar;