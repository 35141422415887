import React, { useEffect, useState } from 'react';
import validator from '@rjsf/validator-ajv8';
import Form from "@rjsf/core";
import { RegistryWidgetsType } from '@rjsf/utils';
import CustomCheckbox from "../CustomComponentRJSF/CustomCheckBox";
import CustomInputField from "../CustomComponentRJSF/CustomInputField";
import CustomSelectField from "../CustomComponentRJSF/CustomSelectField";

type formGeneratorProps = {
    formData: { properties?: object },
    rules?: object,
    onSubmit?: (values) => void,
    onCancel?: () => void,
    isLoading?: boolean
}

const widgets: RegistryWidgetsType = {
    CheckboxWidget: CustomCheckbox,
    TextWidget: CustomInputField,
    SelectWidget: CustomSelectField,
};

const FormGenerator = ({ formData, rules, onSubmit, onCancel, isLoading }: formGeneratorProps) => {
    const [schema, setSchema] = useState({});
    const [value, setValue] = useState({});
    const log = (type) => console.log.bind(console, type);

    useEffect(() => {
        setSchema(formData);
    }, [formData])

    useEffect(() => {
        const withRules = applyRules({ formData: formData, rules }, value)
        setSchema(withRules)
    }, [value])

    const handleSubmit = (values) => {
        onSubmit && onSubmit(values)
    }

    return (
        <Form
            schema={schema}
            validator={validator}
            widgets={widgets}
            formData={value}
            onChange={(value) => {
                setValue(value.formData);
                // console.log({ value })
            }}
            showErrorList={false}
            onSubmit={handleSubmit}
            onError={log('errors')}
        >
            <div className="flex justify-end">
                <button className="btn btn-outline btn-primary px-8" type="button" onClick={onCancel}>Cancel</button>
                <button className={`btn btn-primary ml-3 px-8 ${!isLoading ? "" : "btn-disabled"}`} type="submit">Run Analytics</button>
            </div>
        </Form>
    )
}

const applyRules = ({ formData, rules }: formGeneratorProps, values: {}) => {
    if (!rules || !formData.properties) {
        return formData;
    }
    let newRuleSchema = formData.properties;
    Object.keys(formData.properties).forEach((prop) => {
        if (rules[prop]) {
            rules[prop].forEach((rule) => {
                if (values[prop] === rule.if?.properties[prop]?.const) {
                    const thenConditions = rule.then?.properties;
                    const conditionsSchemas = applyRules({ formData: rule.then, rules }, values);
                    newRuleSchema = { ...newRuleSchema, ...thenConditions, ...conditionsSchemas.properties }

                }
            })
        }
    })

    return { ...formData, properties: newRuleSchema };
}

export default FormGenerator;