import React from "react";

export const useCreateTabItem = (children) => {
    return Array.from(children).map(node => {
        if (React.isValidElement(node)) {
            const { key, props } = node;
            // @ts-ignore
            const { label, ...restProps } = props || {};

            return {
                key: parseInt(<string>key),
                ...restProps,
                label,
            };
        }
        return null;
    });
}