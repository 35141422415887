import {WidgetProps} from "@rjsf/utils";
import React from "react";

const CustomInputField = function (props: WidgetProps) {
    return (
        <input type="text" className="input input-bordered border-dark-400 w-full border rounded bg-white"
               onChange={(e) => props.onChange(e.target.value)} placeholder={props.placeholder}/>
    )
}

export default CustomInputField;