import React, {useContext, useEffect, useState} from 'react';
import {me} from "../services";
import {useAuth} from "./AuthContext";
import {useError} from "./ErrorContext";
import {isNullOrUndefinedOrEmpty} from "../shared/utils/helper";

const defaultBehaviour = {
    isAllowedTo: (permission: string): { allowed: boolean, loading: boolean } => {
        return {allowed: false, loading: true}
    }
}

export const PermissionContext = React.createContext(defaultBehaviour);

type PermissionProviderProps = {
    children: React.ReactNode,
}

const PermissionProvider = ({children}: PermissionProviderProps) => {

    const {user, setUser} = useAuth();
    const {setError} = useError();
    const [permissions, setPermissions] = useState<Array<string> | []>([]);
    const [loading, setLoading] = useState(true);

    const isAllowedTo = (permission: string): { allowed: boolean, loading: boolean } => {
        if (permission === "") return {allowed: true, loading: false}
        if (!isNullOrUndefinedOrEmpty(user) && permissions.length === 0) return {allowed: true, loading: false}
        // @ts-ignore
        if (permissions.includes(permission)) {
            return {allowed: true, loading}
        }
        return {allowed: false, loading}
    };

    useEffect(() => {
        // if (isNullOrUndefinedOrEmpty(user)) {
            loadUser();
        // }
    }, [])

    const loadUser = async () => {
        try {
            const data = await me();
            setUser(data);
            if (data.roles) {
                setPermissions(data.roles);
            }
        } catch (error: any) {
            setError({message: error?.response?.data?.detail, type: 'error'});
        } finally {
            setLoading(false);
        }
    }

    return <PermissionContext.Provider value={{isAllowedTo}}>{children}</PermissionContext.Provider>;
};

export default PermissionProvider;

export const usePermission = (permission: string) => {
    const {isAllowedTo} = useContext(PermissionContext);
    return isAllowedTo(permission);
}