import React from "react";
import SignInForm from "../../components/SignInForm";

const SignIn = () => {

    return (
        <div className="w-full h-screen mx-auto bg-white">
            <div className="flex items-center justify-center w-full h-screen">
                <div className="flex-grow-0 flex-shrink-0 flex flex-col items-center justify-center h-screen w-4/5 md:w-1/2 z-10">
                    <SignInForm/>
                </div>
                <div className="flex-1 p-0 m-0 z-0 h-screen hidden md:block">
                    <img src="/images/sign-in-image.png" alt="" className="h-full w-full"/>
                </div>
            </div>
        </div>
    )
}

export default SignIn;