import React, {useEffect, useState} from "react";
import ActionBar from "../../../components/ActionBar";
import DescriptionPage from "../../../components/SettingSidebar/DescriptionPage";
import Form from "./Form";
import {useNavigate, useParams} from "react-router-dom";
import {updateUserOrg, uploadAvatar, userOrg} from "../../../services";
import {UserValuesType} from "../../../shared/formValidator/userFormValidator";
import {useError} from "../../../context/ErrorContext";
import {useAuth} from "../../../context/AuthContext";

const EditUser = () => {

    const {uuid} = useParams();
    const [userData, setUserData] = useState<UserValuesType | null>(null);

    const navigate = useNavigate();
    const {setError} = useError();
    const {user, setUser} = useAuth();

    const onSubmit = async (values: UserValuesType) => {
        const {
            teams,
            password,
            phone,
            password_field,
            user_name_field,
            workspaces,
            user_role,
            role_ids,
            ...rest
        } = values;

        const formValues = {
            first_name: values.first_name?.trim(),
            last_name: values.last_name?.trim(),
            phone: values.phone?.trim(),
            email: values.email?.trim(),
            team_uuids: teams?.map(item => item.value),
            workspace_uuids: workspaces?.map(item => item.value),
            organization_uuid: values.organization_uuid,
            role_uuids: values.user_role?.map(item => item.value),
            user_name: values.user_name_field.trim(),
            is_active: values.is_active,
        }

        try {
            await updateUserOrg(uuid, formValues);
            navigate(`/setting/users`);
        } catch (error: any) {
            setError({message: error?.response?.data?.detail, type: 'error'});
        }
    }

    useEffect(() => {
        loadUser();
    }, [])

    const handleChangeAvatar = async (file: File[]) => {
        if (file.length > 0 && uuid && uuid !== "") {
            const formData = new FormData();
            formData.append("file", file[0]);
            try {
                const data = await uploadAvatar(formData, uuid);
                // @ts-ignore
                setUserData({...userData, avatar_url: data.avatar_url});
                if (uuid === user.uuid) {
                    setUser({...user, avatar_url: data.avatar_url})
                }
            } catch (error: any) {
                setError({message: error?.response?.data?.detail, type: 'error'})
            }
        }
    }

    const loadUser = async () => {
        try {
            const data = await userOrg(uuid);
            setUserData(data);
        } catch (error: any) {
            setError({message: error?.response?.data?.detail, type: 'error'});
        }
    }

    return (
        <div className="flex flex-col gap-x-1 gap-y-1 w-full">
            <div className="flex flex-col flex-1 gap-y-1">
                <ActionBar goBack={true} pageTitle="Users" topic="user" isShow={false}/>
            </div>
            <div className="flex h-[calc(100vh-133px)] gap-x-1">
                <DescriptionPage>
                    <p>add / edit users and team belongs to it</p>
                </DescriptionPage>
                <section className="h-full flex flex-col p-6 bg-white shadow-sm flex-1 overflow-y-auto">
                    <div className="border border-light-100 rounded-lg p-6 grid grid-cols-3">
                        <Form onSubmit={onSubmit} data={userData} handleChangeAvatar={handleChangeAvatar}/>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default EditUser;