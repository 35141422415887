import React from "react";

export default function Pane({item, activeKey}: any) {

    return (
        <div className={`h-full ${item?.key === activeKey ? "" : "hidden"}`}>
            {
                item?.children
            }
        </div>
    )
}