import React from "react";
import {useCreateTabItem} from "./hook/useCreateTabItem";
import Item from "./Item";
import Pane from "./Pane";

type tabProps = {
    activeKey: number,
    onClick: (key) => void;
    TabItemClassName?: string,
    children: React.ReactNode,
    tabContentClassName?: string
}
const Tab = ({activeKey, onClick, children, TabItemClassName = "", tabContentClassName = ""}: tabProps) => {

    const childrenItem = useCreateTabItem(children);

    return (
        <>
            <div className="tabs bg-light-300 p-2">
                {
                    childrenItem && childrenItem?.map(item => {
                        return <Item item={item} key={item?.key} activeKey={activeKey} onClick={onClick} className={TabItemClassName}/>;
                    })
                }
            </div>
            <div className={`tabs-content flex-1 mt-5 ${tabContentClassName}`}>
                {
                    childrenItem && childrenItem.map(item => {
                        return (
                            <Pane item={item} activeKey={activeKey} key={item?.key}/>
                        );
                    })
                }
            </div>
        </>
    )
}

export default Tab;

Tab.Pane = Pane;