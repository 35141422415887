import React from 'react';
import {Link, NavigateFunction} from "react-router-dom";
import {withRouter} from "../shared/HOC/withRouter";

interface ErrorBoundaryProps {
    children: React.ReactNode,
    navigate: NavigateFunction,
    location: Location
}

interface ErrorBoundaryState {
    hasError: boolean;
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {

    constructor(props) {
        super(props);
        this.state = {hasError: false};
        this.handleGoBack = this.handleGoBack.bind(this);
        this.handleRefresh = this.handleRefresh.bind(this);
    }

    static getDerivedStateFromError() {
        return {hasError: true};
    }

    handleGoBack = () => {
        this.props.navigate(-1);
    }

    handleRefresh = () => {
        window.location.reload();
    }

    componentDidUpdate(prevProps: Readonly<ErrorBoundaryProps>, prevState: Readonly<ErrorBoundaryState>, snapshot?: any) {
        if (prevProps.location.pathname !== this.props.location.pathname) {
            this.setState({hasError: false})
        }
    }

    componentDidCatch(error, info) {
        console.error('Error caught:', error);
        console.error('Info:', info);
    }

    render() {
        if (this.state.hasError) {
            return (
                <div className="h-screen">
                    <header className="py-3 px-6 bg-white shadow-sm">
                        <nav className="flex justify-between">
                            <Link to="/" className="flex items-center h-[38px]">
                                <img src="/images/main-logo.png" alt="logo" className="h-full"/>
                            </Link>
                        </nav>
                    </header>
                    <div className="container flex mx-auto h-[calc(100%-64px)]">
                        <div className="w-full flex justify-center flex-col bg-white mt-[2px] flex-1">
                            <h1 className="text-6xl"><strong>Oops! Something went wrong.</strong></h1>
                            <p className="mt-6">We encountered an unexpected error that's preventing us from displaying this page. Don't worry – our team of developers has been notified, and we're working hard to fix it.</p>
                            <p>You can try again by refreshing the page, If the problem persists or you have additional information that might help us, please contact our <Link to="/" className="text-primary">support team</Link></p>
                            <div className="flex justify-end mt-6">
                                <button className="btn btn-primary btn-outline mr-4" onClick={this.handleGoBack}>Back
                                </button>
                                <button className="btn btn-primary" onClick={this.handleRefresh}>Refresh</button>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }

        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);