import React, {createContext, ReactNode, useContext, useState} from 'react';


type MessageProps = {
    isOpen: boolean,
    topic: string
}

type MessageProviderProps = {
    children: ReactNode
}

const MessageContext = createContext<{message: MessageProps, setMessage: (value: MessageProps) => void}>({
    message: {isOpen: false, topic: ''},
    setMessage: () => {}
})

export const MessageProvider = ({children}: MessageProviderProps) => {

    const [message, setMessage] = useState<MessageProps>({isOpen: false, topic: ''});
    return (
        <MessageContext.Provider value={{message, setMessage}}>
            {children}
        </MessageContext.Provider>
    )
}


export const useMessage = () => useContext(MessageContext);