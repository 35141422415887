import React, {useEffect, useRef, useState} from "react";
import {Link} from "react-router-dom";
import {useLearn} from "../../context/LearnContext";
import {useError} from "../../context/ErrorContext";
import {learnContent} from "../../services";
import data from "../../mockData/data.json";
import {ILearnContent} from "../../shared/interfaces/ILearnContent";
import LearnContent from "./LearnContent";
import LearnTopic from "./LearnTopic";

const Learn = () => {

    const [learnAcademyContent, setLearnAcademyContent] = useState<ILearnContent[] | []>([]);
    const {learn, setLearn} = useLearn();
    const {setError} = useError();
    const learnCardRef = useRef<HTMLDivElement>(null);

    const {learnContentMock} = data;

    const onClose = () => {
        setLearn({isOpen: false, topic: ''});
    }

    useEffect(() => {
        if (learn.topic !== "") {
            loadLearnContent()
        }
    }, [learn.topic])

    const loadLearnContent = async () => {
        try {
            const data = await learnContent(learn.topic);
            setLearnAcademyContent(learnContentMock);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, []);

    const handleClickOutside = event => {
        if (learnCardRef.current && !learnCardRef.current.contains(event.target) && event.target.tagName !== 'I' && event.target.className !== "icon icon-24 icon-academic") {
            onClose()
        }
    }

    return (
        <div ref={learnCardRef}
            className={`h-[calc(100%-64px)] max-h-[calc(100%-64px)] overflow-y-auto w-[360px] transition-all shadow-sm bg-white absolute p-4 top-[64px] bottom-0 ${learn.isOpen ? "right-0" : "right-[-360px]"} `}>
            <h3 className="border-b border-lightGray-200 bg-white flex items-center justify-between">
                <span>Learn</span>
                <i className="icon icon-24 icon-close-circle cursor-pointer" onClick={onClose}/>
            </h3>
            {
                learnAcademyContent.length > 0 && learnAcademyContent.map((learnTopic: ILearnContent) => {
                    return (
                            <LearnTopic learnTopic={learnTopic} key={learnTopic.uuid} />
                    )
                })
            }
        </div>
    )
}

export default Learn;