import React from "react";
import ActionBarButton from "./ActionBarButton";
import {useNavigate} from "react-router-dom";

type ActionBarProps = {
    pageTitle?: string,
    goBack?: boolean,
    buttons?: { title: string, icon: string, action: () => void, className?: string }[],
    topic?: string,
    isShow?: boolean
}
const ActionBar = ({pageTitle, buttons, goBack, topic, isShow = true}: ActionBarProps) => {

    const navigate = useNavigate();

    const handleGoBack = () => {
        navigate(-1)
    }

    return (
        <div className="py-5 px-6 bg-white shadow-sm flex justify-between items-center h-fit">
            <div className="flex gap-x-5">
                {
                    pageTitle && (
                        <div>
                            {
                                goBack && (<i className="icon icon-24 icon-backward cursor-pointer mr-3" onClick={handleGoBack}/>)
                            }
                            <span>{pageTitle}</span>
                        </div>
                    )
                }
                {
                    buttons && buttons.length > 0 && buttons.map((button, index) => {
                        return (
                            <button className={`flex items-center text-sm ${button?.className ? button.className : ""}`} onClick={button?.action} key={index}><i className={`icon icon-24 icon-${button?.icon}`}/><span
                                className="ml-1">{button?.title}</span></button>
                        )
                    })
                }
            </div>
            {
                isShow ? <ActionBarButton topic={topic}/> : null
            }
        </div>
    )
}

export default ActionBar