import {useState} from "react";
import {useError} from "../../context/ErrorContext";
import {
    audiences,
    channel, channelMessages,
    channels,
    createChannel,
    createMessage, createMessageMention,
    deleteChannel, deleteMessage, getChannelByResource, getMessage, markMessageAsRead, messageMentions,
    teams, updateChannel, updateMessage,
    users, usersMention
} from "../../services";
import {teamsToList, usersToList} from "../utils/toList";
import {IChannel} from "../interfaces/IChannel";
import {useWorkSpace} from "../../context/WorkSpaceContext";
import {IMessage} from "../interfaces/IMessage";
import {useLocation} from "react-router-dom";
import {MESSAGE_STATUS} from "../constant";
import {useAuth} from "../../context/AuthContext";
import {IAudience} from "../interfaces/IAudience";
import {IUser} from "../interfaces/IUser";

export const useMessages = () => {

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const [shareItems, setShareItems] = useState<{
        label: string,
        value: string,
        type?: string,
        display?: string
    }[] | []>([])
    const [channelList, setChannelList] = useState<IChannel[] | []>([])
    const [selectedChannel, setSelectedChannel] = useState<IChannel | null>(null);
    const [channelData, setChannelData] = useState<IChannel | null>(null);
    const [channelDataMessages, setChannelDataMessages] = useState<IMessage[] | []>([]);
    const [messageData, setMessageData] = useState<IMessage | null>(null);
    const [userQuestion, setUserQuestion] = useState<string>("")
    const [audienceList, setAudienceList] = useState<IAudience[] | []>([])
    const [repliedMessage, setRepliedMessage] = useState<IMessage | null>(null);
    const [isLoadingChannel, setIsLoadingChannel] = useState<boolean>(false);

    const {user} = useAuth();
    const {setError} = useError();
    const {workSpace} = useWorkSpace();
    const {pathname} = useLocation();

    const handleOpenModal = () => {
        setIsOpenModal(true);
    }

    const handleCloseModal = () => {
        setIsOpenModal(false);
        setChannelData(null);
    }

    const handleCreateChannel = async (values) => {
        const formValues = {
            workspace_uuid: workSpace?.uuid,
            title: values.title.trim(),
            resource: values.resource !== "" ? values.resource : null,
            page_url: values.pathname ? values.pathname : null,
            is_direct_channel: false,
            is_active: true,
            user_uuids: values.member_uuids.filter(item => item.type === 'user').map(item => item.value),
            team_uuids: values.member_uuids.filter(item => item.type === 'team').map(item => item.value)
        }
        try {
            const data = await createChannel(formValues);
            handleCloseModal();
            if (workSpace?.uuid) {
                loadChannels(workSpace.uuid);
            }
            return data;
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    const handleUpdateChannel = async (uuid, values) => {
        const formValues = {
            workspace_uuid: values.workspace_uuid,
            title: values.title.trim(),
            page_url: values.page_url,
            is_direct_channel: values.is_direct_channel,
            is_active: values.is_active,
            user_uuids: values.member_uuids.filter(item => item.type === 'user').map(item => item.value),
            team_uuids: values.member_uuids.filter(item => item.type === 'team').map(item => item.value)
        }

        try {
            await updateChannel(uuid, formValues);
            handleCloseModal();
            if (workSpace?.uuid) {
                loadChannels(workSpace.uuid);
            }
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    const loadUserList = async (resource: string, workSpaceId: string) => {
        try {
            return await usersMention(resource, workSpaceId);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    const loadTeamList = async () => {
        try {
            return await teams();
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    const loadChannels = async (uuid: string) => {
        try {
            const data = await channels(uuid);
            setChannelList(data);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    const loadChannel = async (uuid: string) => {
        try {
            const data = await channel(uuid);
            setChannelData(data);
            handleOpenModal();
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    const loadChannelByResource = async (resource: string) => {
        if (workSpace) {
            setIsLoadingChannel(true)
            try {
                const values = {
                    page_url: pathname
                }
                const data = await getChannelByResource(workSpace?.uuid, resource, values);
                setChannelData(data);
                setIsLoadingChannel(false)
            } catch (error: any) {
                setError({message: error.response.data.detail, type: 'error'})
                setIsLoadingChannel(false)
            }
        }
    }

    const loadAudiencesMessage = async (uuid: string) => {
        try {
            const data = await audiences(uuid);
            setAudienceList(data);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    const resetAudienceList = () => setAudienceList([]);

    const loadChannelMessages = async (uuid: string) => {
        try {
            const data = await channelMessages(uuid);
            setChannelDataMessages(data);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    const handleDeleteChannel = async (uuid: string) => {
        try {
            await deleteChannel(uuid);
            if (workSpace) {
                await loadChannels(workSpace?.uuid);
            }
            setSelectedChannel(null);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    const handleCreateMessage = async (channelUUId: string, isDraft = false) => {
        const matches = userQuestion.match(/\((.*?)\)/g) || [];
        let tempUserQuestion = userQuestion
        for (let key in matches) {
            tempUserQuestion = tempUserQuestion.replace(matches[key], '')
        }

        if (userQuestion !== "") {
            const values: IMessage = {
                message_status_uuid: isDraft ? MESSAGE_STATUS.DRAFT : MESSAGE_STATUS.SENT,
                message_channel_uuid: channelUUId,
                text: tempUserQuestion.trim(),
                message_type: "message",
                replied_message_uuid: repliedMessage && repliedMessage.uuid ? repliedMessage.uuid : null
            }
            try {
                const data = await createMessage(values);
                await handleAddMessageMention(matches.map((match: string) => match.replace(/^\(|\)$/g, '')), data.uuid)
                setChannelDataMessages([...channelDataMessages, data]);
                setUserQuestion("");
                setRepliedMessage(null);
            } catch (error: any) {
                setError({message: error.response.data.detail, type: 'error'})
            }
        }
    }

    const handleUpdateMessage = async (channelUUId: string, isDraft = false) => {
        const matches = userQuestion.match(/\((.*?)\)/g) || [];
        let tempUserQuestion = userQuestion
        for (let key in matches) {
            tempUserQuestion = tempUserQuestion.replace(matches[key], '')
        }

        if (userQuestion !== "" && messageData?.uuid) {
            const values: IMessage = {
                message_status_uuid: isDraft ? MESSAGE_STATUS.DRAFT : MESSAGE_STATUS.SENT,
                message_channel_uuid: channelUUId,
                text: tempUserQuestion.trim(),
                message_type: "message",
                replied_message_uuid: messageData.replied_message_uuid ? messageData.replied_message_uuid : null
            }
            try {
                const data = await updateMessage(values, messageData?.uuid);
                await handleAddMessageMention(matches.map((match: string) => match.replace(/^\(|\)$/g, '')), data.uuid)
                const tempChannelDataMessage = [...channelDataMessages];
                const indexEditedMessage = tempChannelDataMessage.findIndex(item => item.uuid === messageData.uuid);
                tempChannelDataMessage[indexEditedMessage] = data;
                setChannelDataMessages(tempChannelDataMessage);
                setUserQuestion("");
                setRepliedMessage(null);
                setMessageData(null);
            } catch (error: any) {
                setError({message: error.response.data.detail, type: 'error'})
            }
        }
    }

    const handleAddMessageMention = async (UUIds: string[], messageUUId: string) => {

        const promises = UUIds.map(async uuid => {

            const itemUserOrTeam = shareItems.find(item => item.value === uuid);
            let values;
            if (itemUserOrTeam && itemUserOrTeam.type === 'user') {
                values = {
                    message_uuid: messageUUId,
                    user_uuid: uuid,
                    team_uuid: null
                }
            } else {
                values = {
                    message_uuid: messageUUId,
                    user_uuid: null,
                    team_uuid: uuid
                }
            }

            try {
                await createMessageMention(values);
            } catch (error: any) {
                setError({message: error.response.data.detail, type: 'error'})
            }
        })

        await Promise.all(promises);
    }

    const handleMarkAsRead = async (uuid: string) => {
        try {
            const values = {
                message_uuid: uuid,
                user_uuid: user.uuid,
                message_status_uuid: MESSAGE_STATUS.READ
            }
            await markMessageAsRead(values);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    const handleDeleteMessage = async (uuid: string) => {
        try {
            await deleteMessage(uuid);
            if (selectedChannel) {
                await loadChannelMessages(selectedChannel.uuid)
            }
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    const handleSelectChannel = async (channel: IChannel) => {
        if (channelDataMessages.length > 0) {
            setChannelDataMessages([]);
            setUserQuestion("")
        }
        setSelectedChannel(channel);
        await loadChannelMessages(channel.uuid);
    }

    const handleEditMessage = async (uuid: string) => {
        try {
            const data = await getMessage(uuid);
            let mainText = data.text;
            for (let key in shareItems) {
                let indexWord = mainText.search(shareItems[key].display);
                if (indexWord !== -1) {
                    mainText = insert(mainText, `(${shareItems[key].value})`, indexWord + shareItems[key].display?.length)
                }
            }
            setMessageData(data);
            setUserQuestion(mainText);
            await loadMentionsList(uuid);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    const loadMentionsList = async (uuid: string) => {
        try {
            await messageMentions(uuid);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    const fetchData = async (resource: string) => {
        if (workSpace) {
            const users = await loadUserList(resource, workSpace?.uuid);
            const teams = await loadTeamList();
            setShareItems([...usersToList(users), ...teamsToList(teams)]);
        }
    }

    const insert = (main_string: string, ins_string: string, pos: number) => {
        if (typeof (pos) == "undefined") {
            pos = 0;
        }
        if (typeof (ins_string) == "undefined") {
            ins_string = '';
        }
        return main_string.slice(0, pos) + ins_string + main_string.slice(pos);
    }

    return {
        isOpenModal,
        handleCloseModal,
        handleOpenModal,
        handleCreateChannel,
        shareItems,
        handleDeleteChannel,
        selectedChannel,
        setSelectedChannel,
        channelList,
        channelDataMessages,
        handleCreateMessage,
        userQuestion,
        setUserQuestion,
        loadChannel,
        channelData,
        handleUpdateChannel,
        handleMarkAsRead,
        fetchData,
        loadChannels,
        handleDeleteMessage,
        loadChannelByResource,
        audienceList,
        loadAudiencesMessage,
        resetAudienceList,
        handleEditMessage,
        handleUpdateMessage,
        messageData,
        handleSelectChannel,
        loadChannelMessages,
        repliedMessage,
        setRepliedMessage,
        setChannelDataMessages,
        isLoadingChannel
    }
}