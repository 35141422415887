import React, {useEffect, useRef, useState} from 'react';
import {useMessage} from "../../context/MessageContext";
import Loading from "../Loading";
import {Mention, MentionsInput} from "react-mentions";
import {useMessages} from "../../shared/hooks/useMessages";
import MessageItem from "./MessageItem";
import {useLocation} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";
import Spinner from "../Loading/Spinner";

const MessageCard = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [mentionList, setMentionList] = useState<{ id: string, display: string }[] | []>([]);
    const messageCardRef = useRef<HTMLDivElement>(null);
    const {user} = useAuth();

    const {
        shareItems,
        handleCreateMessage,
        loadChannelByResource,
        channelDataMessages,
        handleDeleteMessage,
        channelData,
        handleCreateChannel,
        userQuestion,
        setUserQuestion,
        handleEditMessage,
        loadChannelMessages,
        repliedMessage,
        setRepliedMessage,
        messageData,
        handleUpdateMessage,
        setChannelDataMessages,
        fetchData,
        isLoadingChannel
    } = useMessages()
    const {message, setMessage} = useMessage();
    const {pathname} = useLocation();

    const onClose = () => {
        setMessage({isOpen: false, topic: ''});
        setUserQuestion('')
    }

    useEffect(() => {
        if (shareItems.length > 0) {
            const temp = shareItems.filter(item => item.value !== user.uuid).map(item => {
                return {id: item.value, display: item.label}
            });

            setMentionList(temp)
        }
    }, [shareItems]);

    const handleSubmit = async (e) => {
        setIsLoading(true)
        e.preventDefault();
        if (channelData && channelDataMessages.length > 0) {
            if (messageData) {
                await handleUpdateMessage(channelData.uuid, false)
            } else {
                await handleCreateMessage(channelData.uuid);
            }
        } else {
            const values = {
                title: document.title.split("|").slice(1, 3).join(""),
                member_uuids: [],
                resource: message.topic,
                pathname
            }
            const data = await handleCreateChannel(values);

            await handleCreateMessage(data.uuid)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (message.isOpen) {
            loadChannelByResource(message.topic)
        }
    }, [message]);

    useEffect(() => {
        if (channelData) {
            loadChannelMessages(channelData.uuid);
        }
    }, [channelData]);

    const onSubmitMessageAsDraft = async () => {
        if (channelData && channelDataMessages.length > 0) {
            if (messageData) {
                await handleUpdateMessage(channelData?.uuid!, true)
            } else {
                await handleCreateMessage(channelData?.uuid!, true);
            }
        } else {
            const values = {
                title: document.title.split("|").slice(1, 3).join(""),
                member_uuids: [],
                resource: message.topic,
                pathname
            }
            const data = await handleCreateChannel(values);

            await handleCreateMessage(data.uuid, true);
        }
    }

    useEffect(() => {
        onClose();
    }, [pathname]);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside)
        }
    }, []);

    const handleClickOutside = event => {
        if (messageCardRef.current &&
            !messageCardRef.current.contains(event.target) &&
            event.target.tagName !== 'I' &&
            event.target.className !== "icon icon-24 icon-comment" &&
            event.target.className !== "flex-1__suggestions__item__display" &&
            event.target.className !== "flex-1__suggestions__item flex-1__suggestions__item--focused" &&
            event.target.className !== "border__suggestions__item" &&
            event.target.className !== "border__suggestions__list" &&
            event.target.className !== "border__suggestions__item__display"
        ) {
            onClose()
        }
    }

    useEffect(() => {
        if (!message.isOpen) {
            setChannelDataMessages([])
        }
        if (message.isOpen) {
            fetchData(message.topic);
        }
    }, [message.isOpen]);

    return (
        <div ref={messageCardRef}
             className={`h-[calc(100%-64px)] flex max-h-[calc(100%-64px)] overflow-y-auto w-[360px] transition-all shadow-sm bg-white absolute top-[64px] bottom-0 ${message.isOpen ? "right-0" : "right-[-360px]"} `}>
            <div className="flex-1 flex flex-col gap-y-1 shadow bg-white overflow-y-auto">
                <h3 className="border-b border-lightGray-200 bg-white flex items-center justify-between px-4 py-2">
                    <span>Message</span>
                    <i className="icon icon-24 icon-close-circle cursor-pointer" onClick={onClose}/>
                </h3>
                <div className="flex-1 p-4">
                    <div
                        className="border border-light-100 rounded-lg flex flex-col overflow-y-auto gap-y-4 px-3 py-3 h-full">
                        {
                            isLoadingChannel ? <div className="m-auto"><Spinner /></div> : null
                        }
                        {
                            channelDataMessages && channelDataMessages?.length > 0 && channelDataMessages?.map(message => {
                                return (<MessageItem message={message} onEdit={handleEditMessage} key={message.uuid}
                                                     onDelete={handleDeleteMessage} onReply={setRepliedMessage}/>)
                            })
                        }
                    </div>
                </div>
                {
                    repliedMessage ? (
                        <div
                            className="absolute bottom-[73px] text-sm flex flex-col bg-white shadow left-0 right-0 px-4 py-2 truncate">
                            <span>reply to: {repliedMessage.creator_info?.username}</span>
                            <span className="truncate">{repliedMessage.text}</span>
                            <i className="icon icon-24 icon-close-circle absolute right-4 top-0 bottom-0 my-auto"
                               onClick={() => setRepliedMessage(null)}/>
                        </div>
                    ) : null
                }
                <div className="bg-white shadow-sm py-4 p-2">
                    <form onSubmit={handleSubmit}>
                        <div className="flex flex-col pr-2">
                            <MentionsInput value={userQuestion}
                                           className="border border-lightGray-200 h-[100px] outline-0 px-3 bg-light-300 rounded ml-2 py-[5px]"
                                           onChange={e => setUserQuestion(e.target.value)}>
                                <Mention trigger="@" data={mentionList} className="top-unset bottom-0"
                                         markup="@__display__(__id__)" appendSpaceOnAdd={true}/>
                            </MentionsInput>
                            <div className="btn-group mt-2 flex justify-end">
                                <button
                                    className={`btn btn-primary px-3 text-sm h-7 min-h-[36px] min-w-[80px] ${isLoading ? "cursor-not-allowed" : "cursor-pointer"}`}
                                    type="submit">
                                    {!isLoading ? "Submit" : <Loading/>}
                                </button>
                                <button type="button" className="btn btn-primary px-3 h-7 min-h-[36px]"
                                        onClick={onSubmitMessageAsDraft}><i className="icon icon-24 icon-draft"/>
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default MessageCard;