export const SUCCESS = 'success';
export const FAILED = 'failed'


export const WAITING = 'waitting';

export const MESSAGE_STATUS = {
    DRAFT: "b183fcc9-9d91-4dce-a78d-4c2f8545142e",
    SENT: "22de6b68-7a8d-4d09-9d87-a5a18850d94b",
    READ: "c9a84026-bf02-4899-975f-ce5806cc45e7",
    ARCHIVED: "788dfb5d-13ba-4f6f-b1b4-eee564053178",
    DELETED: "5b9452fc-e051-4a5b-885f-388fb2dc4c04"
}
export const TICKET_STATUS = {
    TICKET_DRAFT: "cc17bdb6-8181-4581-87d2-96a405ea13bd",
    UNREAD_SUPPORT_TEAM: "57646ba2-4a2b-4a7c-9ed5-331e2a145d90",
    READ_SUPPORT_TEAM: "adf7a10d-0e50-4aa2-add5-f90455ef5339",
    ANSWERED_SUPPORT_TEAM: "51903302-7048-448c-a569-a04c73b2542b",
    READ_CUSTOMER: "c7d03dc1-7e8e-4f2a-972d-1386dbdc20c5",
    TICKET_CLOSED: "d63a467b-83be-485c-ae4a-8f27b5cb23eb"
}

export const lockOutTimerMessage = {
    RESET: 'ResetTimer',
    START: 'StartTimer',
    LOGOUT: 'Logout',
    ACTIVITY: 'ActivityDetected'
}
