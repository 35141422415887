import {ITicketSubject} from "../interfaces/ITicketSubject";
import {ITicketPriority} from "../interfaces/ITicketPriority";

export const workSpacesToList = workSpaces => {
    return workSpaces.map(item => {
        return {label: item.title, value: item.uuid}
    })
}

export const teamMembersToList = teamMembers => {
    return teamMembers.map(item => {
        return {label: item.title, value: item.uuid}
    })
}

export const teamsToList = teams => {
    return teams.map(item => {
        return {label: item.title, value: item.uuid, type: 'team', display: item.title}
    })
}

export const usersToList = users => {
    return users.map(item => {
        return {label: `${item.first_name} ${item.last_name}`, value: item.uuid, display: item.user_name, email: item.email, type: 'user'}
    })
}

export const countriesToList = countries => {
    return countries.map(item => {
        return {label: item.name, value: item.uuid}
    })
}

export const industriesToList = industries => {
    return industries.map(item => {
        return {label: item.name, value: item.uuid}
    })
}

export const orgUserRoleToList = orgUserRole => {
    return orgUserRole.map(item => {
        return {label: item.title, value: item.uuid}
    })
}

export const connectorTypeToList = connectorTypes => {
    return connectorTypes.map(item => {
        return {label: item.title, value: item.id}
    })
}

export const ticketSubjectsToList = (ticketSubjects: ITicketSubject[]) => {
    return ticketSubjects.map(item => {
        return {label: item.title, value: item.uuid}
    })
}

export const ticketPrioritiesToList = (ticketPriorities: ITicketPriority[]) => {
    return ticketPriorities.map(item => {
        return {label: item.title, value: item.uuid}
    })
}

export const analyticDataModelToList = res => {
    return res.map(item => {
        return {label: item.name, value: item.value}
    })
}