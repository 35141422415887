import {WidgetProps} from "@rjsf/utils";
import React from "react";

const CustomCheckbox = function (props: WidgetProps) {
    return (
        <div className="flex items-center">
            <input type="checkbox" id='custom' className="checkbox checkbox-primary rounded-sm mr-2"
                   onChange={() => props.onChange(!props.value)} checked={props.value}/>
            <label>{props.label}</label>
        </div>
    );
};

export default CustomCheckbox;