import Select from "../../../components/Select";
import Input from "../../../components/Input/input";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {
    organizationSchema,
    organizationValues,
    OrganizationValuesType
} from "../../../shared/formValidator/organizationFormValidator";
import {countries, industries, users} from "../../../services";
import {countriesToList, industriesToList, usersToList} from "../../../shared/utils/toList";
import {useError} from "../../../context/ErrorContext";
import {useNavigate} from "react-router-dom";

type FormProps = {
    onSubmit: (values: OrganizationValuesType) => void,
    data: OrganizationValuesType
}

const Form = ({onSubmit, data}: FormProps) => {

    const [userList, setUserList] = useState<{ value: string, label: string, email: string }[] | []>([]);
    const [countryList, setCountryList] = useState<{ value: string, label: string }[] | []>([]);
    const [industryList, setIndustryList] = useState<{ value: string, label: string }[] | []>([]);

    const {setError} = useError();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: data ? data : organizationValues,
        onSubmit,
        validationSchema: organizationSchema,
        validateOnBlur: true,
        validateOnChange: false,
        validateOnMount: false
    })

    useEffect(() => {
        loadUserList();
        loadCountryList();
        loadIndustryList();
    }, [])

    const loadUserList = async () => {
        try {
            const data = await users();
            setUserList(usersToList(data));
        } catch (error: any) {
            setError({message: error?.response?.data?.detail, type: 'error'});
        }
    }

    const loadCountryList = async () => {
        try {
            const data = await countries();
            setCountryList(countriesToList(data));
        } catch (error: any) {
            setError({message: error?.response?.data?.detail, type: 'error'});
        }
    }

    const loadIndustryList = async () => {
        try {
            const data = await industries();
            setIndustryList(industriesToList(data));
        } catch (error: any) {
            setError({message: error?.response?.data?.detail, type: 'error'});
        }
    }

    useEffect(() => {
        if (data && data.industry_uuid && industryList.length > 0) {
            const res = industryList.find(item => item?.value === data.industry_uuid);
            if (res) {
                formik.setFieldValue("industry_type", res);
            } else {
                formik.setFieldValue("industry_type", null);
            }
        }

        if (data && data.country_uuid && countryList.length > 0) {
            const res = countryList.find(item => item?.value === data.country_uuid);
            if (res) {
                formik.setFieldValue("country", res);
            } else {
                formik.setFieldValue("country", null);
            }
        }
    }, [data, industryList, countryList]);

    useEffect(() => {
        if (data && data.admin_email && userList.length > 0) {
            const res = userList.find(item => item.email === data.admin_email);
            if (res) {
                formik.setFieldValue("organization_admin", res);
            } else {
                formik.setFieldValue("organization_admin", null);
            }
        }
    }, [userList]);

    const onCancel = () => {
        navigate(`/setting/organization`);
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <p className="mb-4"><strong>Organization Title: </strong>{data.title}</p>
            <p className="mb-4"><strong>Organization Type: </strong>Type Organization</p>
            <p className="mb-4"><strong>Subscription Start Date: </strong>2023/07/04</p>
            <Select
                options={industryList}
                value={formik.values?.industry_type ? formik.values?.industry_type : null}
                onChange={value => formik.setFieldValue("industry_type", value)}
                placeholder="Select An Item"
                label="Industry Type"
                classNameWrapper="!w-full"
                error={formik.errors["industry_type"]}
                required={true}
            />
            <Select
                options={countryList}
                value={formik.values?.country ? formik.values?.country : null}
                onChange={value => formik.setFieldValue("country", value)}
                placeholder="Select An Item"
                label="Country"
                classNameWrapper="!w-full"
                required={true}
            />
            <Input
                formik={formik}
                label="Website Url"
                name="website"
                type="text"
                required={true}
            />
            <Select
                options={userList}
                value={formik.values.organization_admin ? formik.values.organization_admin : null}
                onChange={value => formik.setFieldValue("organization_admin", value)}
                placeholder="Select An Item"
                label="Organization Admin"
                classNameWrapper="!w-full"
                error={formik.errors["organization_admin"]}
                required={true}
            />
            <div className="flex gap-x-2">
                <label className="label cursor-pointer p-0">
                    <input type="checkbox" checked={formik.values.is_two_fa_enable} onChange={e=> formik.setFieldValue("is_two_fa_enable", e.target.checked)}
                           name="is_two_fa_enaible" className="checkbox checkbox-primary border-dark-400 rounded-sm"/>
                </label>
                <span>Enable 2FA</span>
            </div>
            <div className="mt-8 flex gap-x-4">
                <button className="btn btn-outline btn-primary px-4" type="button" onClick={onCancel}>Cancel</button>
                <button className="btn btn-primary px-12" type="submit">Save</button>
            </div>
        </form>
    )
}

export default Form;