import React from "react";

type TableHeaderProps = {
    columns: {key: string, title: string, render?: (_: any, {}:any ) => React.ReactNode}[],
}

const TableHeader = ({columns}: TableHeaderProps) => {
    return (
        <tr>
            {
                columns.map((column, index) => {
                    return (
                        <th key={column.key} className="rounded-tr-none rounded-tl-none text-sm">{column.title}</th>
                    )
                })
            }
        </tr>
    )
}

export default TableHeader;