import React from "react";
import {usePermission} from "./PermissionContext";
import NotFound from "../pages/NotFound";
import Spinner from "../components/Loading/Spinner";

const Restricted = ({to, children, replace = false}) => {

    if (!to) return <>{children}</>;

    const {allowed, loading} = usePermission(to)

    if (loading && replace) {
        return <div className="w-full h-[calc(100vh-62px)] flex items-center justify-center"><Spinner /></div>
    }

    if (allowed) {
        return <>{children}</>;
    }

    return (
        replace ? <><NotFound/></> : null
    )
};

export default Restricted;