import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";

export const withRouter = (WrappedComponent) => {
    const NewComponent = (props) => {
        const navigate = useNavigate();
        const location = useLocation();

        return <WrappedComponent navigate={navigate} location={location} {...props} />
    }

    return NewComponent
}