import React, {useEffect, useState} from 'react';
import ActionBar from "../../../components/ActionBar";
import SettingSidebar from "../../../components/SettingSidebar";
import Table from "../../../components/Table";
import {users} from "../../../services";
import {useNavigate} from "react-router-dom";
import {useError} from "../../../context/ErrorContext";
import InitialProfileImage from "../../../components/Profile/InitialProfileImage";

const Users = () => {

    const [userList, setUserList] = useState([]);
    const [selectedItem, setSelectedItem] = useState<string[]>([]);

    const navigate = useNavigate();
    const {setError} = useError();

    const handleEdit = () => {
        navigate(`/setting/users/edit/${selectedItem[0]}`);
    }

    const handleCreate = () => {
        navigate('/setting/users/add');
    }

    const loadUserList = async () => {
        setUserList([]);
        try {
            const data = await users();
            setUserList(data);
        } catch (error: any) {
            setError({message: error?.response?.data?.detail, type : 'error'});
        }
    }

    const userProfile = (first_name, last_name) => {
        return first_name && last_name ? first_name.charAt(0).toUpperCase() + last_name.charAt(0).toUpperCase() : null;
    }


    const buttons = [
        {
            icon: "plus-circle",
            title: "Create",
            action: handleCreate,
            className: "",
        },
        {
            icon: "edit-circle",
            title: "Edit",
            action: handleEdit,
            className: selectedItem.length === 1 ? "" : "disabled",
        },
        {
            icon: "refresh-circle",
            title: "Refresh",
            action: loadUserList,
            className: "",
        }
    ]

    const columns = [
        {
            key: 'select',
            title: 'select',
            render: (_, {uuid}) => (
                <label className="label cursor-pointer p-0">
                    <input type="checkbox" onChange={(e) => handleSelectedItems(e, uuid)}
                           checked={selectedItem.includes(uuid)}
                           className="checkbox checkbox-primary border-dark-400 rounded-sm"/>
                </label>
            )
        },
        {
            key: 'avatar',
            title: 'avatar',
            render: (_, {avatar_url, first_name, last_name}) => (
                avatar_url ? <img src={avatar_url} alt="avatar bg-contain" className="w-5 h-5 rounded-full object-cover"/> :
                    <InitialProfileImage userProfile={userProfile(first_name, last_name)} />
            )
        },
        {
            key: 'user_name',
            title: 'Username'
        },
        {
            key: 'first_name',
            title: 'First Name'
        },
        {
            key: 'last_name',
            title: 'Last Name'
        },
        {
            key: 'is_active',
            title: 'active',
            render: (_, {is_active}) => (
                <span>{is_active ? "Active" : "Not Active"}</span>
            )
        },
    ]

    const handleSelectedItems = (e, uuid) => {
        const {checked} = e.target;
        if (checked) {
            const tempSelected = [...selectedItem];
            tempSelected.push(uuid);
            setSelectedItem(tempSelected);
        } else {
            const selectedIndex = selectedItem.indexOf(uuid);
            const tempSelected = [...selectedItem];
            tempSelected.splice(selectedIndex, 1);
            setSelectedItem(tempSelected)
        }
    }

    useEffect(() => {
        loadUserList()
    }, [])

    return (
        <>
            <div className="flex gap-x-1">
                <div className="flex h-[calc(100vh-64px)]">
                    <SettingSidebar/>
                </div>
            </div>
            <div className="flex flex-col flex-1 gap-y-1">
                <section className="h-full flex flex-col">
                    <ActionBar goBack={false} pageTitle="Users" buttons={buttons} topic="user" isShow={false}/>
                    <div className="flex-1 bg-white shadow-sm mt-1 p-6 h-[calc(100vh-128px)] overflow-y-auto">
                        <Table columns={columns} data={userList}/>
                    </div>
                </section>
            </div>
        </>
    )
}

export default Users;