import Select from "../Select";
import React, {useEffect, useRef, useState} from "react";
import DropZone from "../DropZone";
import ModalImport from "./ModalImport";
import {useImportFile} from "../../shared/hooks/ModelComposer/useImportFile";
import {isNullOrUndefinedOrEmpty} from "../../shared/utils/helper";

type ImportProps = {
    onNext: (key: number) => void,
    setDataFileUploaded: (values: any) => void,
    dataFileUploaded: any,
    selectedDataModel?: {
        data_type_name: string,
        data_type_uuid: string,
        is_required: boolean
    } | null,
    setStatusAirFlow: (value: boolean) => void,
    statusAirFlow: boolean,
    selectedDataImportStatus: {
        [key: string]: { status: string, dag_id: string, dag_run_id: string, pipeline_type?: string  }
    } | null,
    setSelectedDataImportStatus: (value: null) => void
}


const Import = ({
                    onNext,
                    setDataFileUploaded,
                    dataFileUploaded,
                    selectedDataModel,
                    statusAirFlow,
                    setStatusAirFlow,
                    selectedDataImportStatus,
                    setSelectedDataImportStatus
                }: ImportProps) => {

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

    const importCardRef = useRef<HTMLDivElement>(null);

    const {
        files,
        setFiles,
        selectedConnectorType,
        setSelectedConnectorType,
        connectorTypeList,
        progressUploaded,
        renderFindData,
        renderRequiredInformation,
        isDisabledUpload
    } = useImportFile({
        setDataFileUploaded,
        dataFileUploaded,
        setStatusAirFlow,
        selectedDataModel,
        selectedDataImportStatus,
        setSelectedDataImportStatus
    });

    const handleOpenModal = () => setIsOpenModal(true);

    const handleCloseModal = () => setIsOpenModal(false);

    const handleClick = () => {
        onNext(2)
    }

    useEffect(() => {
        if (importCardRef.current) {
            importCardRef.current.scrollTo({top: 100, behavior: 'smooth'})
        }
    }, [selectedConnectorType, files]);

    return (
        <>
            <div className="flex flex-col flex-1 border border-lightGray-100 p-6 rounded relative h-full">
                <div className="h-[calc(100%-48px)] overflow-y-auto" ref={importCardRef}>
                    <p><strong>Required Information:</strong></p>
                    <div
                        dangerouslySetInnerHTML={{__html: renderRequiredInformation()}}></div>


                    <button className="flex items-center mt-6 gap-x-1 w-fit" onClick={handleOpenModal}>
                        <i className="icon icon-24 icon-search"/>
                        <span>Where to find this data?</span>
                    </button>

                    <p className="mt-6">Upload file (XLSX):</p>
                    <Select options={connectorTypeList} value={selectedConnectorType}
                            onChange={setSelectedConnectorType}
                            label={""} placeholder="Select" classNameWrapper="mt-6"/>
                    {
                        selectedConnectorType && selectedConnectorType?.value === 1 ? (
                            <DropZone setFiles={setFiles}
                                      files={files}
                                      className="mt-4 mb-4"
                                      acceptedFileType={
                                          {
                                              // 'text/csv': ['.csv'],
                                              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
                                              // 'text/plain': ['.txt'],
                                          }
                                      }
                                      percentUploaded={progressUploaded}
                                      isDisabled={isDisabledUpload || isNullOrUndefinedOrEmpty(selectedDataModel)}
                            />
                        ) : null
                    }
                </div>
                <div className="h-[48px] flex justify-end">
                    <button
                        className={`btn btn-primary mt-auto w-fit px-4 ${statusAirFlow ? "" : "btn-disabled"}`}
                        onClick={handleClick}>Next
                    </button>
                </div>
            </div>
            <ModalImport isOpen={isOpenModal} handleClose={handleCloseModal} content={renderFindData()}/>
        </>
    )
}

export default Import;