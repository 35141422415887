import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useModel} from "../../context/ModelContext";
import {IModelNodes} from "../../pages/Model";
import {IModel} from "../../shared/interfaces/IModel";

type ModelNodeProps = {
    item: IModelNodes,
    onSelect: (model: IModel) => void,
    onOpenModal: () => void
}
const ModelNode = ({item, onSelect, onOpenModal}: ModelNodeProps) => {

    const [isShowMenu, setIsShowMenu] = useState<boolean>(false);
    const navigate = useNavigate();
    const {setModel} = useModel();
    const contextMenuRef = useRef<HTMLDivElement>(null);
    const modelNodeRef = useRef<HTMLDivElement>(null);
    const {pathname} = useLocation();

    const onOpen = () => {
        setModel({...item, icon: 'layers'})
        navigate(`${pathname}/${item.uuid}/statistics`)
    }

    const OnOpenRunAnalytic = (e) => {
        e.stopPropagation();
        setModel({...item, icon: 'layers'})
        navigate(`${pathname}/${item.uuid}/run-analytics`)
    }

    const handleClick = (e) => {
        e.stopPropagation();
        setIsShowMenu(prevState => !prevState);
    }

    useEffect(() => {
        window.addEventListener('mousedown', handleClickOutSide);

        return () => {
            window.removeEventListener('mousedown', handleClickOutSide);
        }
    }, [])

    const handleClickOutSide = (e) => {
        if (contextMenuRef?.current && !contextMenuRef.current.contains(e.target)) {
            setIsShowMenu(false)
        }
    }

    const handleOpenModal = e => {
        e.stopPropagation();
        onSelect(item);
        onOpenModal()
        setIsShowMenu(false);
    }

    const calculateDistanceToRight = () => {
        const elementRect = modelNodeRef.current?.getBoundingClientRect();
        const windowWidth = window.innerWidth;

        if (elementRect) {
            return windowWidth - elementRect.right;
        }

        return 230;
    }

    return (
        <>
            <div className="model-node" onClick={onOpen} ref={modelNodeRef}>
                <span className="whitespace-nowrap">{item.title}</span>
                <i className="icon icon-24 icon-three-dots ml-4"  onClick={handleClick}/>
                <div className={`context-menu z-10 ${isShowMenu ? "block" : "hidden"}`}
                     style={calculateDistanceToRight() < 230 ? {left: "unset", right: 0} : {}} ref={contextMenuRef}>
                    <ul className="menu bg-white shadow-md w-56 rounded cursor-default gap-y-[2px]">
                        <li className="py-3 text-left px-4 hover:bg-blue-100 cursor-pointer" onClick={onOpen}>Open</li>
                        {/*<li className="py-3 text-left px-4 hover:bg-blue-100 cursor-pointer" onClick={(e) => e.stopPropagation()}>Clone</li>*/}
                        {/*<li className="py-3 text-left px-4 hover:bg-blue-100 cursor-pointer" onClick={(e) => e.stopPropagation()}>Scan</li>*/}
                        <li className="py-3 text-left px-4 hover:bg-blue-100 cursor-pointer"
                            onClick={OnOpenRunAnalytic}>Run Analytics
                        </li>
                        {/*<li className="py-3 text-left px-4 hover:bg-blue-100 cursor-pointer" onClick={(e) => e.stopPropagation()}>Model Tracker</li>*/}
                        <li className="py-3 text-left px-4 hover:bg-blue-100 cursor-pointer"
                            onClick={handleOpenModal}>Delete
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}


export default ModelNode;