import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useTicket} from "../../shared/hooks/useTicket";
import Form from "./Form";
import SupportItem from "../../components/Support/SupportItem";
import {TICKET_STATUS} from "../../shared/constant";

const ViewTicket = () => {

    const {uuid} = useParams();
    const {loadTicket, ticketData, handleSubmitTicket, editTicketStatus} = useTicket();
    const [isOpenForm, setIsOpenForm] = useState<boolean>(false);

    useEffect(() => {
        if (uuid) {
            loadTicket(uuid);
        }
    }, [])

    useEffect(() => {
        if (ticketData && ticketData.ticket.ticket_status_uuid === TICKET_STATUS.ANSWERED_SUPPORT_TEAM && uuid) {
            editTicketStatus(uuid, TICKET_STATUS.READ_CUSTOMER)
        }
    }, [ticketData]);

    const handleOpenForm = () => {
        setIsOpenForm(prevState => !prevState)
    }

    const onSubmitTicket = async (values, resetForm) => {
        const lastItem = ticketData?.questions_answers[ticketData?.questions_answers.length - 1];
        if (lastItem && lastItemIsAnswer(lastItem?.uuid)) {
            await handleSubmitTicket(values, resetForm, ticketData?.questions_answers[ticketData?.questions_answers.length - 1].uuid);
            if (uuid) {
                await loadTicket(uuid);
            }
        }
    }

    const lastItemIsAnswer = (uuid: string) => {
        return ticketData?.questions_answers[ticketData?.questions_answers.length - 1].ticket_type === "answer" && ticketData?.questions_answers[ticketData?.questions_answers.length - 1].uuid === uuid;
    }

    return (
        <div className="bg-white shadow-sm flex-1 p-6 h-[calc(100vh-64px)] overflow-y-auto">
            {
                ticketData?.ticket.ticket_status_uuid === TICKET_STATUS.TICKET_CLOSED ? (
                    <p className="p-4 text-center bg-lightGray-200">
                        This Ticket has been closed. To reopen it, you need to send a response
                    </p>
                ) : null
            }
            <div className="container mx-auto">
                {
                    ticketData?.ticket_subject ? (<div className="shadow-sm p-4">
                        <span>subject: {ticketData?.ticket_subject.title}</span>
                    </div>) : null
                }
                {
                    isOpenForm ? (
                        <div className="border border-lightGray-100 rounded p-6 mb-6 mt-4">
                            <Form onSubmit={onSubmitTicket} onClose={handleOpenForm}/>
                        </div>
                    ) : null
                }
                {
                    ticketData?.ticket ? (
                        <SupportItem ticket={ticketData?.ticket} key={ticketData.ticket.uuid}/>
                    ) : null
                }

                {
                    ticketData?.questions_answers && ticketData.questions_answers.length > 0 && ticketData.questions_answers.map((ticketItem) => {
                        return (
                            <SupportItem ticket={ticketItem} key={ticketItem.uuid}
                                         isLastAnswer={lastItemIsAnswer(ticketItem.uuid)} onClickReply={handleOpenForm}/>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default ViewTicket;