import * as Yup from "yup";
export const updatePasswordValues = {
    old_password: "",
    new_password: "",
    confirm_password: ""
};

export const updatePasswordSchema = Yup.object({
    old_password: Yup.string().required("The Current Password is required."),
    new_password: Yup.string().required("The New Password is required.")
        .matches(
        /^(?=.*[a-z])/,
        "Password must contain One Lowercase")
        .matches(/(?=.*[A-Z])/, "Password must contain One Uppercase")
        .matches(/(?=.*[0-9])/, "Password must contain One Number")
        .matches(/(?=.*[!@#\$%\^&\*])/, "Password must contain One Special Case Character")
        .min(8, "Min Length is 8 Character"),
    confirm_password: Yup.string().required("The Confirm New Password is required.").oneOf([Yup.ref('new_password'), "null"], 'Confirm Passwords must matches with New Password'),
});