import Loading from "../../components/Loading";
import React, {useState} from "react";
import {useError} from "../../context/ErrorContext";
import {resetPassword} from "../../services";
import {Link, useNavigate, useSearchParams} from "react-router-dom";
import {useFormik} from "formik";
import {resetPasswordSchema, resetPasswordValues} from "../../shared/formValidator/resetPasswordFormValidator";
import Input from "../../components/Input/input";

const ResetPassword = () => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [searchParam] = useSearchParams();
    const navigate = useNavigate();

    const {setError} = useError();

    const handleSubmit = async () => {
        setIsLoading(true)
        const values = {
            token: searchParam.get('token'),
            new_password: formik.values['new_password']
        }
        try {
            await resetPassword(values);
            setIsLoading(false);
            setError({message: 'Your password has been updated successfully!', type: 'success'})
            navigate('/sign-in')
        } catch (error: any) {
            setIsLoading(false)
            setError({message: error?.response?.data?.detail, type: 'error'});
        }

    }

    const formik = useFormik({
        initialValues: resetPasswordValues,
        onSubmit: handleSubmit,
        validationSchema: resetPasswordSchema,
        validateOnMount: true,
        validateOnBlur: true,
        validateOnChange: true
    })

    return (
        <div className="w-full h-screen mx-auto bg-white">
            <div className="flex items-center justify-center w-full h-screen">
                <div
                    className="flex-grow-0 flex-shrink-0 flex flex-col items-center justify-center h-screen w-4/5 md:w-1/2 z-10">
                    <div className="border border-rounded border-lightGray-100 px-4 py-6 rounded min-w-[400px]">
                        <h3 className="text-center text-dark text-xl">Reset Password</h3>
                        <form onSubmit={formik.handleSubmit}
                              className="flex flex-col">
                            <Input type="password" name="new_password" label="New Password: " formik={formik}/>
                            <Input type="password" name="confirm_password" label="Confirm New Password: "
                                   formik={formik}/>
                            <div className="">
                                <Link to="/sign-in" className="" type="button">
                                    Sign In
                                </Link>
                            </div>
                            <button type="submit"
                                    className={`btn btn-primary mt-3 ml-auto min-w-[160px] ${formik.isValid ? "" : "btn-disabled"}`}>
                                {
                                    isLoading ? <Loading/> : "Reset Password"
                                }
                            </button>
                        </form>
                    </div>
                </div>
                <div className="flex-1 p-0 m-0 z-0 h-screen hidden md:block">
                    <img src="/images/sign-in-image.png" alt="" className="h-full w-full"/>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword;