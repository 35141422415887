import React from "react";


export default function Item({item, activeKey, onClick, className = ""}) {

    const handleClick = () => {
        onClick(item.key)
    }

    return (
        <a className={`tab ${item.key === activeKey ? "tab-active" : ""} ${className} ${item?.disable ? "tab-disabled" : ""}`} onClick={handleClick}>{item.label}</a>
    )
}