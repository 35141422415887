import {
    analyticDataModel, analyticsDataModel,
    dataImportFiles,
    getCheckStatus, getDataImports, getDataImportStatus,
    getDataTypeStatus,
    getReportData, getReportDataSummary,
    modelCompose
} from "../../../services";
import {useWorkSpace} from "../../../context/WorkSpaceContext";
import {useError} from "../../../context/ErrorContext";
import {useEffect, useState} from "react";
import {IFile} from "../../interfaces/IFile";
import {useNavigate, useParams} from "react-router-dom";
import {generateTableColumns} from "../../utils/helper";
import {analyticDataModelToList} from "../../utils/toList";
import {SUCCESS} from "../../constant";

export const useModelComposer = () => {

    const [workspaceFiles, setWorkspaceFiles] = useState<IFile[] | null>(null);
    const [checkStatusDataModel, setCheckStatusDataModel] = useState<any>(null);
    const [dataModelList, setDataModelList] = useState<{
        data_type_name: string,
        data_type_uuid: string,
        is_required: boolean
    }[] | []>([])
    const [analyticModelOption, setAnalyticModelOption] = useState<{ label: string, value: string }[] | []>([])
    const [selectedAnalyticModel, setSelectedAnalyticModel] = useState<{ label: string, value: string } | null>(null);
    const [dataFileUploaded, setDataFileUploaded] = useState<any>(null);

    const [selectedDataModel, setSelectedDataModel] = useState<{
        data_type_name: string,
        data_type_uuid: string,
        is_required: boolean
    } | null>();
    const [dataModelStatusList, setDataModelStatusList] = useState<{
        analyticsDataModelName: string,
        status: boolean,
        data_import_uuid: string | null
    }[] | []>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [columns, setColumns] = useState<{
        key: string,
        title: string
    }[] | []>([])
    const [dataTable, setDataTable] = useState<any>([]);
    const [columnsSummary, setColumnsSummary] = useState<{
        key: string,
        title: string
    }[] | []>([]);
    const [dataTableSummary, setDataTableSummary] = useState<any>([]);
    const [isOpenReportModal, setIsOpenReportModal] = useState<boolean>(false);
    const [displayPhaseName, setDisplayPhaseName] = useState<string>("");
    const [statusAirFlowFileImport, setStatusAirFlowFileImport] = useState<boolean>(false);
    const [statusAirFlowPreProcessing, setStatusAirFlowPreProcessing] = useState<boolean>(false);
    const [statusAirFlowIntegration, setStatusAirFlowIntegration] = useState<boolean>(false);
    const [dataImports, setDataImports] = useState<{
        data_import_uuid: string,
        analytic_data_type_uuid: string,
    } [] | []>([]);

    const [selectedDataImportStatus, setSelectedDataImportStatus] = useState<{
        [key: string]: { status: string, dag_id: string, dag_run_id: string, pipeline_type?: string }
    } | null>(null);

    const {workSpace} = useWorkSpace();
    const {workspaceUUId} = useParams()
    const {setError} = useError();
    const navigate = useNavigate();

    const handleOpenReportModal = () => setIsOpenReportModal(true);

    useEffect(() => {
        if (workSpace) {
            loadDataImports();
        }
    }, [workSpace]);

    const loadDataModel = async (id) => {
        try {
            const data = await analyticDataModel(id);
            setDataModelList(data);
            if (data.length > 0) {
                setSelectedDataModel(data[0]);
            }
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    const loadDataModelStatus = async () => {
        try {
            const data = await getDataTypeStatus(workspaceUUId);
            if (selectedAnalyticModel) {
                setDataModelStatusList(data[selectedAnalyticModel?.value].map(item => {
                    return {
                        analyticsDataModelName: item.data_type_name,
                        status: item.is_confirmed,
                        data_import_uuid: item.data_import_uuid
                    }
                }))
            }
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    const loadAnalyticDataModel = async () => {
        try {
            const data = await analyticsDataModel();
            setAnalyticModelOption(analyticDataModelToList(data))
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    const handleSelectDataModel = (id) => {
        const selectedItem = dataModelList.find(item => item.data_type_uuid === id);
        if (selectedItem) {
            setSelectedDataModel(selectedItem);
        }
    }

    const loadFilesList = async () => {
        try {
            const data = await dataImportFiles(workSpace?.uuid, selectedDataModel?.data_type_uuid);
            setWorkspaceFiles(data);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    const loadCheckStatusModel = async () => {
        try {
            const data = await getCheckStatus(workSpace?.uuid)
            setCheckStatusDataModel(data);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    const handleModelCompose = async () => {
        setIsLoading(true);
        const values = {
            workspace_uuid: workSpace?.uuid,
            title: "Base Model",
            description: `Base model for ${workSpace?.title} workspace`,
            analytic_uuid: selectedAnalyticModel?.value
        }

        try {
            const data = await modelCompose(values);
            setIsLoading(false);
            navigate(`/workspace/${workSpace?.uuid}/execution-progress/detail/${data.execution_item_uuid}`)
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
            setIsLoading(false);
        }
        setIsLoading(false);
    }

    const loadDataReport = async (phaseName, metricName, count) => {
        try {
            const data = await getReportData(dataFileUploaded?.data_import.uuid, phaseName, metricName, count);
            setColumns(generateTableColumns(data.table_data[0]));
            setDataTable(data.table_data);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    const loadDataReportSummary = async (phaseName, metricName) => {
        try {
            const data = await getReportDataSummary(dataFileUploaded?.data_import.uuid, phaseName, metricName);
            setColumnsSummary(generateTableColumns(data.table_data[0]));
            setDataTableSummary(data.table_data);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    const loadDataImports = async () => {
        try {
            const data = await getDataImports(workSpace?.uuid!);
            setDataImports(data);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    const loadDataImportStatus = async (dataImportUUId) => {
        try {
            const data = await getDataImportStatus(dataImportUUId);
            setSelectedDataImportStatus(data);
        } catch (error: any) {
            setError({message: error.response.data.detail, type: 'error'})
        }
    }

    const isDisabled = (step: string, phaseName: string, prevPhase: string) => {
        if (selectedDataImportStatus && selectedDataImportStatus[prevPhase] && selectedDataImportStatus[prevPhase].status === SUCCESS) {
            return false;
        }
        if (selectedDataImportStatus && selectedDataImportStatus[phaseName]) {
            return !selectedDataImportStatus[phaseName]
        }
        const selectedDataModelStatus = dataModelStatusList.find(item => item.analyticsDataModelName === selectedDataModel?.data_type_name)

        if (selectedDataModelStatus !== null && selectedDataModelStatus?.status) {
            return !selectedDataModelStatus?.status
        }
        switch (step) {
            case "Preprocessing":
                return !statusAirFlowFileImport;
            case "Integration":
                return !statusAirFlowPreProcessing;
            case "Summary":
                return !statusAirFlowIntegration;
            default:
                return true
        }
    }

    const handleClickLoad = (phaseName, metricName, count, displayPhaseName) => {
        handleOpenReportModal()
        setDisplayPhaseName(displayPhaseName);
        loadDataReport(phaseName, metricName, count);
        // loadDataReportSummary(phaseName, metricName);
    }

    const handleCloseReportModal = () => {
        setIsOpenReportModal(false);
        setColumnsSummary([])
        setColumns([]);
        setDataTable([]);
        setDataTableSummary([]);
    }

    useEffect(() => {
        if (dataFileUploaded) {
            loadFilesList();
        }
    }, [dataFileUploaded])

    useEffect(() => {
        if (selectedAnalyticModel?.value) {
            loadDataModel(selectedAnalyticModel.value);
        }
        if (selectedAnalyticModel?.value && workspaceUUId) {
            loadDataModelStatus();
        }
    }, [selectedAnalyticModel]);

    useEffect(() => {
        if (selectedDataModel) {
            loadFilesList()
        }
    }, [selectedDataModel]);

    const handleDisableCompose = (): string => {
        if (isLoading) {
            return 'disabled';
        }
        if (dataModelList.length > 0 && dataModelStatusList.length > 0) {
            const analyticsStatusMap = new Map<string, boolean | undefined>();

            dataModelStatusList.forEach(({analyticsDataModelName, status}) => {
                analyticsStatusMap.set(analyticsDataModelName, status);
            });

            const composeDisabled = dataModelList.every((item) => {
                const {data_type_name, is_required} = item;
                const status = analyticsStatusMap.get(data_type_name);
                return !is_required || (is_required && status);
            });

            return composeDisabled ? '' : 'disabled';
        }

        return 'disabled';
    };

    return {
        workspaceFiles,
        checkStatusDataModel,
        dataModelList,
        selectedDataModel,
        handleSelectDataModel,
        selectedAnalyticModel,
        setSelectedAnalyticModel,
        loadCheckStatusModel,
        analyticModelOption,
        loadDataModel,
        dataModelStatusList,
        loadDataModelStatus,
        isLoading,
        handleModelCompose,
        columns,
        columnsSummary,
        dataTable,
        dataTableSummary,
        isOpenReportModal,
        handleOpenReportModal,
        handleCloseReportModal,
        displayPhaseName,
        handleClickLoad,
        setDataFileUploaded,
        dataFileUploaded,
        setDataModelStatusList,
        isDisabled,
        setStatusAirFlowIntegration,
        setStatusAirFlowFileImport,
        setStatusAirFlowPreProcessing,
        statusAirFlowFileImport,
        statusAirFlowPreProcessing,
        statusAirFlowIntegration,
        handleDisableCompose,
        setSelectedDataModel,
        loadAnalyticDataModel,
        dataImports,
        selectedDataImportStatus,
        setSelectedDataImportStatus,
        loadDataImports,
        loadDataImportStatus
    }
}