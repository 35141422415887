import React from "react";
import Bar from "../Plots/Bar";
import Line from "../Plots/Line";
import Pie from "../Plots/Pie";

const plots = {
    Pie,
    Line,
    Bar
}

type MessageItemProps = {
    message?: {
        id?: number,
        related_item_id?: number,
        item_text: string,
        item_type: string,
        submit_date?: string,
        options?: {[key: string]: string} | {}
        plot_data?: any
    },
    selectedOption?: string,
    onClick?: (title: string) => void
}

const MessageItem = ({message, selectedOption, onClick}: MessageItemProps) => {

    const USER_ROLE = 'user';

    const PlotComponent = plots[message?.plot_data?.data?.type]

    const handleClick = (title: string) => {
        onClick && onClick(title === selectedOption ? "" : title);
    }

    return (
        <div
            className={`w-10/12 flex items-start gap-x-4 ${message?.item_type === USER_ROLE ? "flex-row-reverse ml-auto" : "mr-auto"}`}>
            <img src={message?.item_type === USER_ROLE ? "/images/avatar.png" : "/images/avatar-bot.png"}
                 className="w-6 h-6 rounded-full"/>
            <div
                className={`rounded flex-1 py-3 px-4 ${message?.item_type === USER_ROLE ? "bg-blue-200" : "bg-blue-400"}`}>
                <p className="whitespace-pre-line">{message?.item_text}</p>
                {
                    message?.options && (
                        <div className="flex items-center flex-wrap gap-y-4 justify-center gap-x-4 mt-3">
                            {
                                Object.values(message?.options).map((option, index) => {
                                    return (<button className={`btn btn-primary whitespace-nowrap ${selectedOption === option ? "" : "btn-outline"}`} key={index} onClick={() => handleClick(option)}>{option}</button>)
                                })
                            }
                        </div>
                    )
                }
                {
                    message?.plot_data && PlotComponent ? (
                        <div className="h-[400px]"><PlotComponent plotData={message.plot_data?.data}/></div>) : null
                }
            </div>
        </div>
    )
}

export default MessageItem;