import React from "react";
import ActionBar from "../../../components/ActionBar";
import SettingSidebar from "../../../components/SettingSidebar";

const Subscription = () => {
    return (
        <>
            <div className="flex gap-x-1">
                <div className="flex h-[calc(100vh-64px)]">
                    <SettingSidebar/>
                </div>
            </div>
            <div className="flex flex-col flex-1 gap-y-1">
                <section className="h-full flex flex-col">
                    <ActionBar goBack={false} pageTitle="Subscription"/>
                    <div className="flex-1 bg-white shadow-sm mt-1">
                        this page is subscription
                    </div>
                </section>
            </div>
        </>
    )
}

export default Subscription;