import React from "react";
import {Link, useNavigate} from "react-router-dom";

const NotFound = () => {

    const navigate = useNavigate();
    const handleClick = () => {
        navigate(-1);
    }

    return (
        <div className="w-full flex justify-center items-center flex-col bg-white flex-1">
            <h1 className="text-7xl">Oops!</h1>
            <p>Sorry, we can't find the page you're looking for.</p>
            <p>Try checking the url, or go back to our <Link to="/" className="text-primary">home page</Link>.</p>
            <div className="background-chain" />
        </div>
    )
}

export default NotFound;