import React from "react";
import get from "lodash/get";

type TableRowCellProps = {
    column: { key: string, title: string, render?: (_: any, {}: any) => React.ReactNode },
    item: { [key: string]: string | number | boolean }
}

const TableRowCell = ({item, column}: TableRowCellProps) => {
    const value = get(item, column.key);
    return (
        <td className="text-sm">
            {column.render ? column.render(column, item) : value}
        </td>
    )
}

export default TableRowCell;