import React, {useState} from "react";
import {useAuth} from "../../context/AuthContext";
import {useNavigate} from "react-router-dom";
import {useError} from "../../context/ErrorContext";
import InitialProfileImage from "./InitialProfileImage";
import {logout} from "../../services";
import {boolean} from "yup";
import UpdatePassword from "./UpdatePassword";

const Profile = () => {

    const [isOpen, setIsOpen] = useState<boolean>(false)
    const {setUser, user} = useAuth();
    const {setError} = useError();

    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await logout();
            setUser({})
            localStorage.removeItem('token');
            navigate('/sign-in');
        } catch (error: any) {
            setUser({})
            localStorage.removeItem('token');
            navigate('/sign-in');
            setError({message: error.response.data.detail, type: 'error'});
        }
    }

    const userProfile = () => {
        if (user.name) {
            const splitName = user.name.split(" ");
            return splitName[0].charAt(0).toUpperCase() + splitName[1].charAt(0).toUpperCase();
        }
        return user.first_name && user.last_name ? user.first_name.charAt(0).toUpperCase() + user.last_name.charAt(0).toUpperCase() : null;
    }

    const onClose = () => {
        setIsOpen(false);
    }

    const handleOpen = () => {
        setIsOpen(true);
    }

    return (
        <>
            <div className="dropdown dropdown-end">
                <label tabIndex={0}
                       className="ml-1 flex items-center text-black border border-lightGray m-0 py-2 pl-4 pr-3 rounded text-base">
                    {
                        user?.avatar_url ? <img src={user.avatar_url} alt="avatar bg-contain" className="w-5 h-5 rounded-full object-cover"/> :
                            <InitialProfileImage userProfile={userProfile()}/>
                    }
                    <span className="leading-[18px] ml-2">{user?.user_name || 'Guest'}</span>
                    <i className="icon icon-16 icon-arrow-down ml-6"/>
                </label>
                <ul tabIndex={0} className="dropdown-content menu p-2 shadow rounded-box bg-white">
                    <li onClick={handleOpen}><a className="whitespace-nowrap">Update Password</a></li>
                    <li onClick={handleLogout}><a className="whitespace-nowrap">Log out</a></li>
                </ul>
            </div>
            <UpdatePassword isOpen={isOpen} onClose={onClose} />
        </>
    )
}

export default Profile;