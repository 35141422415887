import React from 'react';
import {createRoot} from 'react-dom/client';
import App from "./App";
import './index.less'
import {ErrorProvider} from "./context/ErrorContext";
import {BrowserRouter} from "react-router-dom";

const root = createRoot(document.getElementById("root") as Element)

root.render(
    <ErrorProvider>
        <BrowserRouter>
            <App/>
        </BrowserRouter>
    </ErrorProvider>
);