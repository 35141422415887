import React, {useEffect, useRef, useState} from "react";
import ActionBar from "../../components/ActionBar";
import {useMessages} from "../../shared/hooks/useMessages";
import CreateChannelModal from "../../components/Message/CreateChannelModal";
import ChannelItem from "../../components/Message/ChannelItem";
import MessageForm from "../../components/Message/MessageForm";
import {IChannel} from "../../shared/interfaces/IChannel";
import MessageItem from "../../components/Message/MessageItem";
import {useFormik} from "formik";
import {channelSchema, channelValues} from "../../shared/formValidator/channelFormValidator";
import {useWorkSpace} from "../../context/WorkSpaceContext";
import {useMessage} from "../../context/MessageContext";

const Message = () => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const {
        isOpenModal,
        handleCloseModal,
        handleOpenModal,
        shareItems,
        userQuestion,
        setUserQuestion,
        selectedChannel,
        channelList,
        channelDataMessages,
        handleDeleteChannel,
        handleCreateChannel,
        handleCreateMessage,
        loadChannel,
        channelData,
        handleUpdateChannel,
        fetchData,
        loadChannels,
        handleDeleteMessage,
        handleEditMessage,
        messageData,
        handleUpdateMessage,
        handleSelectChannel,
        repliedMessage,
        setRepliedMessage
    } = useMessages();

    const {workSpace} = useWorkSpace();
    const {message} = useMessage()

    const onSubmitChannelForm = async (values) => {
        if (channelData) {
            await handleUpdateChannel(channelData.uuid, values)
        } else {
            await handleCreateChannel(values);
        }
        formik.resetForm()
    }

    const formik = useFormik({
        validationSchema: channelSchema,
        onSubmit: onSubmitChannelForm,
        initialValues: channelData ? channelData : channelValues,
        validateOnBlur: true,
        validateOnMount: true,
        validateOnChange: true,
        enableReinitialize: true,
    })

    useEffect(() => {
        if (channelData && shareItems.length > 0) {
            let userMember: { label: string; value: string; type?: string | undefined; }[] | [] = [];
            userMember = channelData.user_uuids.map(user_uuid => shareItems.filter(item => item.value === user_uuid)).flat(1)

            let teamMember: { label: string; value: string; type?: string | undefined; }[] | [] = [];
            teamMember = channelData.team_uuids.map(team_uuid => shareItems.filter(item => item.value === team_uuid)).flat(1);
            formik.setFieldValue("member_uuids", [...userMember, ...teamMember]);
        }
    }, [channelData, shareItems]);

    const buttons = [
        {
            icon: "plus-circle",
            title: "Create Channel",
            action: handleOpenModal,
        },
        {
            icon: "refresh-circle",
            title: "Refresh",
            action: () => window.location.reload(),
        }
    ]

    const onSubmitMessage = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        if (messageData) {
            await handleUpdateMessage(selectedChannel?.uuid!);
        } else {
            await handleCreateMessage(selectedChannel?.uuid!)
        }
        setIsLoading(false);
    }

    const onSubmitMessageAsDraft = async () => {
        if (messageData) {
            await handleUpdateMessage(selectedChannel?.uuid!, true)
        } else {
            await handleCreateMessage(selectedChannel?.uuid!, true);
        }
    }

    useEffect(() => {
        fetchData("message")
    }, [workSpace]);

    useEffect(() => {
        if (!isOpenModal) {
            formik.resetForm()
        }
    }, [isOpenModal]);

    useEffect(() => {
        if (workSpace?.uuid) {
            loadChannels(workSpace.uuid);
        }
    }, [workSpace]);

    return (
        <>
            <div className="flex flex-col flex-1 gap-y-1 h-[calc(100vh-64px)]">
                <ActionBar pageTitle="Message" buttons={buttons} topic="message"/>
                <div className="flex-1 flex gap-x-1">
                    <div className="shadow-sm h-[calc(100vh-132px)] gap-y-4 bg-white w-[265px] flex flex-col px-3 py-4">
                        <div className="h-1/2 flex flex-col overflow-hidden border-b border-b-lightGray-100">
                            <h2 className="text-sm pb-1">Channels:</h2>
                            <div className="flex-1 overflow-y-auto flex flex-col gap-y-1 py-2">
                                {
                                    channelList.length > 0 && channelList.filter(item => !item.is_direct_channel).map((channel: IChannel) => {
                                        return <ChannelItem
                                            onSelectChannel={handleSelectChannel}
                                            channel={channel}
                                            key={channel.uuid}
                                            isSelectedChannel={channel.uuid === selectedChannel?.uuid}
                                            handleDeleteChannel={handleDeleteChannel}
                                            onEditChannel={loadChannel}
                                        />
                                    })
                                }
                            </div>
                        </div>
                        <div className="h-1/2 flex flex-col overflow-hidden">
                            <h2 className="text-sm pb-1">Direct Messages:</h2>
                            <div className="flex-1 overflow-y-auto py-2 flex flex-col gap-y-1">
                                {
                                    channelList.length > 0 && channelList.filter(item => item.is_direct_channel).map((channel: IChannel) => {
                                        return <ChannelItem
                                            onSelectChannel={handleSelectChannel}
                                            channel={channel}
                                            key={channel.uuid}
                                            isSelectedChannel={channel.uuid === selectedChannel?.uuid}
                                            handleDeleteChannel={handleDeleteChannel}
                                            onEditChannel={loadChannel}
                                        />
                                    })
                                }
                            </div>
                        </div>
                    </div>
                    <div
                        className="flex-1 h-[calc(100vh-132px)] flex flex-col relative gap-y-1 shadow bg-white overflow-y-auto">
                        {
                            selectedChannel !== null ? (
                                <>
                                    <div
                                        className="shadow-sm bg-white h-[calc(100%-68px)] overflow-y-auto flex-1 flex flex-col p-6">
                                        <div
                                            className="border border-light-100 rounded-lg flex flex-col overflow-y-auto gap-y-4 px-6 py-3 h-full">
                                            {
                                                channelDataMessages && channelDataMessages?.length > 0 && channelDataMessages?.map(message => {
                                                    return (<MessageItem
                                                        message={message}
                                                        key={message.uuid}
                                                        onDelete={handleDeleteMessage}
                                                        onEdit={handleEditMessage}
                                                        onReply={setRepliedMessage}
                                                    />)
                                                })
                                            }
                                        </div>
                                    </div>
                                    {
                                        repliedMessage ? (
                                            <div
                                                className="absolute bottom-[73px] text-sm flex flex-col bg-white shadow left-0 right-0 px-4 py-2 truncate">
                                                <span>reply to: {repliedMessage.creator_info?.username}</span>
                                                <span className="truncate">{repliedMessage.text}</span>
                                                <i className="icon icon-24 icon-close-circle absolute right-4 top-0 bottom-0 my-auto"
                                                   onClick={() => setRepliedMessage(null)}/>
                                            </div>
                                        ) : null
                                    }
                                    <MessageForm
                                        userQuestion={userQuestion}
                                        setUserQuestion={setUserQuestion}
                                        onSubmit={onSubmitMessage}
                                        onSubmitMessageAsDraft={onSubmitMessageAsDraft}
                                        isLoading={isLoading}
                                        ref={inputRef}
                                        mentionItems={shareItems}
                                    />
                                </>
                            ) : null
                        }
                    </div>
                </div>
            </div>
            <CreateChannelModal isOpen={isOpenModal} onClose={handleCloseModal} shareItems={shareItems}
                                formik={formik}/>
        </>
    )
}

export default Message;